.agency-slider {
  &__slider {
    &.swiper {
      overflow: visible;
    }
  }

  &__counter-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &__counter-current {
    height: inherit;
    display: flex;
    align-items: center;
  }

  .swiper-pagination-progressbar-fill {
    background: var(--text-color);

    //почему-то это не подцепилось из свайпера, хотя должно было, вроде
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    transform: scale(0);
    transform-origin: left top;
  }

  &__progressbar {
    height: 1px;
    background: var(--translucency-color);
    //TODO: местами на этой странице вместо --translucency-color написано --primary-color-40. не в той ж ветке, что этот файл, но в паре предыдущих
    position: relative;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: auto;
  }
}

@include respond-up('x-large') {
  .agency-slider {
    margin-top: calc((170 / 1920) * 100vw);

    &__counter-wrapper {
      height: calc((35 / 2560) * 100vw);
    }

    &__slider {
      margin-top: calc((22 / 1920) * 100vw);
    }

    &__progressbar-wrapper {
      margin-top: calc((30 / 1920) * 100vw);
    }

    &__item {
      &:not(:last-child) {
        margin-right: calc((30 / 1920) * 100vw);
      }
    }
  }
}

@include respond('large') {
  .agency-slider {
    margin-top: 170px;

    &__counter-wrapper {
      height: 25px;
    }

    &__slider {
      margin-top: 22px;
    }

    &__progressbar-wrapper {
      margin-top: 30px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}

@include respond('s-large') {
  .agency-slider {
    margin-top: 170px;

    &__counter-wrapper {
      height: 25px;
    }

    &__slider {
      margin-top: 22px;
    }

    &__progressbar-wrapper {
      margin-top: 30px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .agency-slider {
    &__item {
      &.swiper-slide {
        width: var(--grid-column3);
        height: calc(789 / 1388 * var(--grid-column3));
      }
    }
  }
}

@include respond('xs-large') {
  .agency-slider {
    margin-top: calc((170 / 1440) * 100vw);

    &__slider {
      margin-top: calc((22 / 1440) * 100vw);
    }

    &__progressbar-wrapper {
      margin-top: calc((30 / 1440) * 100vw);
    }

    &__item {
      &:not(:last-child) {
        margin-right: calc((30 / 1440) * 100vw);
      }
    }

    &__counter-wrapper {
      height: clamp(18px, calc((20 / 1232) * 100vw), 24px);
    }
  }
}

@include respond('medium') {
  .agency-slider {
    margin-top: 80px;

    &__picture {
      width: var(--grid-column3);
      height: calc(366 / 591 * var(--grid-column3));
    }

    &__counter-wrapper {
      height: 28px;
    }

    &__progressbar-wrapper {
      margin-top: 20px;
    }

    &__slider {
      margin-top: 20px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

@include respond('s-medium') {
  .agency-slider {
    margin-top: 80px;

    &__picture {
      width: var(--grid-column3);
      height: calc(366 / 591 * var(--grid-column3));
    }

    &__counter-wrapper {
      height: 26px;
    }

    &__progressbar-wrapper {
      margin-top: 20px;
    }

    &__slider {
      margin-top: 20px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

@include respond('small') {
  .agency-slider {
    margin-top: 50px;

    &__picture {
      width: calc(324 / 354 * var(--grid-column2));
      height: calc(184 / 354 * var(--grid-column2));
    }

    &__counter-wrapper {
      height: 25px;
    }

    &__progressbar-wrapper {
      margin-top: 10px;
    }

    &__slider {
      margin-top: 10px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

@include respond-down('x-small') {
  .agency-slider {
    margin-top: calc((50 / 374) * 100vw);

    &__picture {
      width: calc(324 / 354 * var(--grid-column2));
      height: calc(184 / 354 * var(--grid-column2));
    }

    &__counter-wrapper {
      height: calc((25 / 374) * 100vw);
    }

    &__progressbar-wrapper {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__slider {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__item {
      &:not(:last-child) {
        margin-right: calc((10 / 374) * 100vw);
      }
    }
  }
}
