.lang {
  display: flex;
  align-items: center;

  &__list,
  &__item,
  &__link {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@include respond-up('xs-large') {
  .lang {

  }
}

@include respond-up('medium') {
  .lang {

  }
}

@include respond('medium') {
  .lang {

  }
}

@include respond-down('medium') {
  .lang {

  }
}

@include respond-down('small') {
  .lang {

  }
}