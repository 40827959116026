.modal-form {
  --border-color: black;
  margin-top: 20px;
  display: grid;
  grid-row-gap: 40px;
  grid-template-rows: auto 70px;

  .current-image {
    width: 250px;
  }

  .form-field label {
    top: -28px;

    &.clear-file {
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }


}