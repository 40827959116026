.smart-tabs {
  list-style: none;
  display: flex;
  justify-content: flex-start;

  li {
    a {
      display: flex;
    }
  }
}

.smart-content {
  display: none;

  &._active {
    display: block;
  }
}