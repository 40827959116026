.animation-wrap {
  // start state
  &.need-animation {
    .animation-item {
      &._opacity {
        opacity: 0;
      }

      &._wrap {
        .animation-item__inner,
        .edit-block {
          span {
            transform: translate3d(0, 100%, 0);
          }
        }
      }

      &._wrap-single {
        display: block;

        .animation-item {
          &__inner {
            display: block;
            transform: translate3d(0, 100%, 0);
          }
        }
      }

      &._image {
        transform: scale(1.05);
        opacity: 0;
      }
    }
  }
}

.animation-item {
  &__inner {
    transition: transform var(--animation-duration) var(--default-timing-function);
    transition-delay: var(--animation-delay);
  }

  // delay
  --animation-delay: 0s;

  &._del-01 {
    --animation-delay: .1s;
  }

  &._del-02 {
    --animation-delay: .2s;
  }

  &._del-03 {
    --animation-delay: .3s;
  }

  &._del-04 {
    --animation-delay: .4s;
  }

  &._del-05 {
    --animation-delay: .5s;
  }

  &._del-07 {
    --animation-delay: .7s;
  }

  &._del-26 {
    --animation-delay: 2.6s;
  }

  // duration
  &._dur-04 {
    --animation-duration: .7s;
  }

  &._dur-04-real {
    --animation-duration: .4s;
  }

  &._dur-07 {
    --animation-duration: .7s;
  }

  &._dur-08 {
    --animation-duration: .8s;
  }

  // opacity item
  &._opacity {
    transition: opacity var(--default-timing-function);
    transition-duration: var(--animation-duration);
    transition-delay: var(--animation-delay);
  }

  // multi wrap item
  &._wrap {
    .animation-item__inner,
    .edit-block {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      height: max-content;

      span {
        transition: transform var(--default-timing-function);
        transition-duration: var(--animation-duration);
      }

      @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
          span {
            transition-delay: calc(var(--animation-delay) + #{$i} * .05s);
          }
        }
      }
    }
  }

  // single wrap item
  &._wrap-single {
    overflow: hidden;
  }

  &._image {
    transition: transform, opacity;
    transition-duration: var(--animation-duration);
    transition-timing-function: var(--default-timing-function);
    transition-delay: var(--animation-delay);
  }
}