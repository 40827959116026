.index-page-cases-list {
  &__item {
    position: relative;
    grid-column: span 2;
  }

  &__title {
    .animation-item__inner {
      display: block;
    }
  }
}

@include respond-up('x-large') {
  .index-page-cases-list {
    &__list {
      margin-top: calc((60 / 1920) * 100vw);;
      grid-row-gap: calc((100 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .index-page-cases-list {
    &__list {
      margin-top: 60px;
      grid-row-gap: 100px;
    }
  }
}

@include respond('xs-large') {
  .index-page-cases-list {
    &__list {
      margin-top: calc((60 / 1440) * 100vw);
      grid-row-gap: calc((80 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .index-page-cases-list {
    &__list {
      grid-row-gap: 60px;
      margin-top: 30px;
    }

    &__item {
      grid-column: span 4;
    }
  }
}

@include respond-down('small') {
  .index-page-cases-list {
    &__list {
      grid-row-gap: 40px;
      margin-top: 10px;
    }
  }
}