article {
  //common article tag
  :first-child {
    margin-top: 0;
  }

  p {
    @extend %text-main;

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  blockquote {
    border-top: 2px solid var(--border-color);
    border-left: 2px solid var(--border-color);

    p {
      font-style: italic;
    }
  }

  &, p, li {
  }

  h3 {
    @extend .text__medium-title;

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  h6 {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: var(--text-color);
    margin-top: var(--list-title-margin-top);

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  ul,
  ol {
    @extend %text-main;

    li {
      position: relative;

      ::selection,
      &::selection{
        background-color: var(--text-color);
        color: var(--background-color);
      }
    }
  }

  ul {
    li {
      &:before {
        content: '';
        position: absolute;
        background: var(--text-color);
        width: 6px;
        height: 6px;
        border-radius: 3px;
        transition: background-color .4s var(--default-timing-function);
      }
    }
  }

  ol {
    counter-reset: section;

    li {
      &:before {
        counter-increment: section;
        position: absolute;
        content: counter(section) ".";
        //width: 23px;
        text-align: right;
      }
    }
  }

  strong {
    font-weight: bold;

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  em {
    font-style: italic;

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  a {
    position: relative;
    display: inline-flex;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      transition: border-bottom-color var(--default-timing-function) .4s;
      border-bottom: 2px solid var(--border-color);
    }

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }
}

@include respond-up('x-large') {
  article {
    --list-title-margin-top: calc((80 / 1920) * 100vw);
    ul,
    ol {
      margin-top: calc((30 / 1920) * 100vw);
    }

    p {
      margin-top: calc((40 / 1920) * 100vw);
    }

    h2 {
      margin-bottom: calc((30 / 1920) * 100vw);
      margin-top: calc((100 / 1920) * 100vw);
    }

    blockquote {
      padding: calc((30 / 1920) * 100vw) 0 0 calc((30 / 1920) * 100vw);
    }
  }
}

@include respond('large') {
  article {
    ul,
    ol {
      margin-top: 30px;
    }

    p {
      margin-top: 40px;
    }

    h2 {
      margin-bottom: 30px;
      margin-top: 100px;
    }
  }
}

@include respond('s-large') {
  article {
    ul,
    ol {
      margin-top: 30px;
    }

    p {
      margin-top: 40px;
    }

    h2 {
      margin-bottom: 30px;
      margin-top: 100px;
    }
  }
}

@include respond-up('xs-large') {
  article {
    ul {
      li {
        &:before {
          top: calc((11 / 1920) * 100vw);
          width: 6px;
          height: 6px;
        }
      }
    }

    ol,
    ul {
      li {
        padding-left: calc((35 / 1920) * 100vw);

        &:before {
          left: calc(((35 / 1920) * 100vw) - 24px);
        }
      }
    }

    a {
      &::before {
        transform: scaleX(1);
        transition: border-bottom-color .4s, transform .4s;
        transition-timing-function: var(--default-timing-function);
        transform-origin: center left;
      }

      &:hover {
        &::before {
          transform: scaleX(0);
          transform-origin: center right;
        }
      }
    }

    h6 {
      font-size: 18px;
    }
  }
}

@include respond-between('s-large', 'large') {
  article {
    --list-title-margin-top: 80px;
    blockquote {
      padding: 30px 0 0 30px;
    }
  }
}

@include respond-between('xs-large', 'large') {
  article {
    --list-title-margin-top: 80px;
  }
}

@include respond('xs-large') {
  article {
    ul,
    ol {
      margin-top: calc((30 / 1440) * 100vw);
    }

    p {
      margin-top: calc((40 / 1440) * 100vw);
    }

    h2 {
      margin-top: calc((100 / 1440) * 100vw);
      margin-bottom: calc((30 / 1440) * 100vw);
    }

    blockquote {
      padding: calc((30 / 1440) * 100vw) 0 0 calc((30 / 1440) * 100vw);
    }
  }
}

@include respond("medium") {
  article {
    ul,
    ol {
      margin-top: calc((25 / 1024) * 100vw);
    }

    p {
      margin-top: calc((30 / 1024) * 100vw);
    }

    h2 {
      margin-top: calc((80 / 1024) * 100vw);
      margin-bottom: calc((15 / 1024) * 100vw);
    }
  }
}

@include respond-down("medium") {
  article {
    --list-title-margin-top: calc((60 / 834) * 100vw);
    blockquote {
      padding: 20px 0 0 20px;
    }

    h6 {
      font-size: 16px;
    }

    ul {
      li {
        &:before {
          top: 11px;
        }
      }
    }

    ol,
    ul {
      li {
        padding-left: calc((32 / 834) * 100vw);

        &:before {
          left: calc(((32 / 834) * 100vw) - 22px);
        }
      }
    }
  }
}

@include respond("s-medium") {
  article {
    ul,
    ol {
      margin-top: calc((25 / 840) * 100vw);
    }

    p {
      margin-top: calc((30 / 840) * 100vw);
    }

    h2 {
      margin-top: calc((80 / 840) * 100vw);
      margin-bottom: calc((15 / 840) * 100vw);
    }
  }
}

@include respond("small") {
  article {
    ul,
    ol {
      margin-top: 25px;
    }

    p {
      margin-top: 20px;
    }

    h2 {
      margin-top: 50px;
    }

    blockquote {
      padding: 15px 0 0 20px;
    }
  }
}

@include respond-down("small") {
  article {
    --list-title-margin-top: calc((40 / 374) * 100vw);
    ul {
      li {
        &:before {
          top: calc((11 / 374) * 100vw);
        }
      }
    }

    ol,
    ul {
      li {
        padding-left: calc((27 / 374) * 100vw);

        &:before {
          left: calc(((27 / 374) * 100vw) - 19px);
        }
      }
    }
  }
}

@include respond("x-small") {
  article {
    ul,
    ol {
      margin-top: calc((25 / 374) * 100vw);
    }

    p {
      margin-top: calc((20 / 374) * 100vw);
    }

    h2 {
      margin-top: calc((50 / 374) * 100vw);
    }

    blockquote {
      padding: calc((15 / 374) * 100vw) 0 0 calc((20 / 374) * 100vw);
    }
  }
}