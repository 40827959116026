.footer-picture-link {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__picture {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__scroll-line {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    z-index: 10;

    svg {
      path {
        stroke: var(--light-color);
      }
    }
  }
}