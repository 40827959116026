.recall-block {
  &__text-block {
    border: 1px solid var(--border-color);
    transition: border .4s var(--default-timing-function);
  }

  &__text-block,
  &__form-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__form-block {
    background: var(--opposite-background-color);
    transition: background .4s var(--default-timing-function);
  }

  &__form-title {
    .text__main {
      color: var(--opposite-text-color);
    }
  }

  &:has(.index-recall-form.success) {
    .recall-block {
      &__form-title {
        opacity: 0;
      }
    }
  }

  &__form-block {
    position: relative;
  }
}

@include respond-up('x-large') {
  .recall-block {
    margin-top: calc((200 / 1920) * 100vw);

    &__text-block,
    &__form-block {
      padding: calc((30 / 1920) * 100vw);
    }

    &__title {
      .edit-block {
        max-width: calc((662 / 1920) * 100vw);
      }
    }

    &__subtitle,
    &__form-title {
      width: calc(var(--grid-column1) - ((30 / 1920) * 100vw));
    }

    &__form-block {
      row-gap: calc((64/1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .recall-block {
    margin-top: 200px;

    &__text-block,
    &__form-block {
      padding: 30px;
    }

    &__title {
      .edit-block {
        max-width: calc(var(--grid-column2) - 253px);
      }
    }

    &__subtitle,
    &__form-title {
      width: calc(var(--grid-column1) - 30px);
    }

    &__form-block {
      row-gap: 64px;
    }
  }
}

@include respond-up('xs-large') {
  .recall-block {
    display: grid;
    grid-template-columns: repeat(2, var(--grid-column2));
    grid-gap: var(--grid-gap);
  }
}

@include respond('xs-large') {
  .recall-block {
    margin-top: calc((200 / 1440) * 100vw);

    &__text-block,
    &__form-block {
      padding: calc((30 / 1440) * 100vw);
    }

    &__title {
      .edit-block {
        max-width: calc((662 / 1440) * 100vw);
      }
    }

    &__subtitle,
    &__form-title {
      width: calc(var(--grid-column1) - ((30 / 1440) * 100vw));
    }

    &__form-block {
      row-gap: calc((64/1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .recall-block {
    display: flex;
    flex-direction: column;
  }
}

@include respond-between('s-medium', 'medium') {
  .recall-block {
    row-gap: 20px;
    margin-top: 120px;

    &__text-block,
    &__form-block {
      padding: 20px;
    }

    &__text-block {
      row-gap: 160px;
    }

    &__title {
      .edit-block {
        max-width: calc(var(--grid-column2) - 20px);
      }
    }

    &__subtitle,
    &__form-title {
      width: calc(var(--grid-column2) - 20px);
    }

    &__form-block {
      row-gap: 64px;
    }
  }
}

@include respond-down('small') {
  .recall-block {
    row-gap: 10px;
    margin-top: 60px;

    &__text-block,
    &__form-block {
      padding: 12px;
    }

    &__text-block {
      row-gap: 60px;
    }

    &__title {
      .edit-block {
        max-width: calc(var(--grid-column2) - 90px);
      }
    }

    &__subtitle {
      width: calc(var(--grid-column2) - 90px);
    }

    &__form-block {
      row-gap: 34px;
    }
  }
}

@include respond-down('x-small') {
  .recall-block {
    row-gap: calc((10 / 374) * 100vw);
    margin-top: calc((60 / 374) * 100vw);

    &__text-block,
    &__form-block {
      padding: calc((12 / 374) * 100vw);
    }

    &__text-block {
      row-gap: calc((60 / 374) * 100vw);
    }

    &__title {
      .edit-block {
        max-width: calc(var(--grid-column2) - ((90 / 374) * 100vw));
      }
    }

    &__subtitle {
      width: calc(var(--grid-column2) - ((90 / 374) * 100vw));
    }

    &__form-block {
      row-gap: calc((34/374) * 100vw);
    }
  }
}