.arrows {
  display: flex;
  align-items: center;
}

@include respond-up('x-large') {
  .arrows {
    &__right.arrow {
      margin-left: calc((16 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .arrows {
    &__right.arrow {
      margin-left: 16px;
    }
  }
}

@include respond('xs-large') {
  .arrows {
    &__right.arrow {
      margin-left: calc((16 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .arrows {

  }
}

@include respond('medium') {
  .arrows {

  }
}

@include respond-down('medium') {
  .arrows {

  }
}

@include respond('x-small') {
  .arrows {
    &__right.arrow {
      margin-left: calc((16 / 374) * 100vw);
    }
  }
}