.index-page-content-header {
  position: relative;

  &__title {
    grid-row-gap: 0;

    .edit-block {
      display: inline;
    }
  }

  &__title-text {
    &:last-child {
      text-align: right;
    }
  }

  &__title-text-word {
    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }
}

@include respond-up('x-large') {
  .index-page-content-header {
    &__description {
      margin-top: calc((40 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .index-page-content-header {
    &__description {
      margin-top: 40px;
    }
  }
}

@include respond-up('xs-large') {
  .index-page-content-header {
    &__title-text {
      &:nth-child(1) {
        grid-column: 1/5;
      }

      &:nth-child(2) {
        grid-column: 1/5;
        text-align: end;
      }

      &:nth-child(3) {
        display: none;
      }
    }

    &__description {
      grid-column: 3/4;
    }

    &__underline {
      transition: border-bottom-color var(--default-timing-function) .4s;
      border-bottom: 2px solid var(--border-color);
    }
  }
}

@include respond('xs-large') {
  .index-page-content-header {
    &__description {
      margin-top: calc((40 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .index-page-content-header {
    &__title-text {
      grid-column: 1/5;
    }

    &__description {
      grid-column: 1/3;
      margin-top: calc((40 / 834) * 100vw);
    }

    &__title-text-word {
      display: none;
    }
  }
}

@include respond-up('s-medium') {
  .index-page-content-header {
    &__title {
      grid-column: 1/5;
    }
  }
}

@include respond('small') {
  .index-page-content-header {
    &__description {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .index-page-content-header {
    &__title,
    &__title-text {
      grid-column: 1/3;
    }

    &__description {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}

@include respond-down('x-small') {
  .index-page-content-header {
    &__description {
      margin-top: calc((30 / 375) * 100vw);
    }
  }
}