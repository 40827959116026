.reward-top {
  &__icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;

      path {
        &:first-child {
          stroke: var(--border-color);
          transition: stroke .4s var(--default-timing-function);
        }
        &:last-child {
          fill: var(--border-color);
          transition: fill .4s var(--default-timing-function);
        }
      }
    }
  }
}

@include respond-up('x-large') {
  .reward-top {
    &__description {
      margin-top: calc((40 / 1920) * 100vw);
    }

    &__icon {

    }
  }
}

@include respond-between('s-large', 'large') {
  .reward-top {
    &__description {
      margin-top: 40px;
    }
  }
}

@include respond('xs-large') {
  .reward-top {
    &__description {
      margin-top: calc((40 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .reward-top {

  }
}

@include respond('medium') {
  .reward-top {

  }
}

@include respond-down('medium') {
  .reward-top {
    &__description {
      margin-top: 30px;
    }
  }
}

@include respond-up('s-medium') {
  .reward-top {
    &__icon {
      width: 113px;
      height: 113px;
    }
  }
}

@include respond('small') {
  .reward-top {
    &__description {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .reward-top {
    &__icon {
      width: 87px;
      height: 87px;
    }
  }
}

@include respond-down('x-small') {
  .reward-top {
    &__description {
      margin-top: calc((10 / 374) * 100vw);
    }
  }
}