.cases-page-content-header {
  &__title {
    grid-column: 1/5;
  }
}

@include respond-up('x-large') {
  .cases-page-content-header {
    grid-row-gap: calc((70 / 1920) * 100vw);
  }
}

@include respond-between('s-large', 'large') {
  .cases-page-content-header {
    grid-row-gap: 70px;
  }
}

@include respond-up('xs-large') {
  .cases-page-content-header {
    &__title {
      grid-row: 1;
    }

    &__description {
      grid-row: 2;
      grid-column: 3/4;
    }
  }
}

@include respond('x-large') {
  .cases-page-content-header {
    grid-row-gap: calc((70 / 1440) * 100vw);
  }
}

@include respond-down('medium') {
  .cases-page-content-header {
    grid-row-gap: 60px;

    &__description {
      grid-column: 1/3;
    }
  }
}

@include respond-down('small') {
  .cases-page-content-header {
    grid-row-gap: 40px;

    &__title {
      grid-column: 1/3;
    }
  }
}

@include respond('x-small') {
  .cases-page-content-header {
    grid-row-gap: calc((40 / 374) * 100vw);
  }
}