.index-page-case {
  grid-row-gap: 0;

  &__video-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__text-block {
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &:not(:first-child) {
    margin-top: var(--margin-top);
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__picture {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__picture {
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s var(--default-timing-function);
  }
}

@include respond-up('x-large') {
  .index-page-case {
    --margin-top: calc((200 / 1920) * 100vw);
    padding-top: calc((30 / 1920) * 100vw);

    &__description {
      margin-top: calc((50 / 1920) * 100vw);
    }
  }
}

@include respond-up('large') {
  .index-page-case {
    &__text-block-top {
      width: var(--grid-column);
    }
  }
}

@include respond-down('large') {
  .index-page-case {
    --margin-top: 200px;
    padding-top: 30px;
  }
}

@include respond-between('s-large', 'large') {
  .index-page-case {
    &__description {
      margin-top: 50px;
    }
  }
}

@include respond-up('xs-large') {
  .index-page-case {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--border-color);
      transition: background-color var(--default-timing-function) .4s;
    }

    &__link {
      //height: calc((773 / 1920) * 100vw);
      grid-column: 3/5;
      height: calc(773 / 915 * var(--grid-column2));

      &:hover {
        .index-page-case {
          &__image {
            transform: scale(1.05);
          }
        }
      }
    }

    &__subcases {
      margin-top: auto;
    }
  }
}

@include respond('xs-large') {
  .index-page-case {
    --margin-top: calc((200 / 1440) * 100vw);

    &__description {
      margin-top: calc((50 / 1440) * 100vw);
    }
  }
}

@include respond-up('xs-large') {
  .index-page-case {
  }
}

@include respond-between('s-medium', 'medium') {
  .index-page-case {
    &__text-block,
    &__link {
      grid-column: 1/5;
    }

    &__text-block-top {
      display: flex;
    }
  }
}

@include respond-down('medium') {
  .index-page-case {
    &__subcases {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .index-page-case {
    --margin-top: 100px;
    padding-top: 20px;

    &__text-block {
      display: flex;
      margin-top: 20px;
    }

    &__title,
    &__description {
      flex: 0 0 50%;
    }

    &__link {
      height: calc((671 / 834) * 100vw);
      grid-row: 1/2;
    }
  }
}

@include respond-up('s-medium') {
  .index-page-case {

  }
}

@include respond('small') {
  .index-page-case {
    --margin-top: 50px;
    padding-top: 10px;

    &__subcases {
      margin-top: 30px;
    }

    &__description {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .index-page-case {
    &__link {
      height: calc((299 / 374) * 100vw);
      grid-column: 1/3;
    }

    &__text-block {
      margin-top: 10px;
    }

    &__subcases {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}

@include respond('x-small') {
  .index-page-case {
    --margin-top: calc((50 / 375) * 100vw);
    padding-top: calc((10 / 375) * 100vw);

    &__description {
      margin-top: calc((20 / 374) * 100vw);
    }
  }
}