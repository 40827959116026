@mixin respond($name)
{
  @if map-has-key($grid, $name) {
    $point: map-get($grid, $name);
    @if map-has-key($point, from) and map-has-key($point, to) {
      @media (min-width: #{map-get($point, from)}px) and (max-width: #{map-get($point, to)}px) {
        @content;
      }
    } @else if map-has-key($point, to) {
      @media (max-width: #{map-get($point, to)}px) {
        @content;
      }
    } @else if map-has-key($point, from) {
      @media (min-width: #{map-get($point, from)}px) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoint '#{$name}'";
  }
}

@mixin respond-up($name)
{
  @if map-has-key($grid, $name) {
    $point: map-get($grid, $name);
    @if map-has-key($point, from) {
      @media (min-width: #{map-get($point, from)}px) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoint '#{$name}'";
  }
}

@mixin respond-down($name)
{
  @if map-has-key($grid, $name) {
    $point: map-get($grid, $name);
    @if map-has-key($point, to) {
      @media (max-width: #{map-get($point, to)}px) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoint '#{$name}'";
  }
}

/**
 $fromName - меньшее разрешение
 $toName - большее разрешение
*/
@mixin respond-between($fromName, $toName)
{
  @if map-has-key($grid, $fromName) and map-has-key($grid, $toName) {
    $pointFrom: map-get($grid, $fromName);
    $pointTo: map-get($grid, $toName);
    @if map-has-key($pointFrom, from) and map-has-key($pointTo, to) {
      @media (min-width: #{map-get($pointFrom, from)}px) and (max-width: #{map-get($pointTo, to)}px) {
        @content;
      }
    } @else if map-has-key($pointTo, to) {
      @media (max-width: #{map-get($pointTo, to)}px) {
        @content;
      }
    } @else if map-has-key($pointFrom, from) {
      @media (min-width: #{map-get($pointFrom, from)}px) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoints '#{$fromName}' or '#{$toName}'";
  }
}

/**
 Использовать понимая, что код дублируется для каждого разрешения
*/
@mixin respond-each($names...)
{
  @each $name in $names {
    @if map-has-key($grid, $name) {
      $point: map-get($grid, $name);
      @if map-has-key($point, from) and map-has-key($point, to) {
        @media (min-width: #{map-get($point, from)}px) and (max-width: #{map-get($point, to)}px) {
          @content;
        }
      } @else if map-has-key($point, to) {
        @media (max-width: #{map-get($point, to)}px) {
          @content;
        }
      } @else if map-has-key($point, from) {
        @media (min-width: #{map-get($point, from)}px) {
          @content;
        }
      }
    } @else {
      @warn "Unknown breakpoint '#{$name}'";
    }
  }
}