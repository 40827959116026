.noise {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;

  &_header,
  &_footer,
  &_agency-logo,
  &_transition {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  &::after {
    content: "";
    width: calc(100% + 20rem);
    height: calc(100% + 20rem);
    background: var(--background-color) url('../images/noise.png') repeat 0 0;
    transition: background-color .4s var(--default-timing-function);
    position: absolute;
    left: -10rem;
    top: -10rem;
    will-change: transform;
    -webkit-animation: noise 1s steps(2) infinite;
    animation: noise 1s steps(2) infinite;
  }

  &_footer {
    &::after {
      background: var(--opposite-background-color) url('../images/noise.png') repeat 0 0;
    }
  }
}

@keyframes noise {
  0% { transform: translate3d(0,9rem,0) }
  10% { transform:translate3d(-1rem,-4rem,0) }
  20% { transform:translate3d(-8rem,2rem,0) }
  30% { transform:translate3d(9rem,-9rem,0) }
  40% { transform:translate3d(-2rem,7rem,0) }
  50% { transform:translate3d(-9rem,-4rem,0) }
  60% { transform:translate3d(2rem,6rem,0) }
  70% { transform:translate3d(7rem,-8rem,0) }
  80% { transform:translate3d(-9rem,1rem,0) }
  90% { transform:translate3d(6rem,-5rem,0) }
  to { transform:translate3d(-7rem,0,0)}
}
