.agency-page-content-header {
  position: relative;

  &__title {
    grid-row-gap: 0;

    .edit-block {
      display: inline;
    }
  }
}

@include respond-up('x-large') {
  .agency-page-content-header {
    &__description {
      margin-top: calc((40 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .agency-page-content-header {
    &__description {
      margin-top: 40px;
    }
  }
}

@include respond-up('xs-large') {
  .agency-page-content-header {
    &__title-text {
      grid-column: 1/5;

      &:nth-child(3) {
        display: none;
      }
    }

    &__description {
      grid-column: 3/4;
    }

    &__title-text {
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}

@include respond('xs-large') {
  .agency-page-content-header {
    &__description {
      margin-top: calc((40 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .agency-page-content-header {
    &__title-text {
      grid-column: 1/5;

      &:nth-child(3) {
        text-align: right;
      }
    }

    &__description {
      grid-column: 1/3;
      margin-top: calc((40 / 834) * 100vw);
    }
  }
}

@include respond-up('s-medium') {
  .agency-page-content-header {
    &__title {
      grid-column: 1/5;
    }
  }
}

@include respond-down('small') {
  .agency-page-content-header {
    &__title,
    &__title-text {
      grid-column: 1/3;
    }

    &__description {
      margin-top: 30px;
    }
  }
}

@include respond('x-small') {
  .agency-page-content-header {
    &__description {
      margin-top: calc((30 / 375) * 100vw);
    }
  }
}