.reward {
  //border-top: 1px solid var(--text-color);

  &__title {
    display: flex;
  }

  &__icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: var(--border-color);
        transition: fill .4s var(--default-timing-function);
      }
    }
  }

  //&__top-icon {
  //  #top {
  //    fill: var(--text-color);
  //  }
  //}

  //&__top-icon,
  //&__icon {
  //  display: flex;
  //  align-items: center;
  //  height: max-content;
  //
  //  svg {
  //    width: var(--icon-width);
  //    height: var(--icon-height);
  //  }
  //}

  //&__icon {
  //  svg {
  //    path {
  //      fill: var(--text-color);
  //    }
  //  }
  //}
}

@include respond-up('x-large') {
  .reward {
    //padding-top: calc((30 / 1920) * 100vw);
    //--icon-width: calc((86 / 1920) * 100vw);
    //--icon-height: calc((86 / 1920) * 100vw);

    //&__top-icon {
    //  --icon-width: calc((113 / 1920) * 100vw);
    //  --icon-height: calc((113 / 1920) * 100vw);
    //}

    //&__top-description {
    //  margin-top: calc((30 / 1920) * 100vw);
    //}

    //&__high {
    //  padding-bottom: calc((200 / 1920) * 100vw);
    //}

    &__description {
      margin-top: calc((70 / 1920) * 100vw);
    }

    &__icon {
      margin-left: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond('large') {
  .reward {
    //padding-top: 30px;
    //
    //&__top-description {
    //  margin-top: 30px;
    //}
    //
    //&__high {
    //  padding-bottom: 200px;
    //}
    //
    //--icon-width: 86px;
    //--icon-height: 86px;
  }
}

@include respond-between('s-medium', 'large') {
  .reward {
    &__description {
      margin-top: 70px;
    }

    &__icon {
      margin-left: 30px;
    }
  }
}

@include respond('s-large') {
  .reward {
    //padding-top: 30px;
    //
    //&__top-description {
    //  margin-top: 30px;
    //}
    //
    //&__high {
    //  padding-bottom: 200px;
    //}
    //
    //--icon-width: 86px;
    //--icon-height: 86px;
  }
}

@include respond-up('xs-large') {
  .reward {
    //&__top {
    //  grid-column: span 1;
    //}

    &__title {
      //grid-column: 1/4;
      //flex-direction: row;
    }

    //&__icon-wrapper {
    //  width: var(--grid-column1);
    //  margin-right: var(--grid-gap);
    //}

    //&__name {
    //  width: var(--grid-column2);
    //}
    //
    &__description {
      width: var(--grid-column1);
    //  grid-column: 4/5;
    }

    &__icon {
      width: calc((86 / 1920) * 100vw);
      height: calc((86 / 1920) * 100vw);
    }
  }
}

@include respond('xs-large') {
  .reward {
    //padding-top: calc((30 / 1440) * 100vw);
    //--icon-width: calc((86 / 1440) * 100vw);
    //--icon-height: calc((86 / 1440) * 100vw);
    //
    //&__top-description {
    //  margin-top: calc((30 / 1440) * 100vw);
    //}
    //
    //&__high {
    //  padding-bottom: calc((200 / 1440) * 100vw);
    //}

    &__description {
      margin-top: calc((70 / 1440) * 100vw);
    }

    &__icon {
      margin-left: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond('medium') {
  .reward {
    //padding-top: 20px;
    //
    //&__top-description {
    //  margin-top: 20px;
    //}
    //
    //&__name {
    //  padding-left: 20px;
    //}
    //
    //&__description,
    //&__title {
    //  //grid-column: span 2;
    //}
    //
    //&__high {
    //  padding-bottom: 100px;
    //}
  }
}

@include respond-down('medium') {
  .reward {
    //--icon-width: 50px;
    //--icon-height: 50px;
    //
    //&__top {
    //  grid-column: span 2;
    //}

    &__description {
      margin-top: 40px;
      width: var(--grid-column2);
    }

    &__icon {
      margin-left: 20px;
      width: calc((60 / 834) * 100vw);
      height: calc((60 / 834) * 100vw);
    }
  }
}

@include respond('s-medium') {
  .reward {
    //&__top-icon {
    //  --icon-width: 113px;
    //  --icon-height: 113px;
    //}
  }
}

@include respond('s-medium') {
  .reward {
    //padding-top: 20px;
    //
    //&__top-description {
    //  margin-top: 20px;
    //}
    //
    //&__name {
    //  padding-left: 20px;
    //}
    //
    //&__description,
    //&__title {
    //  //grid-column: span 2;
    //}
    //
    //&__high {
    //  padding-bottom: 100px;
    //}
  }
}

@include respond('small') {
  .reward {
    //padding-top: 10px;
    //
    //&__top-icon {
    //  --icon-width: 87px;
    //  --icon-height: 87px;
    //}
    //
    //&__top-description {
    //  padding-top: 10px;
    //}
    //
    //&__description,
    //&__name {
    //  padding-top: 20px;
    //}
    //
    //&__high {
    //  padding-bottom: 50px;
    //}

    &__description {
      margin-top: 20px;
    }

    &__icon {
      margin-left: 10px;
    }
  }
}

@include respond-down('small') {
  .reward {
    //&__title,
    //&__description {
    //  grid-column: span 2;
    //}

    &__title {
      //flex-direction: column;
    }

    &__icon {
      width: calc((30 / 374) * 100vw);
      height: calc((30 / 374) * 100vw);
    }
  }
}

@include respond('x-small') {
  .reward {
    //padding-top: calc((10 / 375) * 100vw);
    //
    //&__top-icon {
    //  --icon-width: calc((87 / 375) * 100vw);
    //  --icon-height: calc((87 / 375) * 100vw);
    //}
    //
    //&__top-description {
    //  padding-top: calc((10 / 375) * 100vw);
    //}
    //
    //&__description,
    //&__name {
    //  padding-top: calc((20 / 375) * 100vw);
    //}

    //&__high {
    //  padding-bottom: calc((50 / 375) * 100vw);
    //}

    &__description {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__icon {
      margin-left: calc((10 / 374) * 100vw);
    }
  }
}