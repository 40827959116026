.who-we-are-block {
  &__item-wrap {
    grid-column: span 2;
  }

  &__info-description-inner {
    max-width: var(--grid-column2);
  }
}

@include respond-up('x-large') {
  .who-we-are-block {
    &__list {
      grid-row-gap: calc((130 / 1920) * 100vw);
    }

    &__info {
      margin-top: calc((200 / 1920) * 100vw);
    }

    &__button-wrap {
      margin-top: calc((50 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .who-we-are-block {
    &__list {
      grid-row-gap: 130px;
    }

    &__info {
      margin-top: 200px;
    }

    &__button-wrap {
      margin-top: 50px;
    }
  }
}

@include respond-up('xs-large') {
  .who-we-are-block {
    &__info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
    }

    &__info-title {
      grid-column: 1/2;
    }

    &__info-content {
      grid-column: 2/3;
    }
  }
}

@include respond('xs-large') {
  .who-we-are-block {
    &__list {
      grid-row-gap: calc((130 / 1440) * 100vw);
    }

    &__info {
      margin-top: calc((200 / 1440) * 100vw);
    }

    &__button-wrap {
      margin-top: calc((50 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .who-we-are-block {
    &__list {
      grid-row-gap: 70px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-top: 120px;
      grid-row-gap: 0;
    }

    &__info-content {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .who-we-are-block {
    &__item-wrap {
      grid-column: span 4;
    }

    &__info-description-inner {
      max-width: var(--grid-column4);
    }
  }
}

@include respond('small') {
  .who-we-are-block {
    &__list {
      grid-row-gap: 40px;
    }

    &__info {
      margin-top: 60px;
    }

    &__button-wrap {
      margin-top: 20px;
    }

    &__info-content {
      margin-top: 10px;
    }
  }
}

@include respond('x-small') {
  .who-we-are-block {
    &__list {
      grid-row-gap: calc((40 / 374) * 100vw);
    }

    &__info {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__button-wrap {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__info-content {
      margin-top: calc((10 / 374) * 100vw);
    }
  }
}