.hamburger {
  display: flex;
  align-items: center;
  height: 100%;

  &__wrap {
    position: relative;
    display: flex;
    width: var(--hamburger-width);
    height: var(--hamburger-height);
  }

  &__line {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    height: 2px;
    background-color: var(--border-color);
    transition-duration: .2s;
    transition-timing-function: var(--default-timing-function);

    &_top {
      top: 0;
      transition-property: background-color, top, transform, width;
    }

    &_bottom {
      bottom: 0;
      transition-property: background-color, bottom, transform, width;
    }
  }

  &._open {
    .hamburger__line {
      width: var(--opened-line-width);

      &_top {
        transform: translate3d(-50%, var(--opened-line-translate), 0) rotate(45deg);
      }

      &_bottom {
        transform: translate3d(-50%, calc(var(--opened-line-translate) * -1), 0) rotate(-45deg);
      }
    }
  }
}

@include respond-up('x-large') {
  .hamburger {
    --hamburger-width: calc((56 / 1920) * 100vw);
    --hamburger-height: calc((16 / 1920) * 100vw);
    --opened-line-width: calc((48 / 1920) * 100vw);
    --opened-line-translate: calc((7 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  .hamburger {
    --hamburger-width: 56px;
    --hamburger-height: 16px;
    --opened-line-width: 48px;
    --opened-line-translate: 7px;
  }
}

@include respond('xs-large') {
  .hamburger {
    --hamburger-width: calc((56 / 1440) * 100vw);
    --hamburger-height: calc((16 / 1440) * 100vw);
    --opened-line-width: calc((48 / 1440) * 100vw);
    --opened-line-translate: calc((7 / 1440) * 100vw);
  }
}

@include respond-down('medium') {
  .hamburger {
    padding: 0 var(--grid-spacer);
  }
}

@include respond-down('small') {
  .hamburger {
    --hamburger-width: 40px;
    --hamburger-height: 12px;
    --opened-line-width: 32px;
    --opened-line-translate: 5px;
  }
}

@include respond('x-small') {
  .hamburger {
    --hamburger-width: calc((40 / 375) * 100vw);
    --opened-line-width: calc((32 / 375) * 100vw);
  }
}