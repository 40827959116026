.index-page-direction {
  &__description {
    position: relative;

    .edit-block {
      //что делает эта строчка, она зачем была нужна
      //min-height: 40px;
      max-width: var(--grid-column2);
    }

    .edit-block__link {
      margin-top: 0;
    }
  }
}

@include respond-up('xs-large') {
  .index-page-direction {
    margin-top: 200px;
    display: grid;
    grid-template-columns: repeat(2, var(--grid-column2));
    gap: var(--grid-gap);


    &__description {
      .edit-block__link {
        bottom: unset;
        top: -48px;
      }
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .index-page-direction {
    margin-top: 120px;

    &__description {
      .edit-block {
        max-width: var(--grid-column4);
      }

      .edit-block__link {
        bottom: unset;
        top: -48px;
      }
    }
  }
}

@include respond-down('medium') {
  .index-page-direction {
    &__description {
      margin-top: 20px;
    }
  }
}

@include respond('small') {
  .index-page-direction {
    margin-top: 60px;

    &__description {
      .edit-block__link {
        bottom: unset;
        top: -48px;
      }
    }
  }
}

@include respond('x-small') {
  .index-page-direction {
    margin-top: 60px;

    &__description {
      .edit-block__link {
        bottom: unset;
        top: -52px;
      }
    }
  }
}