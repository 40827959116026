.progress-bar {
  &.swiper-pagination-progressbar {
    &.swiper-pagination-horizontal {
      height: 2px;
      background-color: var(--translucency-color);
      transition: background-color .4s var(--default-timing-function);
    }

    .swiper-pagination-progressbar-fill {
      background-color: var(--border-color);
      transition: background-color, transform;
      transition-timing-function: var(--default-timing-function);
      transition-duration: .4s, 1s !important;
    }
  }
}

@include respond-up('large') {
  .progress-bar {

  }
}

@include respond-up('medium') {
  .progress-bar {

  }
}

@include respond('medium') {
  .progress-bar {

  }
}

@include respond-down('medium') {
  .progress-bar {

  }
}

@include respond-down('small') {
  .progress-bar {

  }
}