.article-card {
  border-top: 2px solid var(--text-color);
  border-left: 2px solid var(--text-color);

  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: border-color .4s var(--default-timing-function);

  &__picture {
    overflow: hidden;
    display: flex;
    z-index: 1;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s var(--default-timing-function);
  }

  &__created-at {
    color: var(--translucency-color);
  }

  &__created-at,
  &__category {
    @extend %text-item;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__bottom-wrapper,
  &__date-wrapper {
    display: flex;
  }

  &__bottom-wrapper {
    justify-content: space-between;
  }

  &__title {
    display: block;
    transition: text-decoration-color .4s, color .4s;
    transition-timing-function: var(--default-timing-function);
  }

  &__arrow {
    display: flex;

    svg {
      path {
        stroke: var(--border-color);
        transition: stroke .4s var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .article-card {
    padding-top: calc((30 / 1920) * 100vw);
    padding-left: calc((30 / 1920) * 100vw);

    &__picture {
      height: calc((var(--grid-column2) - calc((30 / 1920) * 100vw)) * 419 / 885);
    }

    &__bottom-wrapper {
      margin-top: calc((90 / 1920) * 100vw);
    }

    &__title {
      padding-top: calc((30 / 1920) * 100vw);
    }

    &__title-inner {
      max-width: calc(var(--grid-column2) - ((30 / 1920) * 100vw));
    }

    &__created-at {
      padding-left: calc((20 / 1920) * 100vw);
    }

    &__arrow {
      svg {
        width: calc((25 / 1920) * 100vw);
        height: calc((25 / 1920) * 100vw);
      }
    }
  }
}

@include respond-between('s-large', 'large') {
  .article-card {
    padding-top: 30px;
    padding-left: 30px;

    &__picture {
      height: calc((var(--grid-column2) - 30px) * 419 / 885);
    }

    &__bottom-wrapper {
      margin-top: 90px;
    }

    &__title {
      padding-top: 30px;
    }

    &__title-inner {
      max-width: calc(var(--grid-column2) - 30px);
    }

    &__created-at {
      padding-left: 20px;
    }
  }
}

@include respond-down('large') {
  .article-card {
    &__arrow {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .article-card {
    &__title {
      text-decoration: underline;
      text-decoration-color: var(--t);
      text-decoration-thickness: 2px;
      text-underline-offset: 7px;
    }

    &:hover {
      .article-card {
        &__title {
          text-decoration-color: var(--border-color);
        }

        &__image {
          transform: scale(1.05);
        }
      }
    }
  }
}

@include respond('xs-large') {
  .article-card {
    padding-top: calc((30 / 1440) * 100vw);
    padding-left: calc((30 / 1440) * 100vw);

    &__picture {
      height: calc((var(--grid-column2) - calc((30 / 1440) * 100vw)) * 419 / 885);
    }

    &__bottom-wrapper {
      margin-top: calc((90 / 1440) * 100vw);
    }

    &__title {
      padding-top: calc((30 / 1440) * 100vw);
    }

    &__title-inner {
      max-width: calc(var(--grid-column2) - ((30 / 1440) * 100vw));
    }

    &__created-at {
      padding-left: calc((20 / 1440) * 100vw);
    }

    &__arrow {
      svg {
        width: calc((25 / 1440) * 100vw);
        height: calc((25 / 1440) * 100vw);
      }
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .article-card {
    padding-top: 20px;
    padding-left: 20px;

    &__picture {
      height: calc((var(--grid-column4) - 20px) * 419 / 885);
    }

    &__bottom-wrapper {
      margin-top: 50px;
    }

    &__title {
      padding-top: 20px;
    }

    &__title-inner {
      max-width: calc(var(--grid-column4) - 20px);
    }

    &__created-at {
      padding-left: 10px;
    }
  }
}

@include respond('small') {
  .article-card {
    padding-top: 10px;
    padding-left: 12px;

    &__picture {
      height: calc((var(--grid-column2) - 10px) * 419 / 885);
    }

    &__bottom-wrapper {
      margin-top: 30px;
    }

    &__title {
      padding-top: 10px;
    }

    &__title-inner {
      max-width: calc(var(--grid-column2) - 10px);
    }

    &__created-at {
      padding-left: 10px;
    }

    &__arrow {
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
}

@include respond-down('x-small') {
  .article-card {
    padding-top: calc((10 / 374) * 100vw);
    padding-left: calc((12 / 374) * 100vw);

    &__picture {
      height: calc((var(--grid-column2) - calc((10 / 374) * 100vw)) * 419 / 885);
    }

    &__bottom-wrapper {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__title {
      padding-top: calc((10 / 374) * 100vw);
    }

    &__title-inner {
      max-width: calc(var(--grid-column2) - ((10 / 374) * 100vw));
    }

    &__created-at {
      padding-left: calc((10 / 374) * 100vw);
    }

    &__arrow {
      svg {
        width: calc((17 / 374) * 100vw);
        height: calc((17 / 374) * 100vw);
      }
    }
  }
}