.picture-ratio {
  &__case-link-index {
    height: calc(var(--grid-column2) * (1031 / 1220));
  }

  &__subcase-main {
    height: calc(var(--grid-column4) * (1395 / 2480));
  }

  &__subcase-picture-xxlarge {
    height: calc(var(--grid-column4) * (1820 / 2480));
  }

  &__subcase-picture-xlarge {
    height: calc(var(--grid-column4) * (1767 / 2480));
  }

  &__subcase-picture-medium {
    height: calc(var(--grid-column4) * (1512 / 2480));
  }

  &__subcase-picture-s-medium {
    height: calc(var(--grid-column4) * (1436 / 2480));
  }

  &__subcase-picture-xs-medium {
    height: calc(var(--grid-column4) * (1251 / 2480));
  }

  &__subcase-picture-small {
    height: calc(var(--grid-column2) * (907 / 1220));
  }

  &__subcase-picture-xsmall {
    height: calc(var(--grid-column2) * (743 / 1220));
  }

  &__subcase-picture-mini {
    height: calc(var(--grid-column) * (335 / 590));
  }

  // автоцифра
  &__subcase-picture-wide-1550 {
    height: calc(var(--grid-column4) * (2067 / 2480));
  }

  &__subcase-picture-wide-923 {
    height: calc(var(--grid-column4) * (1231 / 2480));
  }

  &__subcase-picture-half-596 {
    height: calc(var(--grid-column2) * (795 / 1220));
  }

  &__subcase-picture-wide-1086 {
    height: calc(var(--grid-column4) * (1448 / 2480));
  }

  &__subcase-picture-half-612 {
    height: calc(var(--grid-column2) * (816 / 1220));
  }

  &__subcase-picture-half-831 {
    height: calc(var(--grid-column2) * (1108 / 1220));
  }

  // osobica
  &__subcase-picture-wide-1173 {
    height: calc(var(--grid-column4) * (1979 / 2639));
  }

  &__subcase-picture-wide-1046 {
    height: calc(var(--grid-column4) * (1395 / 2480));
  }

  &__subcase-picture-half-748 {
    height: calc(var(--grid-column2) * (998 / 1220));
  }

  &__subcase-picture-wide-1128 {
    height: calc(var(--grid-column4) * (1504 / 2480));
  }

  &__subcase-picture-wide-1181 {
    height: calc(var(--grid-column4) * (1575 / 2480));
  }

  // хлынов
  &__subcase-picture-wide-1046 {
    height: calc(var(--grid-column4) * (1395 / 2480));
  }

  &__subcase-picture-half-786 {
    height: calc(var(--grid-column2) * (1048 / 1220));
  }

  &__subcase-picture-half-748 {
    height: calc(var(--grid-column2) * (998 / 1220));
  }
}

@include respond-down('medium') {
  .picture-ratio {
    &__subcase-picture-mini {
      height: calc(var(--grid-column2) * (335 / 590));
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .picture-ratio {
    &__case-link-index {
      height: calc(var(--grid-column4) * (1031 / 1220));
    }

    &__subcase-picture-small {
      height: calc(var(--grid-column4) * (907 / 1220));
    }

    &__subcase-picture-xsmall {
      height: calc(var(--grid-column4) * (743 / 1220));
    }

    // автоцифра
    &__subcase-picture-half-596 {
      height: calc(var(--grid-column4) * (795 / 1220));
    }

    &__subcase-picture-half-612 {
      height: calc(var(--grid-column4) * (816 / 1220));
    }

    &__subcase-picture-half-831 {
      height: calc(var(--grid-column4) * (1108 / 1220));
    }

    // osobica
    &__subcase-picture-half-748 {
      height: calc(var(--grid-column4) * (998 / 1220));
    }

    // хлынов
    &__subcase-picture-half-786 {
      height: calc(var(--grid-column4) * (1048 / 1220));
    }

    &__subcase-picture-half-748 {
      height: calc(var(--grid-column4) * (998 / 1220));
    }
  }
}

@include respond-down('small') {
  .picture-ratio {
    &__subcase-main {
      height: calc(var(--grid-column2) * (1395 / 2480));
    }

    &__subcase-picture-xxlarge {
      height: calc(var(--grid-column2) * (1820 / 2480));
    }

    &__subcase-picture-xlarge {
      height: calc(var(--grid-column2) * (1767 / 2480));
    }

    &__subcase-picture-medium {
      height: calc(var(--grid-column2) * (1512 / 2480));
    }

    &__subcase-picture-s-medium {
      height: calc(var(--grid-column2) * (1436 / 2480));
    }

    &__subcase-picture-xs-medium {
      height: calc(var(--grid-column2) * (1251 / 2480));
    }

    // автоцифра
    &__subcase-picture-wide-1550 {
      height: calc(var(--grid-column2) * (2067 / 2480));
    }

    &__subcase-picture-wide-923 {
      height: calc(var(--grid-column2) * (1231 / 2480));
    }

    &__subcase-picture-wide-1086 {
      height: calc(var(--grid-column2) * (1448 / 2480));
    }

    // osobica
    &__subcase-picture-wide-1173 {
      height: calc(var(--grid-column2) * (1979 / 2639));
    }

    &__subcase-picture-wide-1046 {
      height: calc(var(--grid-column2) * (1395 / 2480));
    }

    &__subcase-picture-wide-1128 {
      height: calc(var(--grid-column2) * (1504 / 2480));
    }

    &__subcase-picture-wide-1181 {
      height: calc(var(--grid-column2) * (1575 / 2480));
    }

    // хлынов
    &__subcase-picture-wide-1046 {
      height: calc(var(--grid-column2) * (1395 / 2480));
    }
  }
}