.header-drop-menu {
  display: flex;
  transition: background-color .4s, opacity .4s;
  transition-timing-function: var(--default-timing-function);
  transition-delay: .4s;
  background-color: var(--background-color);

  position: absolute;
  top: var(--header-height);
  left: 0;
  z-index: 100;

  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));

  opacity: 0;
  pointer-events: none;

  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    position: relative;
    z-index: 1500;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__switcher-wrapper {
    display: flex;
    align-items: center;
    transition: opacity var(--default-timing-function) .4s;
    opacity: 0;
  }

  &__switcher-text {
    transition: color .4s var(--default-timing-function);
    color: var(--text-color);
  }

  &__bottom {
    transition: opacity var(--default-timing-function) .4s;
    opacity: 0;
    align-items: center;
  }

  &__phone,
  &__email {
    display: flex;
    align-items: center;
    height: 100%;
    width: max-content;
  }

  &._open,
  &._close {
    .header-drop-menu-list {
      &__link-title {
        transition-property: transform, opacity;
        transition-duration: 1s;
        transition-timing-function: var(--default-timing-function);
      }
    }
  }

  &._open {
    opacity: 1;
    pointer-events: auto;
    transition-delay: 0s;

    .header-drop-menu {
      &__switcher-wrapper {
        opacity: 1;
        transition-delay: .6s;
      }

      &__bottom {
        opacity: 1;
        transition-delay: .7s;
      }
    }

    .header-drop-menu-list {
      &__item {
        &::after {
          transform: scaleX(1);
        }

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            .header-drop-menu-list {
              &__link-title {
                transition-delay: calc(.2s + (#{$i} * .1s));
              }
            }

            &::after {
              transition-delay: calc(.1s + (#{$i} * .1s));
            }
          }
        }
      }

      &__link-title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

  &._close {
    transition-delay: 0.3s;
    .header-drop-menu-list {
      &__item {
        &:nth-child(1) {
          .header-drop-menu-list {
            &__link-title {
              transition-delay: 0.08s;
            }
          }
        }
        &:nth-child(2) {
          .header-drop-menu-list {
            &__link-title {
              transition-delay: 0.06s;
            }
          }
        }
        &:nth-child(3) {
          .header-drop-menu-list {
            &__link-title {
              transition-delay: 0.04s;
            }
          }
        }
        &:nth-child(4) {
          .header-drop-menu-list {
            &__link-title {
              transition-delay: 0.02s;
            }
          }
        }
        &:nth-child(5) {
          .header-drop-menu-list {
            &__link-title {
              transition-delay: 0s;
            }
          }
        }
      }
      &__link-title {
        opacity: 0;
        transition-duration: 0.4s;
      }
    }
  }

  .header-drop-menu-list {
    &__text {
      &._active {
        color: var(--translucency-color);
      }
    }
  }
}


@include respond-up('xs-large') {
  .header-drop-menu {
    display: none;
  }
}

@include respond-down('medium') {
  .header-drop-menu {
    padding-top: 100px;

    &__switcher-wrapper {
      margin-top: 50px;
    }

    &__switcher-text {
      margin-left: 14px;
    }

    &__bottom {
      margin-top: 202px;
    }

    &__phone {
      grid-column: 1/3;
    }

    &__email {
      grid-column: 3/4;
    }

    &__lang {
      grid-column: 4/5;
      justify-self: flex-end;

      .lang__link {
        padding: var(--grid-spacer);
      }
    }

    &._close {
      .header-drop-menu-list {
        &__link-title {
          transform: translate3d(50px, 0, 0);
        }
      }
    }
  }
}

@include respond-down('small') {
  .header-drop-menu {
    padding-top: 44px;

    &__switcher-wrapper {
      margin-top: 30px;
    }

    &__bottom {
      //margin-top: 222px;
      margin-top: auto;
    }

    &__phone {
      grid-column: 1/2;
    }

    &__email {
      display: none;
    }

    &__lang {
      grid-column: 2/3;
    }

    &._close {
      .header-drop-menu-list {
        &__link-title {
          transform: translate3d(30px, 0, 0);
        }
      }
    }
  }
}

@include respond('x-small') {
  .header-drop-menu {
    padding-top: calc((44 / 375) * 100vw);

    &__switcher-wrapper {
      margin-top: calc((30 / 375) * 100vw);
    }

    &__switcher-text {
      margin-left: calc((14 / 375) * 100vw);
    }

    &__bottom {
      //margin-top: calc((222 / 375) * 100vw);
    }
  }
}