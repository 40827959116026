.footer {
  background-color: var(--opposite-background-color);
  overflow: hidden;
  position: relative;
  opacity: 1;
  transition: opacity .7s .6s, background-color .4s;
  transition-timing-function: var(--default-timing-function);

  &__who {
    display: flex;
    align-items: center;
  }

  &__who,
  &__phone {
    color: var(--opposite-text-color);
  }

  &__lang {
    .button__text {
      @extend %text-main;
    }
  }

  &__lang,
  &__email {
    .button__text {
      color: var(--opposite-text-color);
    }
  }

  &__text-wrap {
    position: relative;
    z-index: 1025;
    background-color: var(--t);
    transition: background-color .4s var(--default-timing-function);
  }

  &__main {
    border-top: 2px solid var(--opposite-text-color);
    transition: border-top-color .4s var(--default-timing-function);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__link {
    display: inline-flex;
    position: relative;
  }

  &__link-inner {
    display: flex;
    align-items: center;
  }

  &__link-title {
    color: var(--opposite-text-color);
  }

  &__link-icon {
    display: inline-flex;

    svg {
      width: 100%;
      height: 100%;

      line {
        stroke: var(--opposite-text-color);
        transition: stroke .4s var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .footer {
    padding-top: calc((200 / 1920) * 100vw);

    &__main {
      margin-top: calc((70 / 1920) * 100vw);
      padding-top: calc((50 / 1920) * 100vw);
      grid-row-gap: calc((170 / 1920) * 100vw);
    }

    &__email {
      margin-top: calc((15 / 1920) * 100vw);
    }

    &__text-wrap {
      padding-bottom: calc((30 / 1920) * 100vw);
    }

    &__link-icon {
      width: calc((80 / 1920) * 100vw);
      height: calc((80 / 1920) * 100vw);
      margin-left: calc((40 / 1920) * 100vw);
    }
  }
}

@include respond('large') {
  .footer {
    &__text-wrap {
      padding-bottom: 30px;
    }
  }
}

@include respond-down('large') {
  .footer {
    padding-top: 200px;

    &__main {
      margin-top: 70px;
      padding-top: 50px;
    }

    &__email {
      margin-top: 15px;
    }

    &__link-icon {
      width: calc((80 / 1920) * 100vw);
      height: calc((80 / 1920) * 100vw);
      margin-left: 40px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .footer {
    &__main {
      grid-row-gap: 170px;
    }
  }
}

@include respond('s-large') {
  .footer {
    &__text-wrap {
      padding-bottom: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .footer {
    &__main {
      margin-top: calc((70 / 1440) * 100vw);
      padding-top: calc((50 / 1440) * 100vw);
    }

    &__info,
    &__who {
      grid-column: 1/3;
    }

    &__menu,
    &__lang {
      grid-column: 3/4;
    }

    &__socials {
      grid-column: 4/5;
    }

    &__link-inner {
      transition: transform .47s cubic-bezier(.55, 0, .1, 1) 0ms;

      &:first-child {
        transform: translate3d(0, 0, 0);
      }

      &:last-child {
        transform: translate3d(0, -100%, 0);
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__link {
      overflow: hidden;

      &:hover {
        .footer {
          &__link-inner {
            &:first-child {
              transform: translate3d(0, 100%, 0);
            }

            &:last-child {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }
  }
}

@include respond('xs-large') {
  .footer {
    padding-top: calc((200 / 1440) * 100vw);

    &__main {
      grid-row-gap: calc((170 / 1440) * 100vw);
    }

    &__email {
      margin-top: calc((15 / 1440) * 100vw);
    }

    &__text-wrap {
      padding-bottom: calc((30 / 1440) * 100vw);
    }

    &__link-icon {
      margin-left: calc((40 / 1440) * 100vw);
    }
  }
}

@include respond('medium') {
  .footer {

  }
}


@include respond-down('medium') {
  .footer {
    padding-top: 120px;

    &__info {
      grid-column: 1/3;
    }

    &__menu {
      grid-column: 3/5;
    }

    &__who {
      grid-column: 1/2;
    }

    &__lang {
      grid-column: 2/3;
    }

    &__socials {
      grid-column: 4/5;
    }

    &__main {
      margin-top: 40px;
      padding-top: 30px;
      grid-row-gap: 0;
    }

    &__text-wrap {
      padding-bottom: 20px;
    }

    &__link-icon {
      width: calc((56 / 841) * 100vw);
      height: calc((56 / 841) * 100vw);
      margin-left: calc((30 / 841) * 100vw);
    }

    &__link-inner {
      &:last-child {
        display: none;
      }
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .footer {
    &__menu {
      margin: 60px 0 140px;
    }

    &__info {
      grid-row: 1/2;
    }

    &__menu {
      grid-row: 2/3;
    }

    &__who {
      grid-row: 3/4;
    }

    &__lang,
    &__socials {
      grid-row: 3/4;
    }
  }
}

@include respond('s-medium') {
  .footer {

  }
}

@include respond-down('small') {
  .footer {
    padding-top: 60px;

    &__info,
    &__menu,
    &__socials {
      grid-column: 1/3;
    }

    &__who {
      grid-column: 1/2;
    }

    &__lang {
      grid-column: 2/3;
    }

    &__main {
      margin-top: 30px;
      padding-top: 20px;
      grid-row-gap: 60px;
    }

    &__email {
      margin-top: 10px;
    }

    &__text-wrap {
      padding-bottom: 10px;
    }

    &__link-icon {
      width: calc((25 / 374) * 100vw);
      height: calc((25 / 374) * 100vw);
      margin-left: calc((10 / 374) * 100vw);
    }
  }
}

@include respond('x-small') {
  .footer {
    padding-top: calc((60 / 374) * 100vw);

    &__main {
      margin-top: calc((30 / 374) * 100vw);
      padding-top: calc((20 / 374) * 100vw);
      grid-row-gap: calc((60 / 374) * 100vw);
    }

    &__email {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__text-wrap {
      padding-bottom: calc((10 / 374) * 100vw);
    }

    &__bottom {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__link-icon {
      width: calc((25 / 374) * 100vw);
      height: calc((25 / 374) * 100vw);
      margin-left: calc((10 / 374) * 100vw);
    }
  }
}