.contacts-form-header {
  position: relative;

  &__title {
    grid-row-gap: 0;
  }
}

@include respond-up('x-large') {
  .contacts-form-header {
    grid-row-gap: calc((70 / 1920) * 100vw);

    &_game {
      height: calc(100vh - var(--header-height));
    }

    &__game-wrap {
      top: calc((130 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .contacts-form-header {
    &_game {
      height: calc(100vh - 60px);
    }

    &__game-wrap {
      top: 130px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .contacts-form-header {
    grid-row-gap: 70px;
  }
}

@include respond-up('xs-large') {
  .contacts-form-header {
    &_game {
      position: relative;
    }

    &__game-wrap {
      position: absolute;
      z-index: 100;
    }

    &__game-time-block {
      display: flex;
      width: 268px;
      border: 2px solid var(--border-color);
      padding: 20px;
      position: absolute;
      font-size: 18px;
      right: 0;
      top: 0;
      transition: opacity var(--default-timing-function) 0.8s;
      opacity: 0;
      &._visible {
        opacity: 1;
      }
    }

    &__game-time-text {
      color: var(--translucency-color);
      white-space: nowrap;
    }

    &__game-time {
      margin-left: 20px;
    }

    &__title-text {
      grid-column: 1/5;

      &:nth-child(2) {
        //grid-column: 1/5;
        //display: flex;
        //justify-content: flex-end;
        text-align: right;
      }

      &:nth-child(3) {
        display: none;
      }
    }

    &__title {
      grid-column: 1/5;
      transition: transform linear 15s, opacity var(--default-timing-function) 0.8s;
      opacity: 1;
      &._animated {
        transform: translateX(-110%);
      }
      &._game-over {
        opacity: 0;
      }
    }

    &__description {
      width: var(--grid-column);
      grid-column: 3/4;
      opacity: 1;
      pointer-events: auto;

      &._hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

@include respond('xs-large') {
  .contacts-form-header {
    grid-row-gap: calc((70 / 1440) * 100vw);
    &_game {
      height: calc(100vh - var(--header-height));
    }

    &__game-wrap {
      top: calc((130 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .contacts-form-header {
    grid-row-gap: 60px;

    &__game-time-block {
      display: none;
    }

    &__title-text {
      grid-column: 1/5;
    }

    &__description {
      width: var(--grid-column2);
      grid-column: 1/3;
    }
  }
}

@include respond-down('small') {
  .contacts-form-header {
    grid-row-gap: 40px;

    &__title,
    &__title-text {
      grid-column: 1/3;
    }
  }
}

@include respond('x-small') {
  .contacts-form-header {
    grid-row-gap: calc((40 / 374) * 100vw);
  }
}