.info-item {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid var(--border-color);
  transition: border-color .4s var(--default-timing-function);
  width: 100%;

  &__wrap {
    display: flex;
    overflow: hidden;

    //&:nth-child(2) {
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translate3d(-50%, -50%, 0);
    //}
  }
}

@include respond-up('x-large') {
  .info-item {
    padding: calc((30 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  .info-item {
    padding: 30px;
  }
}

@include respond-up('xs-large') {
  .info-item {
    height: calc((442 / 1920) * 100vw);
  }
}

@include respond('xs-large') {
  .info-item {
    padding: calc((30 / 1440) * 100vw);
  }
}

@include respond-up('medium') {
  .info-item {

  }
}

@include respond('medium') {
  .info-item {

  }
}

@include respond-between('s-medium', 'medium') {
  .info-item {
    padding: 20px;
    height: calc((387 / 834) * 100vw);
  }
}

@include respond('small') {
  .info-item {
    padding: 12px;

    &_text-only {
      padding: 12px 12px 50px;
    }
  }
}

@include respond-down('small') {
  .info-item {
    min-height: calc((172 / 374) * 100vw);
  }
}

@include respond('x-small') {
  .info-item {
    padding: calc((12 / 374) * 100vw);

    &_text-only {
      padding: calc((12 / 374) * 100vw) calc((12 / 374) * 100vw) calc((50 / 374) * 100vw);
    }
  }
}