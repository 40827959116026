.article-page-top {
  grid-row-gap: 0;

  &__heading {
    grid-column: 1/5;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('x-large') {
  .article-page-top {
    &__picture-wrap {
      margin-top: calc((15 / 1920) * 100vw * -1);
    }
  }
}

@include respond-between('s-large', 'large') {
  .article-page-top {
    &__picture-wrap {
      margin-top: -15px;
    }
  }
}

@include respond-up('xs-large') {
  .article-page-top {
    &__picture-wrap {
      height: calc((657 / 1920) * 100vw);
      grid-column: 2/5;
    }
  }
}

@include respond-up('xs-large') {
  .article-page-top {
    &__picture-wrap {
      margin-top: calc((15 / 1440) * 100vw * -1);
    }
  }
}

@include respond-up('medium') {
  .article-page-top {

  }
}

@include respond('medium') {
  .article-page-top {

  }
}

@include respond-down('medium') {
  .article-page-top {
    &__picture-wrap {
      height: calc((376 / 834) * 100vw);
      grid-column: 1/5;
      margin-top: 70px;
    }
  }
}

@include respond-down('small') {
  .article-page-top {
    &__heading {
      grid-column: 1/3;
    }

    &__picture-wrap {
      height: calc((168 / 374) * 100vw);
      grid-column: 1/3;
      margin-top: 30px;
    }
  }
}

@include respond('x-small') {
  .article-page-top {
    &__picture-wrap {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}