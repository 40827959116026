.blog-articles {
  &__item {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('x-large') {
  .blog-articles {
    &__list {
      grid-row-gap: calc((130 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .blog-articles {
    &__list {
      grid-row-gap: 130px;
    }
  }
}

@include respond-up('xs-large') {
  .blog-articles {
    &__item {
      grid-column: span 2;
    }
  }
}

@include respond('xs-large') {
  .blog-articles {
    &__list {
      grid-row-gap: calc((130 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .blog-articles {
    &__list {
      grid-row-gap: 60px;
    }

    &__item {
      grid-column: span 4;
    }
  }
}

@include respond('medium') {
  .blog-articles {

  }
}

@include respond-down('medium') {
  .blog-articles {

  }
}

@include respond('small') {
  .blog-articles {
    &__list {
      grid-row-gap: 40px;
    }
  }
}

@include respond-down('small') {
  .blog-articles {
    &__item {
      grid-column: span 2;
    }
  }
}

@include respond('x-small') {
  .blog-articles {
    &__list {
      grid-row-gap: calc((40 / 374) * 100vw);
    }
  }
}
