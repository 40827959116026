.technologic {
  #main {
    line {
      stroke-dasharray: 134;
      stroke-dashoffset: 0;
      animation: main-line-anim 3.05s var(--default-timing-function) infinite;

      @for $i from 1 through 11 {
        &:nth-child(#{$i}) {
          animation-delay: calc(#{$i} * .05s);
        }
      }
    }
  }

  #double {
    line {
      stroke-dasharray: 134;
      stroke-dashoffset: 134;
      animation: double-line-anim 3.05s var(--default-timing-function) infinite;

      @for $i from 1 through 11 {
        &:nth-child(#{$i}) {
          animation-delay: calc(#{$i} * .05s);
        }
      }
    }
  }
}

@keyframes main-line-anim {
  from {
    stroke-dashoffset: 268;
  }
  22.95% {
    stroke-dashoffset: 134;
  }
  50.81% {
    stroke-dashoffset: 134;
  }
  60.65% {
    stroke-dashoffset: 134;
  }
  83.6% {
    stroke-dashoffset: 268;
  }
  to {
    stroke-dashoffset: 268;
  }
}

@keyframes double-line-anim {
  from {
    stroke-dashoffset: 134;
  }
  11.47% {
    stroke-dashoffset: 134;
  }
  34.42% {
    stroke-dashoffset: 0;
  }
  50.81% {
    stroke-dashoffset: 0;
  }
  73.77% {
    stroke-dashoffset: 134;
  }
  to {
    stroke-dashoffset: 134;
  }
}