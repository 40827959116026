.index-video {
  position: relative;

  &__inner {
    width: 100%;
  }

  &__video {
    width: 100%;
    height: calc(1080 / 1920 * 100vw);
  }
}

@include respond-up('large') {
  .index-video {

  }
}

@include respond-up('medium') {
  .index-video {

  }
}

@include respond('medium') {
  .index-video {

  }
}

@include respond-down('medium') {
  .index-video {

  }
}

@include respond-down('small') {
  .index-video {

  }
}