.link-list {
  display: inline-grid;
  border-top: 2px solid var(--border-color);
  transition: border-top-color var(--default-timing-function) .4s;

  &_column-3-4-2 {
    .button__text {
      text-align: left;

      &:nth-child(3) {
        order: 2;
        text-align: right;
        justify-content: end;
      }
    }
  }

  &__link {
    display: grid;
    grid-column-gap: var(--grid-gap);

    .button__text {
      &:nth-child(1) {
        grid-column: 1/2;
      }

      &:nth-child(2) {
        grid-column: 2/3;
      }

      &:nth-child(3) {
        grid-column: 3/4;
      }

      &:nth-child(4) {
        grid-column: 4/5;
      }
    }
  }
}

@include respond-up('x-large') {
  .link-list {
    --last-text-padding-right: calc((45 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  .link-list {
    --last-text-padding-right: 45px;

    &_column-3-4-2 {
      .link-list__link {
        padding: 17px 0 19px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .link-list {
    &_column-3-4-2 {
      .link-list__link {
        grid-template-columns: repeat(3, 1fr);
        width: var(--grid-column3);
      }
    }

    .button__text-last {
      opacity: 0;
      transition: opacity var(--default-timing-function) .4s;
    }

    &:hover {
      border-top-color: var(--translucency-color);

      .button_arrow-diagonal::before {
        border-bottom-color: var(--translucency-color);
      }

      .link-list__link {
        .button__text {
          color: var(--translucency-color);
        }
      }
    }

    &__item {
      &:hover {
        .button_arrow-diagonal::before {
          border-bottom-color: var(--border-color);
        }

        .button__text-last {
          opacity: 1;
        }

        .link-list__link {
          .button__text {
            color: var(--text-color);
          }
        }
      }
    }
  }
}

@include respond('xs-large') {
  .link-list {
    --last-text-padding-right: calc((45 / 1440) * 100vw);

    &_column-3-4-2 {
      .link-list__link {
        padding: calc((17 / 1440) * 100vw) 0 calc((19 / 1440) * 100vw);
      }
    }
  }
}

@include respond-down('medium') {
  .link-list {
    &_column-3-4-2 {
      .link-list__link {
        grid-template-columns: repeat(4, 1fr);
        width: var(--grid-column4);

        .button__text {
          &:first-child {
            grid-column: 1/4;
          }

          &:nth-child(2) {
            grid-column: 1/5;
            order: 3;
            color: var(--translucency-color);
          }

          &:nth-child(3) {
            order: 2;
            grid-column: 4/5;
          }
        }
      }
    }
  }
}

@include respond-up('s-medium') {
  .link-list {
    &_column-1 {
      .link-list__link {
        grid-template-columns: 1fr;
        width: var(--grid-column);
      }

      .button__text {
        padding-right: var(--last-text-padding-right);
      }
    }

    &_column-2 {
      .link-list__link {
        grid-template-columns: repeat(2, 1fr);
        width: var(--grid-column2);
      }

      .button__text:nth-child(2) {
        padding-right: var(--last-text-padding-right);
      }
    }

    &_column-3 {
      .link-list__link {
        grid-template-columns: repeat(3, 1fr);
        width: var(--grid-column3);
      }

      .button__text:nth-child(3) {
        padding-right: var(--last-text-padding-right);
      }
    }

    &_column-4 {
      .link-list__link {
        grid-template-columns: repeat(4, 1fr);
        width: var(--grid-column4);
      }

      .button__text:nth-child(4) {
        padding-right: var(--last-text-padding-right);
      }
    }
  }
}

@include respond-down('small') {
  .link-list {
    &_column-3-4-2 {
      .link-list__link {
        grid-template-columns: repeat(2, 1fr);
        width: var(--grid-column2);
        padding: 11px 0 11px;

        .button__text {
          &:first-child {
            grid-column: 1/2;
          }

          &:nth-child(2) {
            grid-column: 1/3;
          }

          &:nth-child(3) {
            order: 2;
            grid-column: 2/3;
          }
        }
      }
    }

    &_column-1,
    &_column-2,
    &_column-3,
    &_column-4 {
      .link-list__link {
        grid-template-columns: repeat(2, 1fr);
        width: var(--grid-column2);
      }
    }

    &_column-1 {
      .link-list__link {
        .button__text {
          grid-column: 1/3;
          padding-right: var(--last-text-padding-right);
        }
      }
    }

    &_column-2,
    &_column-3,
    &_column-4 {
      .link-list__link {
        .button__text {
          &:nth-child(2n+1) {
            grid-column: 1/2;
          }

          &:nth-child(2n) {
            grid-column: 2/3;
            padding-right: var(--last-text-padding-right);
          }
        }
      }
    }
  }
}

@include respond('small') {
  .link-list {
    --last-text-padding-right: 37px;
  }
}

@include respond('x-small') {
  .link-list {
    --last-text-padding-right: calc((37 / 375) * 100vw);

    &_column-3-4-2 {
      .link-list__link {
        padding: calc((11 / 375) * 100vw) 0 calc((11 / 375) * 100vw);
      }
    }
  }
}
