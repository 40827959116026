.article-page-case-link {
  border: 2px solid var(--border-color);
  display: flex;
  transition: border-color .4s var(--default-timing-function);

  &:before {
    display: none;
  }

  &__type {
    color: var(--translucency-color);
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-top {
    display: flex;
    flex-direction: column;
  }

  &__text-bottom {
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
  }

  &__description {
    flex: 1 1 auto;
  }

  &__icon {
    flex: 0 0 auto;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--border-color);
        transition: stroke .4s var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .article-page-case-link {
    &__text-wrap {
      padding: calc((30 / 1920) * 100vw);
    }

    &__icon {
      margin-left: calc((30 / 1920) * 100vw);
      width: calc((25 / 1920) * 100vw);
      height: calc((25 / 1920) * 100vw);
    }
  }
}

@include respond-up('large') {
  .article-page-case-link {
    min-height: calc((350 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  .article-page-case-link {
    &__icon {
      width: 25px;
      height: 25px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .article-page-case-link {
    &__text-wrap {
      padding: 30px;
    }

    &__icon {
      margin-left: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .article-page-case-link {
    &__picture-wrap {
      width: var(--grid-column1);
    }

    &__text-wrap {
      flex: 1 0 calc(var(--grid-column) + var(--grid-gap));
    }

    &__image {
      transition: transform 1s var(--default-timing-function);
    }

    &:hover {
      .article-page-case-link__image {
        transform: scale(1.05);
      }
    }
  }
}

@include respond('xs-large') {
  .article-page-case-link {
    min-height: calc((258 / 1440) * 100vw);

    &__text-wrap {
      padding: calc((30 / 1440) * 100vw);
    }

    &__icon {
      margin-left: calc((30 / 1440) * 100vw);
      width: calc((25 / 1440) * 100vw);
      height: calc((25 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .article-page-case-link {
    &__text-wrap {
      padding: 20px;
    }

    &__picture-wrap {
      width: var(--grid-column2);
    }

    &__icon {
      margin-left: 20px;
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .article-page-case-link {
    min-height: calc((304 / 834) * 100vw);

    &__text-wrap {
      flex: 1 0 calc(var(--grid-column2) + var(--grid-gap));
    }
  }
}

@include respond-up('s-medium') {
  .article-page-case-link {
    &__picture-wrap {
      flex: 0 0 auto;
    }
  }
}

@include respond('small') {
  .article-page-case-link {
    &__text-wrap {
      padding: 15px;
    }

    &__icon {
      width: 17px;
      height: 17px;
    }
  }
}

@include respond-down('small') {
  .article-page-case-link {
    flex-direction: column;

    &__picture-wrap {
      height: calc((280 / 374) * 100vw);
      width: 100%;
    }
  }
}

@include respond('x-small') {
  .article-page-case-link {
    &__text-wrap {
      padding: calc((15 / 374) * 100vw);
    }

    &__icon {
      margin-left: calc((20 / 374) * 100vw);
      width: calc((17 / 374) * 100vw);
      height: calc((17 / 374) * 100vw);
    }

    &__text-bottom {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}