.agency-scroll-text {
  &__inner {
    display: flex;
    justify-content: flex-end;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--border-color);
        transition: stroke .4s var(--default-timing-function);
      }
    }
  }

  &__title {
    color: var(--translucency-color);
  }
}

@include respond-up('x-large') {
  .agency-scroll-text {
    &__title {
      margin-left: calc((30 / 1920) * 100vw);
    }

    &__icon {
      width: calc((161 / 1920) * 100vw);
      height: calc((78 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .agency-scroll-text {
    &__title {
      margin-left: 30px;
    }

    &__icon {
      width: 161px;
      height: 78px;
    }
  }
}

@include respond('xs-large') {
  .agency-scroll-text {
    &__title {
      margin-left: calc((30 / 1440) * 100vw);
    }

    &__icon {
      width: calc((161 / 1440) * 100vw);
      height: calc((78 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .agency-scroll-text {

  }
}

@include respond('medium') {
  .agency-scroll-text {

  }
}

@include respond-down('medium') {
  .agency-scroll-text {
    &__title {
      margin-left: 20px;
    }

    &__icon {
      width: 110px;
      height: 53px;
    }
  }
}

@include respond('small') {
  .agency-scroll-text {
    &__title {
      margin-left: 10px;
    }

    &__icon {
      width: 48px;
      height: 23px;
    }
  }
}

@include respond('x-small') {
  .agency-scroll-text {
    &__title {
      margin-left: calc((10 / 374) * 100vw);
    }

    &__icon {
      width: calc((48 / 374) * 100vw);
      height: calc((23 / 374) * 100vw);
    }
  }
}