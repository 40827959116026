.contact-us {
  grid-row-gap: 0;

  &__picture {
    display: flex;
    width: 100%;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__requisites-wrap {
    display: flex;
    flex-direction: column;
  }

  &__requisites {
    width: max-content;
  }
}

@include respond-up("x-large") {
  .contact-us {
    margin-top: calc((30 / 1920) * 100vw);

    &__text {
      margin-top: calc((30 / 1920) * 100vw);
    }

    &__text-top {
      margin-top: calc((20 / 1920) * 100vw);
    }

    &__requisites-wrap {
      margin-top: calc((72 / 1920) * 100vw);
    }

    &__requisites {
      margin-top: calc((20 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .contact-us {
    margin-top: 30px;

    &__requisites-wrap {
      margin-top: 72px;
    }

    &__requisites {
      margin-top: 20px;
    }
  }
}

@include respond-up("s-large") {
  .contact-us {
    &__text {
      margin-top: 30px;
    }

    &__text-top {
      margin-top: 20px;
    }
  }
}

@include respond-up("xs-large") {
  .contact-us {
    &__description {
      grid-column: 1/2;
    }

    &__person {
      &:nth-child(2) {
        grid-column: 3/4;
      }

      &:nth-child(3) {
        grid-column: 4/5;
      }
    }


    &__picture {
      height: calc((425 / 1920) * 100vw);
    }
  }
}

@include respond("xs-large") {
  .contact-us {
    margin-top: calc((30 / 1440) * 100vw);

    &__text {
      margin-top: calc((30 / 1440) * 100vw);
    }

    &__text-top {
      margin-top: calc((20 / 1440) * 100vw);
    }

    &__requisites-wrap {
      margin-top: calc((72 / 1440) * 100vw);
    }

    &__requisites {
      margin-top: calc((20 / 1440) * 100vw);
    }
  }
}

@include respond-up("medium") {
  .contact-us {

  }
}

@include respond("medium") {
  .contact-us {
    &__person {
      margin-top: 80px;
    }

    &__text-top {
      margin-top: 20px;
    }

    &__text {
      margin-top: 30px;
    }
  }
}

@include respond-down("medium") {
  .contact-us {
    margin-top: 20px;

    &__description {
      grid-column: 1/3;
    }

    &__person {
      &:nth-child(2) {
        grid-column: 1/3;
      }

      &:nth-child(3) {
        grid-column: 3/5;
      }
    }

    &__picture {
      height: calc((372 / 834) * 100vw);
    }

    &__requisites-wrap {
      margin-top: 120px;
    }
  }
}

@include respond("s-medium") {
  .contact-us {
    &__person {
      margin-top: 80px;
    }

    &__text-top {
      margin-top: 20px;
    }

    &__text {
      margin-top: 30px;
    }
  }
}

@include respond-down("small") {
  .contact-us {
    &__person {
      &:nth-child(2) {
        grid-column: 1/2;
      }

      &:nth-child(3) {
        grid-column: 2/3;
      }
    }

    &__picture {
      height: calc((165 / 374) * 100vw);
    }
  }
}

@include respond("small") {
  .contact-us {
    margin-top: 10px;

    &__text-top {
      margin-top: 10px;
    }

    &__text {
      margin-top: 20px;
    }

    &__person {
      margin-top: 50px;
    }

    &__requisites-wrap {
      margin-top: 50px;
    }

    &__requisites {
      margin-top: 10px;
    }
  }
}

@include respond-down("x-small") {
  .contact-us {
    margin-top: calc((10 / 374) * 100vw);

    &__text-top {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__text {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__person {
      margin-top: calc((50 / 374) * 100vw);
    }

    &__requisites-wrap {
      margin-top: calc((50 / 374) * 100vw);
    }

    &__requisites {
      margin-top: calc((10 / 374) * 100vw);
    }
  }
}
