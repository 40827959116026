body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .scrollbar-track {
    background: none;
  }

  .scrollbar-thumb {
    background: var(--translucency-color);
  }
}

input, button, form {
  font-family: var(--font);
}