.blog-page {

}

@include respond-up('x-large') {
  .blog-page {
    padding-bottom: calc((200 / 1920) * 100vw);

    &__articles {
      margin-top: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .blog-page {
    padding-bottom: 200px;

    &__articles {
      margin-top: 30px;
    }
  }
}

@include respond('xs-large') {
  .blog-page {
    padding-bottom: calc((200 / 1440) * 100vw);

    &__articles {
      margin-top: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .blog-page {
    &__articles {
      margin-top: 20px;
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .blog-page {
    padding-bottom: 120px;
  }
}

@include respond('small') {
  .blog-page {
    padding-bottom: 60px;
  }
}

@include respond-down('x-small') {
  .blog-page {
    padding-bottom: calc((60 / 374) * 100vw);

    &__articles {
      margin-top: calc((20 / 374) * 100vw);
    }
  }
}