.article-read-also {

}

@include respond-up('x-large') {
  .article-read-also {
    &__items {
      margin-top: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .article-read-also {
    &__items {
      margin-top: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .article-read-also {
    &__item {
      grid-column: span 2;
    }
  }
}

@include respond('xs-large') {
  .article-read-also {
    &__items {
      margin-top: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .article-read-also {

  }
}

@include respond('medium') {
  .article-read-also {

  }
}

@include respond-down('medium') {
  .article-read-also {
    &__items {
      grid-template-columns: 1fr;
      grid-row-gap: 60px;
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .article-read-also {
    &__items {
      grid-row-gap: 40px;
      margin-top: 10px;
    }
  }
}

@include respond('x-small') {
  .article-read-also {
    &__items {
      grid-row-gap: calc((40 / 374) * 100vw);
      margin-top: calc((10 / 374) * 100vw);
    }
  }
}