.edit-block {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

  ::selection,
  &::selection {
    background-color: var(--text-color);
    color: var(--background-color);
  }

  &__link {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 87%, 0);
    background: darkolivegreen;
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 5000;
    color: black;
    border: 2px solid black;
    transition: filter .1s ease-in-out;

    &_new {
      background: greenyellow;

      &.edit-block__link_media {
        background: deepskyblue;
      }
    }

    &_media {
      background: steelblue;
    }

    &:hover {
      filter: hue-rotate(90deg);
    }
  }
}

//извините
.content-header__title-text-inner{
  .edit-block {
    &__link {
      bottom: 46px;
    }
  }
}