.agency-page {
  overflow: hidden;
}

@include respond-up('x-large') {
  .agency-page {
    padding-bottom: calc((200 / 1920) * 100vw);

    &__index-numbers {
      margin-top: calc((130 / 1920) * 100vw);
    }

    &__who-we-are,
    &__numbers,
    &__rewards,
    &__certification,
    &__reviews,
    &__culture,
    &__scroll-text {
      margin-top: calc((200 / 1920) * 100vw);
    }

    &__logo {
      margin-top: calc((200 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .agency-page {
    padding-bottom: 200px;

    &__index-numbers {
      margin-top: 130px;
    }

    &__who-we-are,
    &__numbers,
    &__rewards,
    &__certification,
    &__reviews,
    &__culture,
    &__scroll-text {
      margin-top: 200px;
    }

    &__logo {
      margin-top: 200px;
    }
  }
}

@include respond('s-large') {
  .agency-page {
    &__logo {
      margin-top: calc((200 / 1440) * 100vw);
    }
  }
}

@include respond('xs-large') {
  .agency-page {
    padding-bottom: calc((200 / 1440) * 100vw);

    &__index-numbers {
      margin-top: calc((130 / 1440) * 100vw);
    }

    &__who-we-are,
    &__numbers,
    &__rewards,
    &__certification,
    &__reviews,
    &__culture,
    &__scroll-text {
      margin-top: calc((200 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .agency-page {
    padding-bottom: 120px;

    &__index-numbers {
      margin-top: 80px;
    }

    &__who-we-are,
    &__numbers,
    &__certification,
    &__reviews,
    &__culture,
    &__scroll-text {
      margin-top: 120px;
    }

    &__rewards {
      margin-top: 144px;
    }

    &__logo {
      margin-top: 120px;
    }
  }
}

@include respond('s-medium') {
  .agency-page {

  }
}

@include respond('small') {
  .agency-page {
    padding-bottom: 60px;

    &__index-numbers {
      margin-top: 50px;
    }

    &__who-we-are,
    &__numbers,
    &__rewards,
    &__certification,
    &__reviews,
    &__culture,
    &__scroll-text {
      margin-top: 60px;
    }

    &__logo {
      margin-top: 60px;
    }
  }
}

@include respond-down('x-small') {
  .agency-page {
    padding-bottom: calc((60 / 374) * 100vw);

    &__who-we-are,
    &__numbers,
    &__rewards,
    &__certification,
    &__reviews,
    &__culture,
    &__scroll-text {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__logo {
      margin-top: calc((60 / 374) * 100vw);
    }
  }
}
