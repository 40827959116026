.scroll-line {
  display: flex;
  width: max-content;

  &__item {
    display: flex;
  }

  &__icon {
    display: flex;
    height: max-content;
  }

  &__title {
    color: var(--text-color);
    transition: color .4s var(--default-timing-function);
  }
}

@include respond-up('x-large') {
  .scroll-line {
    &__item {
      padding-right: calc((90 / 1920) * 100vw);
    }

    &__icon {
      margin-top: calc((24 / 1920) * 100vw);
      margin-left: calc((14 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .scroll-line {
    &__item {
      padding-right: 90px;
    }

    &__icon {
      margin-top: 24px;
      margin-left: 14px;
    }
  }
}

@include respond('xs-large') {
  .scroll-line {
    &__item {
      padding-right: calc((90 / 1440) * 100vw);
    }

    &__icon {
      margin-top: calc((24 / 1440) * 100vw);
      margin-left: calc((14 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .scroll-line {
    &__item {
      padding-right: 40px;
    }

    &__icon {
      margin-top: 15px;
      margin-left: 14px;
    }
  }
}

@include respond('small') {
  .scroll-line {
    &__item {
      padding-right: 20px;
    }

    &__icon {
      margin-top: 8px;
      margin-left: 8px;
    }
  }
}

@include respond('x-small') {
  .scroll-line {
    &__item {
      padding-right: calc((20 / 375) * 100vw);
    }

    &__icon {
      margin-top: calc((8 / 375) * 100vw);
      margin-left: calc((8 / 375) * 100vw);
    }
  }
}