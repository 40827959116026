.game-over {

}

@include respond-up('xs-large') {
  .game-over {
    width: 486px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
    transition: opacity var(--default-timing-function) 0.8s;
    &._visible {
      opacity: 1;
      z-index: 100;
      .game-over {
        &__button {
          pointer-events: auto;
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__results-list {
      margin-top: 30px;
    }

    &__result-item {
      font-size: 18px;
      display: flex;
      align-items: flex-end;
    }

    &__time {
      margin-top: 40px;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
    }

    &__time-text {
      color: var(--translucency-color);
    }

    &__button {
      margin-top: 30px;
      height: 111px;
      align-items: center;
      pointer-events: none;
    }

    &__dots {
      width: 100%;
      height: 100%;
      border-bottom: 1px dashed var(--translucency-color);
      margin-left: 10px;
      transform: translateY(-5px);
    }

    &__result-value {
      margin-left: 10px;
    }

    &__result-name {
      white-space: nowrap;
    }
  }
}

@include respond-down('medium') {
  .game-over {
    display: none;
  }
}