.article-page-main {
  grid-row-gap: 0;

  &__article-block {
    display: block;
  }

  &__image-wrap {
    position: relative;
  }

  &__image-title {
    color: var(--translucency-color);
    margin-top: 10px;
  }

  &__picture-single {
    display: flex;
    overflow: hidden;
    width: var(--grid-column2);
  }

  &__picture-wrap {
    position: relative;
    display: flex;
    overflow: hidden;
  }

  &__picture {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__images-slider-item {
    padding-left: unset;
    will-change: transform;

    &::before {
      display: none;
    }

    .article-page-main__picture {
      height: 100%;
    }
  }

  .arrows {
    &__left {
      margin-top: -16px;
    }
  }

  &__nav {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .swiper-pagination {
    position: static;
    color: var(--text-color);
    width: max-content;
    transition: color var(--default-transition-time) var(--default-timing-function);
  }
}

@include respond-up('x-large') {
  .article-page-main {
    &__image-title,
    &__arrows {
      margin-top: calc((10 / 1920) * 100vw);
    }

    &__article-block {
      margin-top: calc((100 / 1920) * 100vw);
    }

    &__picture-wrap {
      &:not(:first-child) {
        margin-top: calc((30 / 1920) * 100vw);
      }
    }

    &__nav {
      margin-top: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .article-page-main {
    &__article-block {
      margin-top: 100px;
    }

    &__picture-wrap {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__nav {
      margin-top: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .article-page-main {
    &__sticky-wrap {
      grid-column: 1/2;
    }

    &__content {
      grid-column: 2/4;
    }

    &__picture-single {
      height: calc((516 / 1920) * 100vw);
    }

    &__images-slider-item {
      &.swiper-slide {
        height: calc((516 / 1920) * 100vw);
      }
    }
  }
}

@include respond('xs-large') {
  .article-page-main {
    &__image-title,
    &__arrows {
      margin-top: calc((10 / 1440) * 100vw);
    }

    &__article-block {
      margin-top: calc((100 / 1440) * 100vw);
    }

    &__picture-wrap {
      &:not(:first-child) {
        margin-top: calc((30 / 1440) * 100vw);
      }
    }

    &__nav {
      margin-top: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .article-page-main {

  }
}

@include respond-between('s-medium', 'medium') {
  .article-page-main {
    &__picture-single {
      width: var(--grid-column4);
      height: calc((447 / 834) * 100vw);
    }

    &__images-slider-item {
      &.swiper-slide {
        height: calc((447 / 834) * 100vw);
      }
    }

    &__article-block {
      margin-top: 70px;
    }

    &__sticky-wrap {
      margin-top: 70px;
    }

    &__nav {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .article-page-main {
    &__sticky-wrap {
      grid-column: 1/3;
      grid-row: 2/3;
    }

    &__content {
      grid-column: 1/5;
      grid-row: 1/2;
    }

    &__picture-wrap {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .article-page-main {
    &__content {
      grid-column: 1/3;
    }

    &__picture-single {
      height: calc((200 / 374) * 100vw);
    }

    &__images-slider-item {
      &.swiper-slide {
        height: calc((200 / 374) * 100vw);
      }
    }

    &__article-block {
      margin-top: 50px;
    }

    &__sticky-wrap {
      margin-top: 50px;
    }

    &__nav {
      margin-top: 10px;
    }
  }
}

@include respond('x-small') {
  .article-page-main {
    &__image-title,
    &__arrows {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__article-block,
    &__sticky-wrap {
      margin-top: calc((50 / 374) * 100vw);
    }

    &__picture-wrap {
      &:not(:first-child) {
        margin-top: calc((20 / 374) * 100vw);
      }
    }

    &__nav {
      margin-top: calc((10 / 374) * 100vw);
    }
  }
}