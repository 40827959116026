.article-page-content-header {
  &__date {
    display: flex;
    align-items: baseline;
  }

  &__title {
    &.need-animation {
      .article-page-content-header__title-inner {
        opacity: 0;

        span {
          transform: translate3d(0, 100%, 0);
        }
      }
    }
  }

  &__title-inner {
    display: block;
    overflow: hidden;
    white-space: nowrap;

    span {
      transition: transform .7s var(--default-timing-function);
    }

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc(.9s + #{$i} * .05s);
        }
      }
    }
  }
}

@include respond-up('x-large') {
  .article-page-content-header {
    &__date {
      margin-top: calc((52 / 1920) * 100vw);
    }

    &__title {
      margin-top: calc((120 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .article-page-content-header {
    &__date {
      margin-top: 52px;
    }

    &__title {
      margin-top: 120px;
    }
  }
}

@include respond-up('xs-large') {
  .article-page-content-header {
    &__date-month-year {
      margin-left: 20px;
    }

    &__title-inner {
      width: var(--grid-column3);
    }
  }
}

@include respond('xs-large') {
  .article-page-content-header {
    &__date {
      margin-top: calc((52 / 1440) * 100vw);
    }

    &__title {
      margin-top: calc((120 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .article-page-content-header {

  }
}

@include respond('medium') {
  .article-page-content-header {

  }
}

@include respond-down('medium') {
  .article-page-content-header {
    &__date {
      margin-top: 30px;
    }

    &__date-month-year {
      margin-left: 10px;
    }

    &__title {
      margin-top: 100px;
    }

    &__title-inner {
      width: var(--grid-column4);
    }
  }
}

@include respond('small') {
  .article-page-content-header {
    &__date {
      margin-top: 25px;
    }

    &__title {
      margin-top: 60px;
    }
  }
}

@include respond-down('small') {
  .article-page-content-header {
    &__title-inner {
      width: var(--grid-column2);
    }
  }
}

@include respond('x-small') {
  .article-page-content-header {
    &__date {
      margin-top: calc((25 / 374) * 100vw);
    }

    &__title {
      margin-top: calc((60 / 374) * 100vw);
    }
  }
}