.case-content-header {
  grid-row-gap: 0;

  &__overlined {
    > * {
      width: 100%;
    }
  }
}

@include respond-up('x-large') {
  .case-content-header {
    &__heading {
      margin-top: calc((80 / 1920) * 100vw);
    }

    &__description {
      margin-top: calc((90 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .case-content-header {
    &__heading {
      margin-top: 80px;
    }

    &__description {
      margin-top: 90px;
    }
  }
}

@include respond-up('xs-large') {
  .case-content-header {
    &__overlined {
      grid-column: span 2;
    }

    &__title {
      grid-column: 1/5;
    }

    &__heading {
      grid-column: 2/5;
    }

    &__description {
      grid-column: 1/2;
    }
  }
}

@include respond('xs-large') {
  .case-content-header {
    &__heading {
      margin-top: calc((80 / 1440) * 100vw);
    }

    &__description {
      margin-top: calc((90 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .case-content-header {

  }
}

@include respond('medium') {
  .case-content-header {
    &__overlined {
      grid-column: span 4;
    }
  }
}

@include respond-down('medium') {
  .case-content-header {
    &__title {
      grid-column: 1/5;
    }

    &__heading {
      grid-column: 1/5;
      margin-top: 70px;
    }

    &__description {
      grid-column: 1/3;
      margin-top: 20px;
    }
  }
}

@include respond-up('s-medium') {
  .case-content-header {
    &__heading {
      text-indent: calc(var(--grid-column) + var(--grid-gap));
    }
  }
}

@include respond('s-medium') {
  .case-content-header {
    &__overlined {
      grid-column: span 4;
    }
  }
}

@include respond('small') {
  .case-content-header {
    &__heading {
      margin-top: 30px;
    }

    &__description {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .case-content-header {
    &__overlined {
      grid-column: span 2;
    }

    &__heading,
    &__title {
      grid-column: 1/3;
    }
  }
}

@include respond('x-small') {
  .case-content-header {
    &__heading {
      margin-top: calc((30 / 375) * 100vw);
    }

    &__description {
      margin-top: calc((10 / 375) * 100vw);
    }
  }
}