.tabs {
  overflow-x: scroll;
  margin: 0 calc(var(--grid-spacer) * -1);

  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
    padding: 0 var(--grid-spacer);
  }

  &__item {
    &:not(:last-child) {
      margin-right: var(--tabs-spacer);
    }

    &._active {
      .tabs {
        &__text {
          &:before {
            background: var(--text-color);
          }
        }
      }
    }
  }

  &__link {
    padding: var(--tabs-link-padding);
    margin: calc(var(--tabs-link-padding) * -1);
    align-items: center;
    white-space: nowrap;
  }

  &__text {
    padding-left: 30px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: var(--background-color);
      border: 1px solid var(--text-color);
      transition: border-color .4s, background .4s;
      transition-timing-function: var(--default-timing-function);
    }

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }
}

@include respond('x-large') {
  .tabs {
    --tabs-spacer: calc((30 / 1920) * 100vw);
  }
}

@include respond-up('large') {
  .tabs {
    --tabs-link-padding: 15px;
  }
}

@include respond('large') {
  .tabs {
    --tabs-spacer: 30px;
  }
}

@include respond-down('s-large') {
  .tabs {
    --tabs-link-padding: 10px;
  }
}

@include respond('s-large') {
  .tabs {
    --tabs-spacer: 20px;
  }
}

@include respond-up('xs-large') {
  .tabs {
    &__link {
      &:hover {
        .tabs {
          &__text {
            &:before {
              background: var(--text-color);
            }
          }
        }
      }
    }
  }
}

@include respond('xs-large') {
  .tabs {
    --tabs-spacer: calc((20 / 1440) * 100vw);
  }
}

@include respond('medium') {
  .tabs {
    --tabs-link-padding: 15px;
    --tabs-spacer: 30px;
  }
}

@include respond-down('s-medium') {
  .tabs {
    --tabs-link-padding: 10px;
    --tabs-spacer: 20px;
  }
}

@include respond-down('x-small') {
  .tabs {
    --tabs-spacer: calc((20 / 375) * 100vw);
  }
}