.case-table {
  &.need-animation {
    .case-table {
      &__item {
        border-color: var(--t);
      }

      &__name,
      &__value {
        opacity: 0;
      }
    }
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);
    border-bottom: 2px solid white;
    transition: border-color .4s var(--default-timing-function);

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: calc(.5s + (#{$i} - 1) * .05s);

        .case-table {
          &__name,
          &__value {
            transition-delay: calc(.5s + (#{$i} - 1) * .05s);
          }
        }
      }
    }
  }

  &__name,
  &__value {
    grid-column: span 1;
    transition: opacity .7s var(--default-timing-function);
  }
}

@include respond-up('x-large') {
  .case-table {
    &__item {
      padding: calc((17 / 1920) * 100vw) 0;
    }
  }
}

@include respond('large') {
  .case-table {
    &__item {
      padding: 17px 0;
    }
  }
}

@include respond('xs-large') {
  .case-table {
    &__item {
      padding: calc((17 / 1440) * 100vw) 0;
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .case-table {
    &__item {
      padding: 17px 0;
    }
  }
}

@include respond-down('small') {
  .case-table {
    &__item {
      padding: 8px 0 12px;
      grid-template-columns: 1fr;

      &:first-child {
        display: none;
      }

      &:nth-child(2) {
        border-top: 1px solid white;
      }
    }

    &__name {
      color: var(--light-color-40);
    }
  }
}