.grid-block {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns-number), var(--grid-column));
  grid-gap: var(--grid-gap);
}

@include respond-up('xs-large') {
  .grid-block {
    &_desktop-half {
      grid-column: span 2;
      grid-template-columns: repeat(2, var(--grid-column));
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .grid-block {
    &_desktop-half {
      grid-column: span 4;
    }
  }
}

@include respond-down('small') {
  .grid-block {
    &_desktop-half {
      grid-column: span 2;
      grid-template-columns: repeat(2, var(--grid-column));
    }
  }
}