.info-block {
  &__small-title {
    color: var(--text-color);
  }

  &__socials-list {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }
}

@include respond-up('x-large') {
  .info-block {
    &__list {
      grid-row-gap: calc((32 / 1920) * 100vw);
    }

    &__small-title {
      margin-bottom: calc((20 / 1920) * 100vw);
    }

    &__socials {
      margin-top: calc((20 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .info-block {
    &__list {
      grid-row-gap: 32px;
    }

    &__small-title {
      margin-bottom: 20px;
    }

    &__socials {
      margin-top: 20px;
    }
  }
}

@include respond-up('xs-large') {
  .info-block {
    &__item {
      &:nth-child(1) {
        grid-column: 1/3;
      }

      &:nth-child(2),
      &:nth-child(3) {
        grid-column: 3/5;
      }
    }
  }
}

@include respond('xs-large') {
  .info-block {
    &__list {
      grid-row-gap: calc((32 / 1440) * 100vw);
    }

    &__small-title {
      margin-bottom: calc((20 / 1440) * 100vw);
    }

    &__socials {
      margin-top: calc((20 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .info-block {
    &__small-title {
      margin-bottom: 10px;
    }

    &__item {
      grid-column: 1/5;
    }

    &__socials {
      margin-top: 10px;
    }
  }
}

@include respond-up('s-medium') {
  .info-block {

  }
}

@include respond-between('s-medium', 'medium') {
  .info-block {
    &__list {
      grid-row-gap: 50px;
    }

    &__small-title {
      margin-bottom: 10px;
    }
  }
}

@include respond-down('small') {
  .info-block {
    &__list {
      grid-row-gap: 30px;
    }

    &__small-title {
      margin-bottom: 5px;
    }

    &__item {
      grid-column: 1/3;
    }
  }
}

@include respond('x-small') {
  .info-block {
    &__list {
      grid-row-gap: calc((30 / 374) * 100vw);
    }

    &__small-title {
      margin-bottom: calc((10 / 374) * 100vw);
    }

    &__socials {
      margin-top: calc((10 / 1440) * 100vw);
    }
  }
}