:root {
  //--default-input-height: 37px;
  //--errors-color: var(--alert-color, #b50000)
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  --textarea-label-translate: calc(var(--label-translate) * -1);

    &.reverse {
      #{$inputs}, textarea, select {
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px var(--opposite-background-color) inset;
          -webkit-text-fill-color: var(--opposite-text-color);
          transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
          transition-timing-function: var(--default-timing-function);
        }
      }

      label {
        ::selection,
        &::selection{
          background-color: var(--opposite-text-color);
          color: var(--opposite-background-color);
        }
      }

      ul.errors {
        color: var(--opposite-text-color);
      }

      .form-field {
        #{$inputs}, textarea, select {
          color: var(--opposite-text-color);
        }

        #{$inputs} {
          border-bottom: 2px solid var(--opposite-text-color);
        }

        textarea {
          border-bottom: 2px solid var(--opposite-text-color);
        }

        &._filled {
          #{$inputs} {
            + label {
              color: var(--opposite-translucency-color);
            }
          }

          textarea + label {
            color: var(--opposite-translucency-color);
          }
        }
      }

      .form-file-field {
        &__button {
          &::after {
            border: 1px solid var(--opposite-text-color);
          }
        }
      }

      #{$inputs} {
        &:focus + label {
          color: var(--opposite-translucency-color);
        }
      }

      textarea:focus + label {
        color: var(--opposite-translucency-color);
      }
    }

  #{$inputs}, textarea, select {
    @extend .text__medium-title;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset;
      -webkit-text-fill-color: var(--text-color);
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-timing-function);
    }
  }

  textarea {
    resize: none;
  }

  label {
    @extend %text-item;
    transition: color .4s, transform .4s !important;
    transition-timing-function: var(--default-timing-function) !important;

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  ul.errors {
    position: relative;
    display: flex;
    color: var(--text-color);
    margin-top: 10px;
    transition: color .4s var(--default-timing-function);
    @extend %text-main;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      width: 24px;
      height: 24px;
      background-image: var(--error-svg-path);
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 100%;
      transition: background-image .4s var(--default-timing-function);
    }

    li {
      display: flex;
      align-items: center;
      margin-left: 34px;
    }
  }

  .form-field {
    display: flex;
    position: relative;
    //border-bottom: 2px solid var(--border-color);
    border-radius: 0;
    background-color: var(--t);
    transition: background-color .4s, border-color .4s;
    transition-timing-function: var(--default-timing-function);

    label {
      display: block;
      position: absolute;
      left: 0;
    }

    #{$inputs} {
      border-bottom: 2px solid var(--border-color);
      transition: border-color .4s var(--default-timing-function);

      + label {
        bottom: var(--label-bottom);
      }
    }

    textarea {
      border-bottom: 2px solid var(--border-color);
      transition: border-color .4s var(--default-timing-function);

      + label {
        top: 0;
        transform: translate3d(0, var(--textarea-label-translate), 0);
      }
    }

    &._filled {
      #{$inputs} {
        + label {
          color: var(--translucency-color);
          transform: translate3d(0, var(--label-translate), 0);
        }
      }

      textarea + label {
        color: var(--translucency-color);
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .form-file-field {
    display: inline-flex;
    align-items: center;
    position: relative;

    input[type='file'] {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      ~ input[type='checkbox'] {
        display: none;

        + label {
          display: none;
        }
      }
    }

    &__button {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border: 1px solid var(--border-color);
        transform: scaleX(1);
        transition: transform .4s, border-color .4s;
        transition-timing-function: var(--default-timing-function);
        transform-origin: left;
      }
    }
  }

  #{$inputs} {
    &:focus + label {
      color: var(--translucency-color);
      transform: translate3d(0, var(--label-translate), 0);
    }
  }

  textarea:focus + label {
    color: var(--translucency-color);
    transform: translate3d(0, 0, 0);
  }

  #{$inputs}, textarea, select {
    background-color: var(--t);
    border: unset;
    margin: 0;
    padding: var(--input-padding);
    width: 100%;
    display: block;
    appearance: none;

    &::-webkit-input-placeholder {
      opacity: 0;
      pointer-events: none;
    }

    &:invalid ~ svg {
      opacity: 1;
    }
  }

  //#{$inputs}, select {
    //height: var(--default-input-height);
  //}
}

@include respond-up('x-large') {
  form {
    --input-padding: calc((35 / 1920) * 100vw) 0 calc((20 / 1920) * 100vw);
    --label-bottom: calc((20 / 1920) * 100vw);
    --label-translate: calc((58 / 1920) * 100vw * -1);
    --errors-svg-right: calc((19 / 1920) * 100vw);
    --text-area-min-height: calc((238 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  form {
    --input-padding: 32px 0 20px;
    --label-bottom: 20px;
    --label-translate: -58px;
    --errors-svg-right: 19px;
    --text-area-min-height: 238px;
  }
}

@include respond-up('xs-large') {
  form {
    .form-file-field {
      &:hover {
        .form-file-field__button {
          &::after {
            transform-origin: right;
            transform: scaleX(0);
          }
        }
      }
    }
  }
}

@include respond('xs-large') {
  form {
    --input-padding: calc((32 / 1440) * 100vw) 0 calc((20 / 1440) * 100vw);
    --label-bottom: calc((20 / 1440) * 100vw);
    --label-translate: calc((58 / 1440) * 100vw * -1);
    --errors-svg-right: calc((19 / 1440) * 100vw);
    --text-area-min-height: calc((238 / 1440) * 100vw);
  }
}

@include respond-down('medium') {
  form {
    --label-translate: -45px;
  }
}

@include respond('small') {
  form {
    --input-padding: 27px 0 10px;
    --label-bottom: 10px;
    --label-translate: -33px;
    --errors-svg-right: 9px;
    --text-area-min-height: 178px;
  }
}

@include respond('x-small') {
  form {
    --input-padding: calc((27 / 374) * 100vw) 0 calc((10 / 375) * 100vw);
    --label-bottom: calc((10 / 374) * 100vw);
    --label-translate: calc((33 / 374) * 100vw * -1);
    --errors-svg-right: calc((9 / 374) * 100vw);
    --text-area-min-height: calc((178 / 374) * 100vw);
  }
}