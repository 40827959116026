.article {
  //article page

  &__share {
    height: max-content;
  }

  &__body-wrapper {
    position: relative;
  }

  &__case-subcase-name {
    padding-right: var(--grid-gap);
  }

  &__case-case {
    color: var(--light-color-40);
  }

  &__case-name {
    color: var(--text-color);
  }

  &__case-wrapper {
    border: 1px solid var(--text-color);
    width: 100%;
    display: flex;

    &:before {
      width: 0;
    }
  }

  &__case-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--grid-gap);
  }

  &__case-subcase {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__image_title {
    color: var(--light-color-40);
  }

  &__picture {
    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__picture,
  &__body-picture {
    display: flex;
    width: 100%;
    height: auto;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__date {
    display: flex;
    flex-direction: row;
  }

  &__link-description {
    color: var(--light-color-40);
  }

  &__body {
    color: var(--text-color);
  }
}

@include respond-up("x-large") {
  .article {
    &__icon {
      --icon-width: calc((25 / 1920) * 100vw);
      --icon-height: calc((25 / 1920) * 100vw);
    }

    &__image_title {
      margin-top: calc((20 / 1920) * 100vw);
    }

    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: calc((100 / 1920) * 100vw);
    }

    &__month-year {
      padding-top: calc((18 / 1920) * 100vw);
      padding-left: calc((20 / 1920) * 100vw);
    }

    &__link-block {
      margin-top: calc((30 / 1920) * 100vw);

      &:first-child {
        margin-top: calc((47 / 1920) * 100vw);
      }
    }
  }
}

@include respond('large') {
  .article {
    &__icon {
      --icon-width: 25px;
      --icon-height: 25px;
      padding-bottom: 5px;
    }

    &__image_title {
      margin-top: 20px;
    }

    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: 100px;
    }

    &__month-year {
      padding-top: 14px;
      padding-left: 20px;
    }

    &__link-block {
      margin-top: 30px;

      &:first-child {
        margin-top: 47px;
      }
    }
  }
}

@include respond('s-large') {
  .article {
    &__icon {
      --icon-width: 25px;
      --icon-height: 25px;
      padding-bottom: 5px;
    }

    &__image_title {
      margin-top: 20px;
    }

    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: 100px;
    }

    &__month-year {
      padding-top: 14px;
      padding-left: 20px;
    }

    &__links {
      display: flex;
      flex-direction: column;
    }

    &__link-block {
      margin-top: 30px;

      &:first-child {
        margin-top: 47px;
      }
    }
  }
}

@include respond-up("xs-large") {
  .article {
    &__share {
      position: sticky;
      top: var(--header-height);
    }

    &__case-picture {
      flex: 0 0 var(--grid-column1)
    }

    &__case-wrapper {
      height: calc(350 / 442 * var(--grid-column1));
    }

    &__picture {
      height: calc((656 / 1378) * var(--grid-column3));
    }

    &__share-wrapper {
      grid-column: span 1;
    }

    &__body {
      grid-column: 2/4;
    }

    &__title,
    &__picture-wrapper {
      grid-column: span 3;
    }
  }
}

@include respond('xs-large') {
  .article {
    &__icon {
      --icon-width: calc((25 / 1440) * 100vw);
      --icon-height: calc((25 / 1440) * 100vw);
    }

    &__image_title {
      margin-top: calc((20 / 1440) * 100vw);
    }

    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: calc((100 / 1440) * 100vw);
    }

    &__month-year {
      padding-top: calc((14 / 1440) * 100vw);
      padding-left: calc((20 / 1440) * 100vw);
    }

    &__link-block {
      margin-top: calc((30 / 1440) * 100vw);

      &:first-child {
        margin-top: calc((47 / 1440) * 100vw);
      }
    }
  }
}

@include respond("medium") {
  .article {
    &__icon {
      --icon-width: 25px;
      --icon-height: 25px;
      padding-bottom: 5px;
    }

    &__case-wrapper {
      height: calc(306 / 387 * var(--grid-column2));
    }

    &__image_title {
      margin-top: calc((20 / 1024) * 100vw);
    }

    &__body {
      grid-column: span 4;
    }

    &__share-wrapper,
    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: calc((80 / 1024) * 100vw);
    }

    &__picture {
      height: calc((376 / 794) * var(--grid-column4));
    }

    &__month-year {
      padding-top: calc((12 / 1024) * 100vw);
      padding-left: calc((20 / 1024) * 100vw);
    }

    &__link-block {
      &:nth-child(2) {
        grid-column: span 2;
      }
    }
  }
}

@include respond-down("medium") {
  .article {
    &__share-wrapper {
      order: 2;
    }

    &__body {
      order: 1;
    }

    &__case-picture {
      flex: 0 0 var(--grid-column2)
    }

    &__links {
      display: grid;
      grid-template-columns: repeat(var(--grid-columns-number), var(--grid-column));
      grid-column-gap: var(--grid-gap);
      grid-row-gap: calc(2 * var(--grid-gap));
      order: 2;
    }

    &__share-wrapper,
    &__date {
      grid-column: span 2;
    }

    &__picture-wrapper {
      order: 1;
    }

    &__title,
    &__picture-wrapper,
    &__links {
      grid-column: span 4;
    }
  }
}

@include respond("s-medium") {
  .article {
    &__icon {
      --icon-width: 25px;
      --icon-height: 25px;
    }

    &__case-wrapper {
      height: calc(306 / 387 * var(--grid-column2));
    }

    &__image_title {
      margin-top: calc((20 / 840) * 100vw);
    }

    &__body {
      grid-column: span 4;
    }

    &__share-wrapper,
    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: calc((80 / 840) * 100vw);
    }

    &__picture {
      height: calc((376 / 794) * var(--grid-column4));
    }

    &__month-year {
      padding-top: calc((10 / 840) * 100vw);
      padding-left: calc((20 / 840) * 100vw);
    }

    &__link-block {
      &:nth-child(2) {
        grid-column: span 2;
      }
    }
  }
}

@include respond-down("s-medium") {
  .article {
    &__icon {
      padding-bottom: 3px;
    }
  }
}

@include respond("small") {
  .article {
    &__icon {
      --icon-width: 17px;
      --icon-height: 17px;
    }

    &__case-subcase {
      margin-top: 30px;
    }

    &__month-year {
      padding-left: 10px;
    }

    &__image_title {
      margin-top: 10px;
    }

    &__share-wrapper,
    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: 50px;
    }
  }
}

@include respond-down("small") {
  .article {
    &__icon {
      --icon-width: calc((17 / 375) * 100vw);
      --icon-height: calc((17 / 375) * 100vw);
    }

    &__case-wrapper {
      flex-direction: column;
    }

    &__body {
      grid-column: span 2;
    }

    &__picture {
      height: calc((168 / 354) * var(--grid-column2));
    }

    &__month-year {
      padding-top: calc((2 / 560) * 100vw);
    }

    &__link-block {
      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(3) {
        order: 2;
      }
    }

    &__title,
    &__picture-wrapper,
    &__links {
      grid-column: span 2;
    }
  }
}

@include respond-down("x-small") {
  .article {
    &__case-subcase {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__share-wrapper,
    &__case-wrapper,
    &__body-wrapper,
    &__body-picture {
      margin-top: calc((50 / 374) * 100vw);
    }

    &__month-year {
      padding-left: calc((10 / 374) * 100vw);
    }

    &__image_title {
      margin-top: calc((10 / 374) * 100vw);
    }
  }
}