.rewards {
  &__list-top {
    display: grid;
    grid-column-gap: var(--grid-gap);
  }

  &__list {

  }

  &__item-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include respond-up('x-large') {
  .rewards {
    &__list-top {
      grid-row-gap: calc((100 / 1920) * 100vw);
      margin-top: calc((100 / 1920) * 100vw);
    }

    &__list {
      margin-top: calc((100 / 1920) * 100vw);
      grid-row-gap: calc((170 / 1920) * 100vw);
    }

    &__item-list {
      grid-row-gap: calc((100 / 1920) * 100vw);
    }
  }
}

@include respond('large') {
  .rewards {
    &__list-top {
      grid-row-gap: 100px;
      margin-top: 100px;
    }

    &__list {
      //margin-top: 22px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .rewards {
    &__list {
      margin-top: 100px;
      grid-row-gap: 170px;
    }

    &__item-list {
      grid-row-gap: 100px;
    }
  }
}

@include respond('s-large') {
  .rewards {
    &__list-top {
      grid-row-gap: 100px;
      margin-top: 100px;
    }

    &__list {
      //margin-top: 22px;
    }
  }
}

@include respond-up('xs-large') {
  .rewards {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__list-top {
      grid-column: 2/5;
      grid-template-columns: repeat(3, var(--grid-column1));
      grid-column-gap: var(--grid-gap);
    }

    &__item {
      &_runet {
        grid-template-columns: repeat(2, 1fr);
        grid-column: 1/3;
      }
    }
  }
}

@include respond('xs-large') {
  .rewards {
    &__list-top {
      grid-row-gap: calc((100 / 1440) * 100vw);
      margin-top: calc((100 / 1440) * 100vw);
    }

    &__list {
      margin-top: calc((100 / 1440) * 100vw);
      grid-row-gap: calc((170 / 1440) * 100vw);
    }

    &__item-list {
      grid-row-gap: calc((100 / 1440) * 100vw);
    }
  }
}

@include respond('medium') {
  .rewards {
    &__list-top {
      grid-template-columns: repeat(4, var(--grid-column1));
      grid-column: span 4;
      margin-top: 80px;
      grid-row-gap: 60px;
    }
  }
}

@include respond('s-medium') {
  .rewards {
    &__list-top {
      grid-template-columns: repeat(4, var(--grid-column1));
      grid-column: span 4;
      margin-top: 80px;
      grid-row-gap: 60px;
    }
  }
}

@include respond-down('medium') {
  .rewards {
    &__list {
      grid-template-columns: 1fr;
      margin-top: 70px;
      grid-row-gap: 80px;
    }

    &__item-list {
      grid-row-gap: 60px;
    }

    &__item {
      &_runet {
        grid-template-columns: 1fr;
        flex-direction: column;
        grid-row-gap: 40px;
      }
    }
  }
}

@include respond('small') {
  .rewards {
    &__list-top {
      grid-row-gap: 20px;
    }

    &__list {
      margin-top: 30px;
      grid-row-gap: 40px;
    }

    &__item-list {
      grid-row-gap: 30px;
    }

    &__item {
      &_runet {
        grid-row-gap: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .rewards {
    &__list-top {
      grid-template-columns: repeat(2, var(--grid-column1));
    }

    &__item-list {
      grid-template-columns: 1fr;
    }
  }
}

@include respond-down('x-small') {
  .rewards {
    &__list-top {
      grid-row-gap: calc((20 / 375) * 100vw);
    }

    &__list {
      margin-top: calc((30 / 374) * 100vw);
      grid-row-gap: calc((40 / 374) * 100vw);
    }

    &__item-list {
      grid-row-gap: calc((30 / 374) * 100vw);
    }

    &__item {
      &_runet {
        grid-row-gap: calc((20 / 374) * 100vw);
      }
    }
  }
}
