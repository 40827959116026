.overlined-heading {
  border-top: 2px solid var(--border-color);
  border-left: 2px solid var(--border-color);
  transition: border-color .4s var(--default-timing-function);
}

@include respond-up('x-large') {
  .overlined-heading {
    padding: calc((20 / 1920) * 100vw) 0 0 calc((30 / 1920) * 100vw);
  }
}

@include respond-between('s-large', 'large') {
  .overlined-heading {
    padding: 20px 0 0 30px;
  }
}

@include respond('xs-large') {
  .overlined-heading {
    padding: calc((20 / 1440) * 100vw) 0 0 calc((30 / 1440) * 100vw);
  }
}

@include respond-up('medium') {
  .overlined-heading {

  }
}

@include respond('medium') {
  .overlined-heading {

  }
}

@include respond-down('medium') {
  .overlined-heading {
    padding: 15px 0 0 14px;
  }
}

@include respond('small') {
  .overlined-heading {
    padding: 8px 0 0 11px;
  }
}

@include respond('x-small') {
  .overlined-heading {
    padding: calc((8 / 374) * 100vw) 0 0 calc((11 / 374) * 100vw);
  }
}