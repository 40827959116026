.agency-numbers {
  &__item {
    &:nth-child(1) {
      grid-column: 1/3;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__circle-wrap {
    height: 100%;
    width: 100%;
  }

  &__circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--border-color);
    border-radius: 100%;
    height: 100%;
    width: 100%;
    transition: transform, border-color;
    transition-duration: .3s;
    transition-timing-function: var(--default-timing-function);
  }

  &__circle-subtitle {
    text-align: center;
  }
}

@include respond-up('x-large') {
  .agency-numbers {
    &__circle-subtitle {
      margin-top: calc((15 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .agency-numbers {
    &__circle-subtitle {
      margin-top: 15px;
    }
  }
}

@include respond-up('xs-large') {
  .agency-numbers {
    &__circle-wrap {
      transition: width, height;
      transition-duration: 0.3s;
      transition-timing-function: var(--default-bezier);
    }

    &__item-circle {
      &:hover {
        .agency-numbers {
          &__circle-wrap {
            width: 93%;
            height: 93%;
          }
        }
      }
    }

    &__item {
      &:nth-child(4) {
        grid-column: 2/4;
      }
    }
  }
}

@include respond('xs-large') {
  .agency-numbers {
    &__circle-subtitle {
      margin-top: calc((15 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .agency-numbers {
    &__circle {
      width: var(--grid-column2);
      height: var(--grid-column2);
    }
  }
}

@include respond-down('medium') {
  .agency-numbers {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond-down('small') {
  .agency-numbers {
    &__circle-subtitle {
      margin-top: 5px;
    }
  }
}

@include respond-down('small') {
  .agency-numbers {
    &__circle-subtitle {
      margin-top: calc((5 / 374) * 100vw);
    }
  }
}