.services-page-list {
  &__item {
    display: flex;
    flex-direction: column;

    &.need-animation {
      .services-page-list {
        &__item-title {
          transform: translate3d(-50%, 0, 0);
          opacity: 0;
        }
      }

      .service-items {
        &__item,
        &__link {
          opacity: 0;
        }
      }
    }

    &:first-child {
      .services-page-list {
        &__item-title {
          transition-delay: .5s;
        }
      }

      .service-items {
        &__item {
          @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
              .service-items__link {
                transition-delay: calc(.5s + #{$i} * .05s);
              }
            }
          }
        }
      }
    }
  }

  &__item-title {
    transition: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: var(--default-timing-function);
  }
}

@include respond-up('x-large') {
  .services-page-list {
    &__item:not(:first-child) {
      padding-top: calc((170 / 1920) * 100vw);
    }

    &__item:first-child {
      padding-top: calc((130 / 1920) * 100vw);
    }

    &__service-items {
      margin-top: calc((70 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .services-page-list {
    &__item:not(:first-child) {
      padding-top: 170px;
    }

    &__item:first-child {
      padding-top: 130px;
    }

    &__service-items {
      margin-top: 70px;
    }
  }
}

@include respond('xs-large') {
  .services-page-list {
    &__item:not(:first-child) {
      padding-top: calc((170 / 1440) * 100vw);
    }

    &__item:first-child {
      padding-top: calc((130 / 1440) * 100vw);
    }

    &__service-items {
      margin-top: calc((70 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .services-page-list {

  }
}

@include respond('medium') {
  .services-page-list {

  }
}

@include respond-down('medium') {
  .services-page-list {
    &__item {
      padding-top: 100px;
    }

    &__service-items {
      margin-top: 40px;
    }
  }
}

@include respond('small') {
  .services-page-list {
    &__item:not(:first-child) {
      padding-top: 50px;
    }

    &__item:first-child {
      padding-top: 36px;
    }

    &__service-items {
      margin-top: 20px;
    }
  }
}

@include respond('x-small') {
  .services-page-list {
    &__item:not(:first-child) {
      padding-top: calc((50 / 374) * 100vw);
    }

    &__item:first-child {
      padding-top: calc((36 / 374) * 100vw);
    }

    &__service-items {
      margin-top: calc((20 / 374) * 100vw);
    }
  }
}