.agency-video-block {
  &__main {

  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__video-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@include respond-up('x-large') {
  .agency-video-block {
    &__text-wrap {
      padding-top: calc((100 / 1920) * 100vw);
    }

    &__video-wrapper {
      margin-top: calc((22 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .agency-video-block {
    &__video-wrapper {
      margin-top: 22px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .agency-video-block {
    &__text-wrap {
      padding-top: 100px;
    }
  }
}

@include respond-up('xs-large') {
  .agency-video-block {
    &__text-wrap {
      justify-content: space-between;
      grid-column: 1/3;
    }

    &__video-wrapper {
      grid-column: 3/5;
      height: calc((701 / 1920) * 100vw);
      margin-top: -15px;
    }
  }
}

@include respond('xs-large') {
  .agency-video-block {
    &__text-wrap {
      padding-top: calc((100 / 1440) * 100vw);
    }

    &__video-wrapper {
      margin-top: calc((22 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .agency-video-block {

  }
}

@include respond('medium') {
  .agency-video-block {

  }
}

@include respond-down('medium') {
  .agency-video-block {
    &__text-wrap {
      grid-column: 1/5;
      grid-row: 2/3;
      margin-top: 20px;
    }

    &__description {
      margin-top: 70px;
    }

    &__video-wrapper {
      grid-column: 1/5;
      grid-row: 1/2;
      height: calc((528 / 834) * 100vw);
      margin-top: 70px;
    }
  }
}

@include respond('small') {
  .agency-video-block {
    &__description {
      margin-top: 30px;
    }

    &__video-wrapper {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .agency-video-block {
    &__text-wrap {
      grid-column: 1/3;
      margin-top: 10px;
    }

    &__video-wrapper {
      grid-column: 1/3;
      height: calc((236 / 375) * 100vw);
    }
  }
}

@include respond('x-small') {
  .agency-video-block {
    &__text-wrap {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__description {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__video-wrapper {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}