.parallax {
  &__image-wrap-inner,
  &__parallax-container,
  &__image-wrapper {
    display: block;
  }

  &__image-wrap {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }

  &__image-wrap-inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transition: height 1.2s var(--default-timing-function);
  }

  &__parallax-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  &__picture {
    height: calc(var(--grid-column4) * (1495 / 2580));

    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__picture,
  &__image {
    transition: transform 1.2s var(--default-timing-function);
  }

  &__image-wrap,
  &__parallax-container {
    height: calc(var(--grid-column4) * (1395 / 2480));
  }
}

@include respond-up('large') {
  .parallax {

  }
}

@include respond-up('medium') {
  .parallax {

  }
}

@include respond('medium') {
  .parallax {

  }
}

@include respond-between('s-medium', 'medium') {
  .parallax {
    &__picture {
      height: calc(var(--grid-column4) * (1695 / 2780));
    }
  }
}

@include respond-down('medium') {
  .parallax {

  }
}

@include respond-down('small') {
  .parallax {
    &__picture {
      height: calc(var(--grid-column2) * (1795 / 2880));
    }

    &__image-wrap,
    &__parallax-container {
      height: calc(var(--grid-column2) * 1395 / 2480);
    }
  }
}