.contacts-page {

}

@include respond-up('x-large') {
  .contacts-page {
    padding-bottom: calc((200 / 1920) * 100vw);

    &__info-block {
      margin-top: calc((200 / 1920) * 100vw);
    }

    &__map-block {
      margin-top: calc((28 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .contacts-page {
    padding-bottom: 200px;

    &__info-block {
      margin-top: 200px;
    }

    &__map-block {
      margin-top: 28px;
    }
  }
}

@include respond-up('xs-large') {
  .contacts-page {

  }
}

@include respond('xs-large') {
  .contacts-page {
    padding-bottom: calc((200 / 1440) * 100vw);

    &__info-block {
      margin-top: calc((200 / 1440) * 100vw);
    }

    &__map-block {
      margin-top: calc((28 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium','medium') {
  .contacts-page {
    padding-bottom: 30px;

    &__info-block {
      margin-top: 120px;
    }

    &__map-block {
      margin-top: 50px;
    }
  }
}

@include respond-down('small') {
  .contacts-page {
    padding-bottom: 20px;

    &__info-block {
      margin-top: 60px;
    }

    &__map-block {
      margin-top: 30px;
    }
  }
}

@include respond('x-small') {
  .contacts-page {
    padding-bottom: calc((20 / 374) * 100vw);

    &__info-block {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__map-block {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}