.cases-page {
  &__item {
    border-bottom: 2px solid var(--border-color);
    position: relative;

    &:first-child {
      border-top: 2px solid var(--border-color);
    }
  }
}

@include respond-up('xs-large') {
  .cases-page {
    padding-bottom: 200px;
  }
}

@include respond-up('s-medium') {
  .cases-page {
    &__cases-list-wrap {
      margin-top: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .cases-page {

  }
}

@include respond-down('medium') {
  .cases-page {

  }
}

@include respond-between('s-medium', 'medium') {
  .cases-page {
    padding-bottom: 120px;
  }
}

@include respond-down('small') {
  .cases-page {
    padding-bottom: 60px;

    &__cases-list-wrap {
      margin-top: calc(var(--grid-gap) * 2);
    }
  }
}