html {
  ::-webkit-scrollbar {
    width: 0;
  }
}

.layout {
  display: flex;

  //палитра для черного фона
  --filter-color: grayscale(100%) invert(100%);
  --background-color: var(--primary-color);
  --text-color: var(--light-color);
  --translucency-color: var(--light-color-40);
  --border-color: var(--light-color);
  --logo-path: url('../images/base/logo/light/logo.png');
  --opposite-background-color: var(--light-color);
  --opposite-text-color: var(--primary-color);
  --opposite-translucency-color: var(--primary-color-40);
  --error-svg-path: url('../images/svg/icons/input-error-icon-light.svg');

  //палитра для белого фона
  &._light {
    --background-color: var(--light-color);
    --filter-color: grayscale(100%);
    --text-color: var(--primary-color);
    --translucency-color: var(--primary-color-40);
    --border-color: var(--primary-color);
    --logo-path: url('../images/base/logo/dark/logo.png');
    --opposite-background-color: var(--primary-color);
    --opposite-text-color: var(--light-color);
    --opposite-translucency-color: var(--light-color-40);
    --error-svg-path: url('../images/svg/icons/input-error-icon-dark.svg');

    .ya-share2 {
      &__icon {
        filter: brightness(0);
      }
    }
  }

  //svg {
  //  path {
  //    transition: stroke .4s;
  //    transition-timing-function: var(--default-timing-function);
  //    stroke: var(--text-color);
  //  }
  //}

  &::after {
    content: '';
    position: absolute;
    z-index: 1600;
    left: var(--grid-spacer);
    right: var(--grid-spacer);
    bottom: var(--grid-spacer);
    transform: scaleX(0);
    border-bottom: 2px solid var(--border-color);
    will-change: transform;
  }

  &__header {
    position: fixed;
    left: 0;
    right: 8px;
    top: 0;
    height: var(--header-height);
    z-index: 1600;
  }

  &__wrapper {
    overflow: auto;
    width: 100vw;
    //height: calc(var(--vh, 1vh) * 100);
    z-index: 1500;
  }

  &__base {
    display: flex;
    flex-direction: column;
    position: relative;
    //background-color: var(--background-color);
    background-color: var(--t);
    transition: background-color .4s var(--default-timing-function);
    padding-top: var(--header-height);
    min-height: calc(var(--vh, 1vh) * 100);
    z-index: 300;
  }

  &__transition-bg {
    position: fixed;
    z-index: 1600;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--background-color);
    transition: opacity .3s var(--default-timing-function);
    opacity: 0;
    pointer-events: none;
  }

  &__main {
    opacity: 1;
    transition: opacity .7s var(--default-timing-function) .6s;
  }

  &.need-animation {
    .content-header {
      &__title-text,
      &__description,
      &__link,
      &__month-year {
        opacity: 0;
      }

      &__day {
        transform: translate3d(0, 100%, 0);
      }
    }

    .layout__main,
    .footer {
      opacity: 0;
    }
  }

  &._animation-out {
    .layout__transition-bg {
      opacity: 1;
    }
  }

  .content-header {
    &__title-text,
    &__title-text-inner {

      ::selection,
      &::selection {
        background-color: var(--text-color);
        color: var(--background-color);
      }
    }
  }
}

@include respond-up('x-large') {
  .layout {
    --header-height: calc((70 / 1920) * 100vw);

    --logo-svg-width: calc((182 / 1920) * 100vw);
    --logo-svg-height: calc((28 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  .layout {
    --header-height: 70px;

    --logo-svg-width: 181px;
    --logo-svg-height: 28px;
  }
}

@include respond('xs-large') {
  .layout {
    --header-height: calc((70 / 1440) * 100vw);

    --logo-svg-width: calc((182 / 1440) * 100vw);
    --logo-svg-height: calc((28 / 1440) * 100vw);
  }
}

@include respond-up('xs-large') {
  .layout {
    &__wrapper {
      height: calc(var(--vh, 1vh) * 100);
    }

    &.need-animation {
      .content-header {
        &__title-text {
          &:nth-child(1) {
            transform: translate3d(-50%, 0, 0);
          }

          &:nth-child(2) {
            transform: translate3d(50%, 0, 0);
          }
        }
      }
    }

    &_first-visit {
      #main {
        transition: opacity .4s var(--default-timing-function);
        transition-delay: 1.5s;
      }

      &::after {
        -webkit-animation: stripe 1.7s var(--custom-ease-in-ease-out);
        animation: stripe 1.7s var(--custom-ease-in-ease-out);
      }

      .header {
        &__nav-menu,
        &__info {
          transition-delay: 1.5s;
        }
      }

      .content-header {
        &__title-text {
          @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
              transition-delay: calc(1.4s + #{$i} * .2s);
            }
          }
        }

        &__link,
        &__description {
          transition-delay: 0s, 1.9s;
        }

        &__day,
        &__month-year {
          transition-delay: 1.9s;
        }
      }

      .article-page-content-header__title-inner {
        @for $i from 1 through 15 {
          &:nth-child(#{$i}) {
            span {
              transition-delay: calc(1.9s + #{$i} * .05s);
            }
          }
        }
      }

      .services-page-list {
        &__item:first-child {
          .services-page-list {
            &__item-title {
              transition-delay: 1.4s;
            }
          }

          .service-items {
            transition-delay: 1.9s;

            &__item {
              transition-delay: 1.9s;

              @for $i from 1 through 15 {
                &:nth-child(#{$i}) {
                  .service-items__link {
                    transition-delay: calc(1.9s + #{$i} * .05s);
                  }
                }
              }
            }
          }
        }
      }

      &.need-animation {
        .switcher_desktop,
        #main {
          opacity: 0;
        }

        .logo-symbol svg {
          transform: translate3d(0, 100%, 0);
        }

        .header {
          &__logo {
            height: calc((330 / 1920) * 100vw);
            width: calc(100vw - var(--grid-spacer) * 2);
          }

          &__nav-menu,
          &__info {
            opacity: 0;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .layout {
    &.need-animation {
      .content-header {
        &__title-text {
          &:nth-child(1),
          &:nth-child(2) {
            transform: translate3d(-50%, 0, 0);
          }

          &:nth-child(3) {
            transform: translate3d(50%, 0, 0);
          }
        }
      }
    }
  }
}

@include respond('small') {
  .layout {
    --header-height: 44px;

    --logo-svg-width: 120px;
    --logo-svg-height: 18px;
  }
}

@include respond('x-small') {
  .layout {
    --header-height: calc((44 / 375) * 100vw);

    --logo-svg-width: calc((120 / 375) * 100vw);
    --logo-svg-height: calc((18 / 375) * 100vw);
  }
}

@keyframes stripe {
  from {
    transform: scaleX(0);
    transform-origin: center left;
  }
  25% {
    transform: scaleX(1);
    transform-origin: center left;
  }
  75% {
    transform: scaleX(1);
    transform-origin: center right;
  }
  to {
    transform: scaleX(0);
    transform-origin: center right;
  }
}