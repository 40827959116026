.services-content-header {
  &__title-text-inner {
    grid-column: var(--column);
    grid-row: 1;
  }

  &__subtitle {
    grid-row: 2;
  }
}

@include respond-up('xs-large') {
  .services-content-header {
    &__title-text-inner {
      --column: 1/3;
    }

    &__subtitle {
      padding-top: 0 !important;
      grid-column: 2/5 !important;
    }
  }
}

@include respond-up('s-medium') {
  .services-content-header {
    &__title-text-inner {
      --column: 1/3;
    }

    &__subtitle {
      padding-top: 50px;
      grid-column: 1/5;
      text-indent: calc(var(--grid-column) + var(--grid-gap));
    }
  }
}

@include respond-down('small') {
  .services-content-header {
    &__subtitle {
      padding-top: 20px;
      grid-column: 1/3;
    }
  }
}