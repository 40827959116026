.agency-text-block {
  &__menu-link {
    width: fit-content;
  }

  &__menu-title {
    color: var(--translucency-color);
  }

  &__menu {
    display: flex;
  }
}

@include respond-up('x-large') {
  .agency-text-block {
    padding-top: calc((200 / 1920) * 100vw);
    grid-row-gap: calc((80 / 1920) * 100vw);

    &__menu {
      top: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond('large') {
  .agency-text-block {
    padding-top: 200px;
    grid-row-gap: 80px;

    &__menu {
      top: 30px;
    }
  }
}

@include respond('s-large') {
  .agency-text-block {
    padding-top: 200px;
    grid-row-gap: 80px;

    &__menu {
      top: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .agency-text-block {
    &__wrapper {
      position: relative;
    }

    &__menu {
      position: absolute;
      left: 0;
      flex-direction: column;
    }

    &__menu-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__first {
      grid-column: 2/5;
    }

    &__second {
      grid-column: 1/2;
    }

    &__third {
      grid-column: 3/4;
    }

    &__fourth {
      grid-column: 4/5;
    }
  }
}

@include respond('xs-large') {
  .agency-text-block {
    padding-top: calc((200 / 1440) * 100vw);
    grid-row-gap: calc((80 / 1440) * 100vw);

    &__menu {
      top: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .agency-text-block {
    padding-top: 120px;
    grid-row-gap: 50px;

    &__menu {
      margin-top: 20px;
      flex-direction: row;
    }

    &__menu-wrapper {
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column1));
      grid-column-gap: var(--grid-gap);

      :nth-child(1) {
        order: 1;
      }

      :nth-child(2) {
        order: 3;
      }
      :nth-child(3) {
        order: 2;
      }

      :nth-child(4) {
        order: 4;
      }
    }

    &__menu-title {
      width: var(--grid-column1);
    }

    &__first {
      grid-column: 1/5;
    }

    &__second {
      display: none;
    }

    &__third {
      grid-column: 1/3;
    }

    &__fourth {
      grid-column: 3/5;
    }
  }
}

@include respond-up('s-medium') {
  .agency-text-block {
    &__first {
      text-indent: calc(var(--grid-column1) + var(--grid-gap));
    }
  }
}

@include respond-down('small') {
  .agency-text-block {
    padding-top: 60px;
    grid-row-gap: 30px;

    &__menu {
      margin-top: 10px;
      flex-direction: column;
    }

    &__first,
    &__third,
    &__fourth {
      grid-column: 1/3;
    }
  }
}


@include respond('x-small') {
  .agency-text-block {
    &__menu {
      margin-top: calc((10 / 375) * 100vw);
    }

    padding-top: calc((60 / 375) * 100vw);
    grid-row-gap: calc((30 / 375) * 100vw);
  }
}