.index-reward {
  &__title{
    align-items: center;
    display: flex;
    border-bottom: 2px solid var(--border-color);

    &.animation-block__text-opacity-side {
      transition: opacity 1s var(--default-timing-function),
                  transform 1s var(--default-timing-function),
                  border-bottom-color .4s var(--default-timing-function);
    }
  }

  &__wrap-description{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__wrap-description{
    width: 100%;
  }

  &__icon {
    svg path {
      fill: var(--text-color);
      transition: fill .4s var(--default-timing-function);
    }
  }
}

@include respond-up('xs-large') {
  .index-reward {

    &_first{
      .index-reward__wrap-description {
        width: var(--grid-column);
        margin-left: calc(var(--grid-column1) + var(--grid-gap));
      }
    }

    &__icon{
      svg{
        width: 34px;
        height: 34px;
      }
    }

    &__title{
      display: flex;
      gap: 20px;
      padding-bottom: 30px;
    }

    &__wrap-description{
      margin-top: 50px;
    }

  }
}

@include respond-up('medium') {
  .index-reward {

  }
}

@include respond('medium') {
  .index-reward {

    &_first{
      .index-reward__wrap-description {
        width: var(--grid-column2);
        margin-left: calc(var(--grid-column2) + var(--grid-gap));
      }
    }


    &__icon{
      svg{
        width: 26px;
        height: 26px;
      }
    }

    &__title{
      gap: 15px;
      padding-bottom: 20px;
    }

    &__wrap-description{
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .index-reward {

  }
}

@include respond-down('small') {
  .index-reward {
    &__icon{
      svg{
        width: 20px;
        height: 20px;
      }
    }

    &__title{
      gap: 10px;
      padding-bottom: 10px;
    }

    &__wrap-description{
      margin-top: 30px;
    }
  }
}