.header-nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__item,
  &__link {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__link {
    &._active {
      &::before {
        transform: translate3d(0, -50%, 0) scaleX(1);
        transform-origin: left center;
      }
    }
  }
}

@include respond-up('x-large') {
  .header-nav-menu {
    margin: 0 calc((45 / 1920) * 100vw * -1);

    &__link {
      padding: 0 calc((15 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .header-nav-menu {
    margin: 0 -45px;

    &__link {
      padding: 0 15px;
    }
  }
}

@include respond('xs-large') {
  .header-nav-menu {
    margin: 0 calc((45 / 1440) * 100vw * -1);

    &__link {
      padding: 0 calc((15 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .header-nav-menu {

  }
}

@include respond('medium') {
  .header-nav-menu {

  }
}

@include respond-down('medium') {
  .header-nav-menu {

  }
}

@include respond-down('small') {
  .header-nav-menu {

  }
}