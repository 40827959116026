.index-recall-form {
  &_reverse-color {
    &.request-form {
      position: static;
    }
    .index-recall-form {

      &__policy {
        color: var(--opposite-translucency-color);

        .policy__link {
          color: var(--opposite-text-color);

          &::before {
            border-bottom-color: var(--opposite-text-color);
          }
        }
      }

      &__button {
        &.button {
          background: var(--background-color);
          border: 2px solid var(--border-color);
          width: 100%;
        }
      }

      &__field {
        label {
          color: var(--opposite-text-color);
        }
      }

      &__success {
        flex-direction: column;
        text-align: center;
      }

      &__success-title,
      &__success-subtitle {
        color: var(--opposite-text-color);
      }
    }
  }
}

@include respond-up('x-large') {
  .index-recall-form {
    &__action {
      margin-top: calc((36 / 1920) * 100vw);
    }

    &__policy-wrap {
      margin-top: calc((30 / 1920) * 100vw);
    }

    &__success {
      row-gap: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .index-recall-form {
    &__action {
      margin-top: 36px;
    }

    &__policy-wrap {
      margin-top: 30px;
    }

    &__success {
      row-gap: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .index-recall-form {
    &_reverse-color {
      .index-recall-form {
        &__button {
          &:hover {
            background: var(--text-color);
            border-color: var(--background-color);
            .button {
              &__text {
                color: var(--background-color);
              }
            }
          }
        }
      }
    }
  }
}

@include respond('xs-large') {
  .index-recall-form {
    &__action {
      margin-top: calc((36 / 1440) * 100vw);
    }

    &__policy-wrap {
      margin-top: calc((30 / 1440) * 100vw);
    }

    &__success {
      row-gap: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .index-recall-form {
    &__action {
      margin-top: 36px;
    }

    &__policy-wrap {
      margin-top: 30px;
    }

    &__success {
      row-gap: 30px;
    }
  }
}

@include respond-down('small') {
  .index-recall-form {
    &__action,
    &__policy-wrap {
      margin-top: 20px;
    }

    &__policy {
      &.text__main-mini {
        font-size: 15px;
      }
    }

    &__success {
      row-gap: 20px;
    }
  }
}

@include respond-down('x-small') {
  .index-recall-form {
    &__action,
    &__policy-wrap {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__policy {
      &.text__main-mini {
        font-size: calc((15 / 374) * 100vw);
      }
    }

    &__success {
      row-gap: calc((20 / 374) * 100vw);
    }
  }
}