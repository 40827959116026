.case-card {
  padding: var(--grid-gap) 0;
  overflow: hidden;

  &.need-animation {
    .case-card {
      &__arrow,
      &__subtitle {
        //opacity: 0;
      }

      &__title {
        //transform: translate3d(100%, 0, 0);
      }
    }
  }

  &.animate {
    .case-card {
      &__arrow,
      &__subtitle {
        //opacity: 1;
        //transition: opacity .7s var(--default-timing-function);
        //transition-delay: 0.3s;
      }

      &__title {
        //transform: translate3d(0, 0, 0);
        //transition: transform .7s var(--default-timing-function);
      }
    }
  }

  &__image-wrap {
    position: relative;
  }

  &__text-content {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-auto-rows: max-content;
  }

  &__arrow-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: stroke var(--default-transition-time) var(--default-timing-function);
        stroke: var(--text-color);
      }
    }
  }

  &__title {
    grid-area: title;
    overflow: hidden;
    display: block;
    transition: text-decoration-color .4s, color .4s;
    transition-timing-function: var(--default-timing-function);
  }

  &__subtitle-inner,
  &__title-inner {
    span {
      transition: transform .7s var(--default-timing-function);
    }

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc(.9s + #{$i} * .05s);
        }
      }
    }
  }

  &__subtitle {
    grid-area: subtitle;
    overflow: hidden;
    display: block;
    //transition: text-decoration-color .4s, color .4s;
    //transition-timing-function: var(--default-timing-function);
  }

  &__subtitle-inner {
    display: block;
    transition: text-decoration-color .4s, color .4s;
    transition-timing-function: var(--default-timing-function);
  }

  &__categories-list {
    grid-area: categories;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__bottom-links {
    grid-area: links;
  }
}

@include respond-up('x-large') {
  .case-card {
    &__subtitle {
      margin-top: calc((100 / 1920) * 100vw);
    }

    &__subtitle-inner {
      max-width: calc((var(--grid-column2) - 210px) / 1920 * 100vw);
    }

    &__subtitle-link {
      width: calc((var(--grid-column2) - 210px) / 1920 * 100vw);
    }

    &__categories-list {
      margin-top: calc((20 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .case-card {
    &__subtitle {
      margin-top: 100px;
    }

    &__subtitle-inner {
      max-width: calc(var(--grid-column2) - 210px);
    }

    &__subtitle-link {
      width: calc(var(--grid-column2) - 210px);
    }

    &__categories-list {
      margin-top: 20px;
    }
  }
}

@include respond-up('xs-large') {
  .case-card {
    &__title-inner {
      max-width: calc(var(--grid-column2) - var(--grid-gap));
    }

    &.index-page-case-card {
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column2));
      grid-column-gap: var(--grid-gap);
    }

    &__subtitle-inner {
      text-decoration: underline;
      text-decoration-color: var(--t);
      text-decoration-thickness: 2px;
      text-underline-offset: 7px;
    }

    &__text-content {
      display: flex;
      flex-direction: column;

      &:hover {
        .case-card {
          &__subtitle-inner {
            text-decoration-color: var(--border-color);
          }
        }
      }
    }

    &__arrow {
      width: 25px;
      height: 25px;
    }

    &__bottom-links {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: auto;
    }

    &__outer-link-wrap {
      &_top {
        display: none;
      }
    }
  }
}

@include respond('xs-large') {
  .case-card {
    &__subtitle {
      margin-top: calc((100 / 1440) * 100vw);
    }

    &__subtitle-inner {
      max-width: calc((var(--grid-column2) - 210px) / 1440 * 100vw);
    }

    &__subtitle-link {
      width: calc((var(--grid-column2) - 210px) / 1440 * 100vw);
    }

    &__categories-list {
      margin-top: calc((20 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .case-card {

  }
}

@include respond-between('s-medium', 'medium') {
  .case-card {
    &__title-inner {
      max-width: calc(var(--grid-column3) - var(--grid-gap));
    }

    &__text-content {
      grid-template-columns: var(--grid-column3) var(--grid-column1);
      grid-template-areas: 'title link'
                           'subtitle .'
                           'categories .'
                           'links links';
      margin-top: 20px;
    }

    &__bottom-links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__arrow {
      width: 25px;
      height: 25px;
    }

    &__outer-link-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      grid-area: link;

      &_bottom {
        display: none;
      }
    }

    &__subtitle {
      margin-top: 30px;
    }

    &__categories-list {
      margin-top: 15px;
    }

    &__arrow-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-down('medium') {
  .case-card {

  }
}

@include respond-down('small') {
  .case-card {
    &__title-inner {
      max-width: calc(var(--grid-column2) - var(--grid-gap));
    }

    &__text-content {
      grid-template-columns: repeat(2, var(--grid-column1));
      grid-template-areas: 'title title'
                           'subtitle subtitle'
                           'categories categories'
                           'links links';
      margin-top: 10px;
    }

    &__arrow {
      width: 17px;
      height: 17px;
    }

    &__subtitle,
    &__outer-link-wrap {
      margin-top: 20px;
    }

    &__categories-list {
      margin-top: 8px;
    }

    &__arrow-wrap {
      margin-top: 18px;
    }

    &__outer-link-wrap {
      &_top {
        display: none;
      }
    }
  }
}

@include respond-down('x-small') {
  .case-card {
    &__text-content {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__arrow {
      width: calc((17 / 374) * 100vw);
      height: calc((17 / 374) * 100vw);
    }

    &__subtitle,
    &__outer-link-wrap {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__categories-list {
      margin-top: calc((8 / 374) * 100vw);
    }

    &__arrow-wrap {
      margin-top: calc((18 / 374) * 100vw);
    }
  }
}