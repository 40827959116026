.certification {
  &__item {
    display: flex;
    width: var(--grid-column);
    height: var(--grid-column);
    //border-radius: 100%;
  }

  &__link-wrap {
    width: 100%;
    height: 100%;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid var(--border-color);
    border-radius: 100%;
    transition: transform, border-color;
    transition-duration: .3s;
    transition-timing-function: var(--default-bezier);

    &_empty {
      pointer-events: none;
    }
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__icon {
    display: flex;
    width: var(--icon-logo-width);
    height: var(--icon-logo-height);

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: var(--text-color);
        transition: fill .4s var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .certification {
    &__main {
      margin-top: calc((100 / 1920) * 100vw);
    }
  }
}

@include respond('large') {
  .certification {

  }
}

@include respond-between('s-large', 'large') {
  .certification {
    &__main {
      margin-top: 100px;
    }
  }
}

@include respond('s-large') {
  .certification {

  }
}

@include respond-up('xs-large') {
  .certification {
    &__title {
      grid-column: 1/2;
      margin-right: calc(0px - var(--grid-column1) - var(--grid-gap));
    }

    &__list {
      grid-column: 2/5;
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      &_yandex {
        --icon-logo-width: calc((168 / 1920) * 100vw);
        --icon-logo-height: calc((49 / 1920) * 100vw);
      }

      &_google {
        --icon-logo-width: calc((182 / 1920) * 100vw);
        --icon-logo-height: calc((61 / 1920) * 100vw);
      }

      &_vk {
        --icon-logo-width: calc((276 / 1920) * 100vw);
        --icon-logo-height: calc((48 / 1920) * 100vw);
      }

      &_my-target {
        --icon-logo-width: calc((253 / 1920) * 100vw);
        --icon-logo-height: calc((65 / 1920) * 100vw);
      }

      &_avito {
        --icon-logo-width: calc((214 / 1920) * 100vw);
        --icon-logo-height: calc((74 / 1920) * 100vw);
      }
    }

    &__title {
      position: relative;
      z-index: 10;
    }

    &__list {
      position: relative;
      z-index: 20;
    }

    &__item {
      align-content: center;
      align-items: center;
      justify-content: center;

      &:hover {
        .certification {
          &__link-wrap {
            width: 93%;
            height: 93%;
          }
        }
      }
    }

    &__link-wrap {
      transition: width, height;
      transition-duration: 0.3s;
      transition-timing-function: var(--default-bezier);
    }
  }
}

@include respond('xs-large') {
  .certification {
    &__main {
      margin-top: calc((100 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .certification {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .certification {
    &__main {
      margin-top: 70px;
      grid-row-gap: 70px;
    }

    &__item {
      width: var(--grid-column2);
      height: var(--grid-column2);

      &_yandex {
        --icon-logo-width: calc((147 / 834) * 100vw);
        --icon-logo-height: calc((42 / 834) * 100vw);
      }

      &_google {
        --icon-logo-width: calc((159 / 834) * 100vw);
        --icon-logo-height: calc((53 / 834) * 100vw);
      }

      &_vk {
        --icon-logo-width: calc((241 / 834) * 100vw);
        --icon-logo-height: calc((42 / 834) * 100vw);
      }

      &_my-target {
        --icon-logo-width: calc((221 / 834) * 100vw);
        --icon-logo-height: calc((57 / 834) * 100vw);
      }

      &_avito {
        --icon-logo-width: calc((187 / 834) * 100vw);
        --icon-logo-height: calc((65 / 834) * 100vw);
      }
    }

    &__title {
      grid-column: 1/3;
      margin-right: calc(0px - var(--grid-column1) - var(--grid-gap));
    }

    &__list {
      grid-column: 1/5;
    }
  }
}

@include respond-up('s-medium') {
  .certification {
    &__item {
      &:first-child {
        grid-column: 2/3;
      }
    }
  }
}

@include respond-down('small') {
  .certification {
    &__main {
      margin-top: 30px;
      grid-row-gap: 30px;
    }

    &__title,
    &__list {
      grid-column: 1/3;
    }

    &__item {
      &_yandex {
        --icon-logo-width: calc((65 / 374) * 100vw);
        --icon-logo-height: calc((19 / 374) * 100vw);
      }

      &_google {
        --icon-logo-width: calc((70 / 374) * 100vw);
        --icon-logo-height: calc((23 / 374) * 100vw);
      }

      &_vk {
        --icon-logo-width: calc((107 / 374) * 100vw);
        --icon-logo-height: calc((18 / 374) * 100vw);
      }

      &_my-target {
        --icon-logo-width: calc((98 / 374) * 100vw);
        --icon-logo-height: calc((25 / 374) * 100vw);
      }

      &_avito {
        --icon-logo-width: calc((83 / 374) * 100vw);
        --icon-logo-height: calc((29 / 374) * 100vw);
      }
    }
  }
}

@include respond('x-small') {
  .certification {
    &__main {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__list {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}
