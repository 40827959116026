.case-card-rewards {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  color: white;
  pointer-events: none;

  &__wrap-icon {
    position: static;
    pointer-events: all;

    &:hover {
      .case-card-rewards__wrap-description {
        opacity: 1;
      }
    }
  }

  &__wrap-description {
    right: 0;
    z-index: 50;
    border-radius: 15px;
    background: var(--primary-color);
    padding: 4px 10px 5px;
    position: absolute;
    opacity: 0;
    transition: opacity var(--default-transition-time) var(--default-timing-function);
    pointer-events: none;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.index-page-case-card {
  &.need-animation {
    .case-card-rewards {
      &__wrap-icon {
        opacity: 0;
      }
    }
  }
}

.index-page-case-card {
  &.animate {
    .case-card-rewards {
      &__wrap-icon {
        transition: opacity var(--default-transition-time) var(--default-timing-function);
        opacity: 1;
      }
    }
  }
}

@include respond-up('xs-large') {
  .case-card-rewards {
    gap: 8px;
    max-width: calc(100% - 60px);

    top: 30px;
    right: 30px;

    &__wrap-icon {
      gap: 8px;

      svg {
        width: 44px;
        height: 44px;
      }
    }

    &__wrap-description {
      top: 64px;

    }
  }
}

@include respond-between('s-medium', 'medium') {
  .case-card-rewards {

    max-width: calc(100% - 40px);
    top: 20px;
    right: 20px;

    &__wrap-icon {
      gap: 8px;

      svg {
        width: 44px;
        height: 44px;
      }
    }

    &__wrap-description {
      top: 54px;
    }
  }
}

@include respond-down('medium') {
  .case-card-rewards {
    gap: 4px;
  }
}

@include respond-down('small') {
  .case-card-rewards {
    top: 10px;
    right: 10px;
    max-width: calc(100% - 20px);

    &__wrap-icon {
      //width: calc(100% - 10px);
      gap: 4px;

      svg {
        width: 34px;
        height: 34px;
      }
    }

    &__wrap-description {
      top: 44px;
    }
  }
}