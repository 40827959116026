.header-drop-menu-list {
  &__item {
    position: relative;

    &:not(:first-child) {
      .header-drop-menu-list__link {
        padding-top: var(--link-padding-top);
      }
    }
  }

  &__link {
    display: flex;
    padding-bottom: var(--link-padding-bottom);
  }

  &__link-inner {
    //overflow: hidden;
  }

  &__link-title {
    display: flex;
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }
}

@include respond-up('x-large') {
  .header-drop-menu-list {
    --link-padding-top: calc((60 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  .header-drop-menu-list {
    --link-padding-top: 60px;
  }
}

@include respond('xs-large') {
  .header-drop-menu-list {
    --link-padding-top: calc((60 / 1440) * 100vw);
  }
}

@include respond-down('medium') {
  .header-drop-menu-list {
    --link-padding-top: calc((10 / 834) * (var(--vw, 1vw) * 100));
  }
}

@include respond('small') {
  .header-drop-menu-list {
    --link-padding-top: 5px;
  }
}

@include respond('x-small') {
  .header-drop-menu-list {
    --link-padding-top: calc((5 / 374) * (var(--vw, 1vw) * 100));
  }
}