.footer-menu {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: -7px 0 -7px -15px;
  }

  .button_line-through {
    padding: 7px 14px 7px 15px;

    &::before {
      border-bottom-color: var(--opposite-text-color);
    }
  }

  .button__text {
    color: var(--opposite-text-color);
  }
}

@include respond-up('xs-large') {
  .footer-menu {
    &__list {

    }

    &__item {

    }
  }
}

@include respond-up('medium') {
  .footer-menu {

  }
}

@include respond('medium') {
  .footer-menu {

  }
}

@include respond-down('medium') {
  .footer-menu {

  }
}

@include respond-down('small') {
  .footer-menu {

  }
}