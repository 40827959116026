.logo-symbol {
  display: flex;
  align-items: center;
  width: var(--symbol-width);
  height: 100%;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    transition: transform .7s, width .4s, height .4s;
    transition-timing-function: var(--default-timing-function);

    path {
      fill: var(--text-color);
      transition: fill .4s var(--default-timing-function);
    }
  }

  &_r {
    --symbol-width: calc((394 / 1920) * 100vw);
  }

  &_c {
    --symbol-width: calc((329 / 1920) * 100vw);
  }

  &_l {
    --symbol-width: calc((243 / 1920) * 100vw);
  }

  &_a {
    --symbol-width: calc((320 / 1920) * 100vw);
  }

  &_s {
    --symbol-width: calc((300 / 1920) * 100vw);
  }

  &_s-last {
    --symbol-width: calc((274 / 1920) * 100vw);
  }

  @for $i from 1 through 15 {
    &:nth-child(#{$i}) {
      svg {
        transition-delay: calc(#{$i} * .1s);
      }
    }
  }
}