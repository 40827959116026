.index-page-subcases {
  display: flex;
  flex-direction: column;
  position: relative;

  &__video-wrap,
  &__picture {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
    width: var(--grid-column);
    height: calc((288 / 1920) * 100vw);
    opacity: 0;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__video,
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link-icon {
    svg {
      path {
        stroke: var(--border-color);
        transition: stroke .1s var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .index-page-subcases {
    &__list {
      margin-top: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .index-page-subcases {
    &__list {
      margin-top: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .index-page-subcases {
    justify-content: flex-end;

    &__item {
      position: relative;

      &:hover {
        .index-page-subcases {
          &__video-wrap,
          &__picture {
            opacity: 1;
          }

          &__link {
            &::before {
              border-bottom-color: var(--translucency-color);
            }
          }
        }
      }

      &._hovered,
      &._anim-in-playing {
        .index-page-subcases__item-border {
          width: 100%;
          transition-duration: .4s;
        }
      }

      &._anim-out-playing {
        .index-page-subcases__item-border {
          width: 0;
          transition-duration: 1s;
        }
      }
    }

    &__item-border {
      background-color: var(--border-color);
      width: 0;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: width var(--default-timing-function);
      will-change: width, transform;
      animation-fill-mode: forwards;
    }
  }
}

@include respond('xs-large') {
  .index-page-subcases {
    &__list {
      margin-top: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .index-page-subcases {

  }
}

@include respond('medium') {
  .index-page-subcases {

  }
}

@include respond-between('s-medium', 'medium') {
  .index-page-subcases {
    &__title {
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .index-page-subcases {
    &__video-wrap,
    &__picture {
      display: none;
    }

    &__list {
      margin-top: 20px;
    }

    .link-list {
      width: 100%;

      &__link {
        width: 100%;
        display: flex;
      }
    }

    .button__text {
      &:not(:first-child) {
        display: none;
      }
    }

    &__item-border {
      display: none;
    }
  }
}

@include respond('small') {
  .index-page-subcases {
    &__list {
      margin-top: 10px;
    }

    &__title {
      margin-top: 30px;
    }
  }
}

@include respond-down('x-small') {
  .index-page-subcases {
    &__list {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__title {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}

@keyframes anim-in {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes anim-out {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}