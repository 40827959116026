.culture-block {
  &__image-wrap {
    overflow: hidden;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('x-large') {
  .culture-block {
    &__main {
      margin-top: calc((130 / 1920) * 100vw);
    }

    &__main-bottom {
      margin-top: calc((200 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .culture-block {
    &__main {
      margin-top: 130px;
    }

    &__main-bottom {
      margin-top: 200px;
    }
  }
}

@include respond-up('xs-large') {
  .culture-block {
    &__main-top {
      grid-template-columns: repeat(2, 1fr);
    }

    &__main-bottom-title {
      grid-column: 3/5;
      grid-row: 1/2;
    }

    &__main-top-title {
      width: var(--grid-column1);
    }

    &__main-bottom-description-top {
      grid-column: 1/3;
      grid-row: 2/3;
    }

    &__main-bottom-description-bottom {
      grid-column: 4/5;
      grid-row: 4/5;
    }

    &__image-wrap {
      &_first {
        grid-column: 3/5;
        grid-row: 2/4;
        height: calc((810 / 916) * var(--grid-column2));
      }

      &_second {
        grid-column: 1/2;
        grid-row: 3/4;
        height: calc(var(--grid-column) * (405 / 442));
        align-self: flex-end;
      }

      &_third {
        grid-column: 1/3;
        grid-row: 4/5;
        height: calc((556 / 915) * var(--grid-column2));
      }
    }
  }
}

@include respond('xs-large') {
  .culture-block {
    &__main {
      margin-top: calc((130 / 1440) * 100vw);
    }

    &__main-bottom {
      margin-top: calc((200 / 1440) * 100vw);
    }
  }
}

@include respond('medium') {
  .culture-block {

  }
}

@include respond-down('medium') {
  .culture-block {
    &__main {
      margin-top: 70px;
    }

    &__main-top-title {
      width: var(--grid-column2);
    }

    &__main-items {
      margin-top: 70px;
    }

    &__main-top {
      grid-template-columns: 1fr;
    }

    &__main-bottom {
      margin-top: 130px;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 0;
    }

    &__main-bottom-title {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    &__main-bottom-description-top {
      grid-column: 1/3;
      grid-row: 3/4;
      margin-top: 40px;
    }

    &__main-bottom-description-bottom {
      grid-column: 2/3;
      grid-row: 6/7;
      margin-top: 20px;
    }

    &__image-wrap {
      &_first {
        grid-column: 1/3;
        grid-row: 2/3;
        margin-top: 30px;
      }

      &_second {
        grid-column: 1/2;
        grid-row: 4/5;
        margin-top: 120px;
      }

      &_third {
        grid-column: 1/3;
        grid-row: 5/6;
        margin-top: 20px;
      }
    }
  }
}

@include respond-up('s-medium') {
  .culture-block {
    &__main-items {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond('s-medium') {
  .culture-block {

  }
}

@include respond('small') {
  .culture-block {
    &__main {
      margin-top: 30px;
    }

    &__main-items {
      margin-top: 30px;
    }

    &__main-bottom {
      margin-top: 60px;
    }

    &__main-bottom-description-top {
      margin-top: 30px;
    }

    &__image-wrap {
      &_first {
        margin-top: 10px;
      }

      &_second {
        margin-top: 60px;
      }

      &__third {
        margin-top: 10px;
      }
    }

    &__main-item {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .culture-block {
    &__main-items {
      grid-template-columns: 1fr;
    }

    &__main-bottom-description-bottom {
      grid-column: 1/3;
    }
  }
}

@include respond-down('x-small') {
  .culture-block {
    &__main {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__main-items {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__main-bottom {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__main-bottom-description-top {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__main-bottom-description-bottom {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__image-wrap {
      &_first {
        margin-top: calc((10 / 374) * 100vw);
      }

      &_second {
        margin-top: calc((60 / 374) * 100vw);
      }

      &__third {
        margin-top: calc((10 / 374) * 100vw);
      }
    }

    &__main-item {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}
