.switcher {
    padding: 4px;
    width: 39px;
    height: 20px;
    background-color: var(--primary-color);
    border-radius: 100px;
    border: 2px solid var(--light-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color .4s, border-color .4s, transform .4s, opacity .4s 1.9s;
    transition-timing-function: var(--default-timing-function);

    &__selector {
        background-color: var(--light-color);
        border-radius: 100%;
        height: 12px;
        width: 12px;
        transition: background-color .4s, transform .4s;
        transition-timing-function: var(--default-timing-function);
    }

    &._light {
        background-color: var(--light-color);
        border-color: var(--primary-color);

        .switcher__selector {
            background-color: var(--primary-color);
            transform: translate3d(16px, 0 ,0);
        }
    }

    &_desktop {
        position: absolute;
        z-index: 2000;
        right: var(--grid-spacer);
    }

    &_mobile {
        display: none;
    }
}

@include respond-up('large') {
    .switcher {
        &_desktop {
            bottom: 30px;
        }
    }
}

@include respond-down('s-large') {
    .switcher {
        &_desktop {
            bottom: 20px;
        }
    }
}

@include respond-down('medium') {
    .switcher {
        &_desktop {
            display: none;
        }

        &_mobile {
            display: block;
        }
    }
}