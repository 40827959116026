.game {

}

@include respond-up('xs-large') {
  .game {
    display: flex;
    flex-direction: row;
    position: relative;

    &__bottom-line {
      position: absolute;
      bottom: 0;
      left: calc(0px - var(--grid-spacer-and-indent));
      height: 2px;
      width: 100vw;
      background: var(--border-color);

      &._rotated {
        top: var(--header-height);
      }
    }

    &__block {
      font-size: 18px;
      padding: 9px 18px;
      border: 1px solid white;
      border-bottom: none;
      width: fit-content;
      height: fit-content;
      border-radius: 20px 20px 0 0;
    }

    &__blocks {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
    }

    &__car-wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__car-gif {
      width: 255px;
      height: 112px;
      border-radius: 100px;
    }

    &__car-wheels {
      display: flex;
      flex-direction: row;
    }

    &__car-wheel {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid white;

      &:last-child {
        margin-left: 83px;
      }
    }
  }
}

@include respond-up('medium') {
  .game {

  }
}

@include respond('medium') {
  .game {

  }
}

@include respond-down('medium') {
  .game {

  }
}

@include respond-down('small') {
  .game {

  }
}