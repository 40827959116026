h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  //font-family: var(--h-font);
  //font-size: var(--h-size);
  //text-transform: uppercase;
  //letter-spacing: -0.02em;
  //line-height: 110%;
  //font-style: normal;
  //font-weight: 500;
  //color: var(--text-color);
}

h2, .h2 {
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.03em;
  font-family: var(--font);
  line-height: 110%;
  font-size: var(--medium-title-size);
}

h3, .h3 {
  //--h-size: 26px;
}

h4, .h4 {
  //--h-size: 24px;
}

h5, .h5 {
  //--h-size: 22px;
}

h6, .h6 {
  //--h-size: 20px;
}

@include respond-up('xs-large') {
  h2, .h2 {
    --medium-title-size: calc((60 / 1920) * 100vw);
  }
}

@include respond-down('medium') {
  h2, .h2 {
    --medium-title-size: calc((40 / 834) * 100vw);
  }
}


@include respond-down('small') {
  h2, .h2 {
    --medium-title-size: 26px;
  }
}