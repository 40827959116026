.share {
  &__share {
    //color: var(--light-color-40);
  }

  .ya-share2__item_copy .ya-share2__link_copy {
    display: inline-flex;
    align-items: center;
  }

  .ya-share2 {
    &__item {
      &_copy {
        .ya-share2__icon {
          background-image: url('../images/svg/icons/copy-link.svg')
        }
      }

      &_service_vkontakte {
        .ya-share2__icon {
          background-image: url('../images/svg/icons/vk.svg')
        }
      }

      &_service_telegram {
        .ya-share2__icon {
          background-image: url('../images/svg/icons/telegram.svg')
        }
      }

      &_service_whatsapp {
        .ya-share2__icon {
          background-image: url('../images/svg/icons/whatsapp.svg')
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;

      .ya-share2 {
        &__badge {
          background-color: var(--t);
        }
      }
    }

    &__title {
      @extend %text-main;
      color: var(--text-color);
      display: flex;
      align-items: center;
      transition: color .4s var(--default-timing-function);
    }
  }
}

@include respond-up('x-large') {
  .share {
    .ya-share2__container_size_m .ya-share2__badge + .ya-share2__title {
      margin-left: calc((14 / 1920) * 100vw);
    }

    .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
      --icon-copy-height: calc((25 / 1920) * 100vw);
      height: var(--icon-copy-height);
      width: var(--icon-copy-height);
      background-size: var(--icon-copy-height) var(--icon-copy-height);
    }

    .ya-share2 {
      &__link {
        margin-bottom: calc((8 / 1920) * 100vw);
      }

      &__list {
        margin-top: calc((20 / 1920) * 100vw);
      }
    }
  }
}

@include respond-down('large') {
  .share {
    .ya-share2__container_size_m .ya-share2__badge + .ya-share2__title {
      margin-left: 14px;
    }

    .ya-share2 {
      &__link {
        margin-bottom: 2px;
      }

      &__list {
        margin-top: 20px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .share {
    .ya-share2 {
      &__icon {
        transition: opacity .4s var(--default-timing-function);
      }

      &__list {
        margin-top: calc((20 / 1440) * 100vw);

        &:hover {
          opacity: 1;

          .ya-share2 {
            &__title {
              color: var(--translucency-color);
            }

            &__icon {
              opacity: .4;
            }
          }
        }
      }

      &__item {
        &:not(.ya-share2__item_more) {
          &:hover {
            opacity: 1;
          }
        }

        &:hover {
          opacity: 1;

          .ya-share2 {
            &__icon {
              opacity: 1;
            }

            &__title {
              color: var(--text-color);
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .share {
    .ya-share2 {
      &__list {
        margin-top: 10px;
      }
    }
  }
}

@include respond('x-small') {
  .share {
    .ya-share2 {
      &__list {
        margin-top: calc((10 / 374) * 100vw);
      }
    }
  }
}