.index-rewards {
  &__title-wrap {
    &.need-animation {
      .index-rewards {
        &__title {
          transform: translate3d(-50%, 0, 0);
        }
      }
    }

    &.animate {
      .index-rewards {
        &__title {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__title {
    transition: transform;
    transition-duration: .7s;
    transition-timing-function: var(--default-timing-function);

    .animation-item__inner {
      display: block;
    }
  }
}

@include respond-up('xs-large') {
  .index-rewards {
    margin-top: 200px;

    &__list {
      margin-top: 70px;
    }

    &__item {
      &:nth-child(1) {
        grid-column: 1/3;
      }

      &:nth-child(2) {
        grid-column: 3/4;
      }

      &:nth-child(3) {
        grid-column: 4/5;
      }
    }
  }
}

@include respond-up('medium') {
  .index-rewards {

  }
}

@include respond('medium') {
  .index-rewards {
    margin-top: 120px;

    &__list {
      margin-top: 40px;

      &.grid-block {
        row-gap: 80px;
      }
    }

    &__item {
      &:nth-child(1) {
        grid-column: 1/5;
        grid-row: 1;
      }

      &:nth-child(2) {
        grid-column: 1/3;
        grid-row: 2;
      }

      &:nth-child(3) {
        grid-column: 3/5;
        grid-row: 2;
      }
    }
  }
}

@include respond-down('medium') {
  .index-rewards {

  }
}

@include respond-down('small') {
  .index-rewards {
    margin-top: 60px;

    &__list {
      margin-top: 20px;
      grid-template-columns: 1fr;

      &.grid-block {
        row-gap: 40px;
      }
    }

    &__item {
      grid-column: 1/3;
    }
  }
}