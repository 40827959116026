.fresh {
  #top-eye {
    animation: top-eye 1.4s var(--default-timing-function) infinite;
  }

  #bottom-eye {
    animation: bottom-eye 1.4s var(--default-timing-function) infinite;
  }

  #pupil {
    transform-origin: 46% 50%;
    animation: rotation 4.3s linear infinite;

    fill: var(--border-color);
    transition: stroke, fill;
    transition-duration: .4s;
    transition-timing-function: var(--default-timing-function);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes top-eye {
  from {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 29px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bottom-eye {
  from {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -29px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}