.icon {
  &_arrow {
    svg {
      width: var(--icon-size);
      height: var(--icon-size);
    }
  }
}

@include respond-up('x-large') {
  .icon {
    &_arrow {
      --icon-size: calc((25 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .icon {
    &_arrow {
      --icon-size: 25px;
    }
  }
}

@include respond('xs-large') {
  .icon {
    &_arrow {
      --icon-size: calc((25 / 1440) * 100vw);
    }
  }
}

@include respond('small') {
  .icon {
    &_arrow {
      --icon-size: 17px;
    }
  }
}

@include respond('x-small') {
  .icon {
    &_arrow {
      --icon-size: calc((17 / 375) * 100vw);
    }
  }
}