.index-page-services-list {
  display: grid;
  grid-column-gap: var(--grid-gap);

  &__item {
    display: grid;
    height: 100%;
    grid-gap: var(--grid-gap);
    border-top: 2px solid var(--border-color);
    border-left: 2px solid var(--border-color);

    transition: border .4s var(--default-timing-function);
  }

  &__link{
    width: 100%;
  }

  &__title-wrap {
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
  }

  &__title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--text-color);

        transition: stroke .4s var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .index-page-services-list {
    grid-row-gap: calc((130 / 1920) * 100vw);

    &__item {
      padding: calc((30 / 1920) * 100vw) calc((30 / 1920) * 100vw) 0;
    }

    &__description {
      padding-top: calc((148 / 1920) * 100vw);
    }

    &__title-icon {
      margin-top: calc((11 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .index-page-services-list {
    grid-row-gap: 130px;

    &__item {
      padding: 30px 30px 0;
    }

    &__description {
      padding-top: 148px;
    }

    &__title-icon {
      margin-top: 11px;
    }
  }
}

@include respond-up('xs-large') {
  .index-page-services-list {
    grid-template-columns: repeat(2, 1fr);

    &__link{
      margin-top: 100px;
    }

    &__item {
      &:hover {
        .index-page-services-list {
          &__title-text {
            &::before {
              transform-origin: left;
              transform: scaleX(1);
            }
          }
        }
      }
    }

    &__title-text {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: var(--border-color);
        transition: transform var(--default-transition-time) var(--default-bezier),
                    background var(--default-transition-time) var(--default-timing-function);
        transform-origin: right;
        transform: scaleX(0);
      }
    }
  }
}

@include respond('xs-large') {
  .index-page-services-list {
    grid-row-gap: calc((130 / 1440) * 100vw);

    &__item {
      padding: calc((30 / 1440) * 100vw) calc((30 / 1440) * 100vw) 0;
    }

    &__description {
      padding-top: calc((148 / 1440) * 100vw);
    }

    &__title-icon {
      margin-top: calc((11 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .index-page-services-list {
    &__link{
      margin-top: 60px;
    }

    &__title-icon {
      margin-top: 7px;
    }
  }
}

@include respond-down('medium') {
  .index-page-services-list {
    grid-row-gap: 70px;

    &__item {
      padding: 20px 20px 0;
    }

    &__description {
      padding-top: 86px;
    }
  }
}

@include respond-up('s-medium') {
  .index-page-services-list {
    &__item {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond('small') {
  .index-page-services-list {
    grid-row-gap: 40px;

    &__item {
      padding: 15px 20px 0;
    }

    &__description {
      padding-top: 30px;
    }
  }
}

@include respond-down('small') {
  .index-page-services-list {
    &__link{
      margin-top: 40px;
    }

    &__title-wrap {
      column-gap: 8px;
    }

    &__title-icon {
      margin-top: 6px;
    }
  }
}

@include respond('x-small') {
  .index-page-services-list {
    grid-row-gap: calc((40 / 374) * 100vw);

    &__item {
      padding: calc((15 / 374) * 100vw) calc((20 / 374) * 100vw) 0;
    }

    &__description {
      padding-top: calc((30 / 374) * 100vw);
    }

    &__title-icon {
      margin-top: calc((6 / 374) * 100vw);
    }
  }
}
