.contacts-socials {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__title {
    color: var(--translucency-color);
    text-transform: capitalize;
    margin-left: 10px;
  }

  &__icon {
    width: 13px;
    height: 13px;
    border-radius: 100%;
  }

  &__item {
    &_telegram {
      .contacts-socials__icon {
        background-color: var(--blue-color);
      }
    }

    &_viber {
      .contacts-socials__icon {
        background-color: var(--purple-color);
      }
    }

    &_whatsapp{
      .contacts-socials__icon {
        background-color: var(--green-color);
      }
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }
}

@include respond-up('xs-large') {
  .contacts-socials {
    &__link {
      &:hover {
        .contacts-socials__title {
          color: var(--text-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-socials {

  }
}

@include respond('medium') {
  .contacts-socials {

  }
}

@include respond-down('medium') {
  .contacts-socials {

  }
}

@include respond-down('small') {
  .contacts-socials {

  }
}