.subcase {
  &__see-also-title {
    border-bottom: 2px solid var(--border-color);
    padding-bottom: var(--see-also-bottom);
  }

  &__picture {
    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__custom-circle-number,
  &__custom-circle-text {
    color: #fff;
  }

  &__custom-analytic-icon {
    svg {
      width: 100%;
      height: auto;

      path,
      line {
        stroke-width: 2px;
      }
    }
  }

  &__subcase-page {
    padding-bottom: var(--subcase-bottom-padding);
  }

  &__custom-analytic-item {
    display: flex;
  }

  &__text {
    .edit-block {
      ul {
        padding-left: 25px;

        li {
          @extend %text-main;
          list-style: outside;
        }
      }
    }
  }

  &__custom-result-card-content-wrap {
    display: flex;
    height: 100%;
    position: relative;
    align-items: flex-end;
  }

  &__custom-result-card-icon {
    position: absolute;
    display: flex;

    svg {
      overflow: visible;

      line,
      path,
      circle {
        stroke-width: 2px;
      }
    }
  }

  &__custom-result-cards-item {
    border: 2px solid #F4731E;

    &:first-child {
      .subcase {
        &__custom-result-card-content-wrap {
          &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            background: #F4731E;
            left: 0;
          }
        }
      }
    }
  }

  &__custom-result-card-text {
    color: black;
  }

  &__custom-circle-text {
    text-align: center;
  }

  &__custom-circles-list {
    display: flex;
  }

  &__custom-circle {
    color: white;
    border: 2px solid #F4731E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__custom-analytic-text {
    color: black;
  }

  &__custom-site-info-percent-number,
  &__custom-site-info-percent {
    color: #F4731E;
  }

  &__custom-site-info-text {
    color: white;
  }

  &__custom-card {
    border-left: 2px solid var(--border-color);
    border-top: 2px solid var(--border-color);
    display: flex;
    flex-direction: column;
  }

  &__custom-grey-bg-color {
    background-color: #252525;
  }

  &__custom-white-bg-color {
    background-color: #E8E8E8;
  }

  &__custom-list-text {
    color: white;
  }

  &__custom-list-item {
    display: flex;

    &:not(:first-child) {
      border-top: 2px solid rgba(225, 225, 225, 0.4);
    }
  }

  &__sub-heading {
    grid-row: span 3;
  }

  &__units {
    align-self: auto;
  }

  &__units {
    padding-bottom: var(--compesation-padding);
  }

  &__list-list {
    ul {
      li {
        position: relative;

        ::selection,
        &::selection {
          background-color: var(--text-color);
          color: var(--background-color);
        }

        &:before {
          content: '';
          position: absolute;
          background: var(--text-color);
          width: 6px;
          height: 6px;
          border-radius: 3px;
          transition: background-color .4s var(--default-timing-function);
        }
      }
    }
  }

  &__list-label {
    text-transform: uppercase;
  }

  &__review-picture {
    height: var(--avatar-size);
    width: var(--avatar-size);

    .index-page-case__picture {
      border-radius: 50%;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__review-wrapper {
    display: flex;
    align-items: center;
  }

  &__review-author {
    display: flex;
    flex-direction: column;
  }

  &__review-title {
    color: var(--light-color-40);
  }

  &__review-avatar {
    margin-left: -20px;
  }

  &__text-client {
    width: var(--client-text-column);
  }

  &__name {
    //height: fit-content;
    justify-content: flex-start;
    text-align: left;
    height: 100%;
  }

  &__services {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    border-top: 2px solid var(--border-color);
  }

  &__item {
    border-bottom: 2px solid var(--border-color);
  }

  &__text-block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__picture-block {

  }

  &_desktop-half {
    grid-template-rows: auto 1fr;
  }

  &__wrap {
    display: flex;
    align-items: flex-end;
  }

  &__span1 {
    grid-column: span 1;
  }

  &__span2 {
    grid-column: span 2;
  }

  &__content {
    padding-bottom: var(--top200);
  }

  .grid-block_clean {
    grid-row-gap: 0;
  }

  &__head {
  }

  &__head-counter {
    display: flex;
    align-items: flex-end;
    margin-top: var(--top050);

    .text__big-title {
      line-height: 76%;
    }
  }

  &__head-counter-base {
    display: flex;
  }

  &__main-title {
    grid-column: var(--column-title);
  }

  &__wide-picture {
    grid-column: var(--column-wide-picture);
  }

  &__top030 {
    margin-top: var(--top030);
  }

  &__see-also-top030 {
    margin-top: var(--see-also-bottom);
  }

  &__top030-to-010 {
    margin-top: var(--top030-to-010);
  }

  &__top050 {
    margin-top: var(--top050);
  }

  &__top070 {
    margin-top: var(--top070);
  }

  &__top080 {
    margin-top: var(--top080);
  }

  &__top100 {
    margin-top: var(--top100);
  }

  &__top130 {
    margin-top: var(--top130);
  }

  &__top120 {
    margin-top: var(--top120);
  }

  &__top200 {
    margin-top: var(--top200);
  }

  &__name {
    grid-column: span 2;
  }

  &__list-link {
    //height: 100%;
  }

  &__review-images-wrap {
    display: flex;
  }

  &__top-description {
    grid-column: 1/3;
  }
}

@include respond-up('x-large') {
  .subcase {
    &__units {
      --compesation-padding: calc((15 / 1920) * 100vw);
    }

    --subcase-bottom-padding: calc((200 / 1920) * 100vw);

    --top030: calc((30 / 1920) * 100vw);
    --top030-to-010: calc((30 / 1920) * 100vw);
    --top050: calc((50 / 1920) * 100vw);
    --top070: calc((70 / 1920) * 100vw);
    --top080: calc((80 / 1920) * 100vw);
    --top100: calc((100 / 1920) * 100vw);
    --top130: calc((130 / 1920) * 100vw);
    --top120: calc((120 / 1920) * 100vw);
    --top200: calc((200 / 1920) * 100vw);
    --avatar-size: calc((170 / 1920) * 100vw);
    --avatar-margin: calc((40 / 1920) * 100vw);
    --see-also-bottom: calc((30 / 1920) * 100vw);

    &__list-list {
      ul {
        li {
          padding-left: calc((30 / 1920) * 100vw);

          &:before {
            left: calc((12 / 1920) * 100vw);
            top: calc((11 / 1920) * 100vw);
            width: calc((6 / 1920) * 100vw);
            height: calc((6 / 1920) * 100vw);
          }
        }
      }
    }
  }
}

@include respond-up('xs-large') {
  .subcase {
    --client-text-column: var(--grid-column1);

    &__custom-result-cards-item {
      &:first-child {
        .subcase {
          &__custom-result-card-icon {
            top: calc((30 / 1920) * 100vw);
            left: calc((30 / 1920) * 100vw);
          }
        }
      }

      &:nth-child(2) {
        .subcase {
          &__custom-result-card-icon {
            top: calc((110 / 1920) * 100vw);
            left: calc((60 / 1920) * 100vw);
          }
        }
      }
    }

    &__custom-card {
      grid-column: span 1;
      padding: var(--grid-gap) 0 0 var(--grid-gap);

      &:first-child {
        grid-column-start: 2;
      }
    }

    &__custom-card-text {
      margin-top: 100px;
    }

    &__custom-site-info-list {
      padding: calc((150 / 1920) * 100vw) calc((161 / 1920) * 100vw);
      column-gap: calc((163 / 1920) * 100vw);

      &:after,
      &:before {
        height: calc(100% - (300 / 1920) * 100vw);
        width: 2px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__custom-site-info-text {
      margin-top: calc((35 / 1920) * 100vw);
      width: calc((296 / 1920) * 100vw);
    }

    &__picture {
      grid-column: span 1;
    }

    &__item {
      padding: var(--top030) 0;

      .subcase__name {
        display: block;

        transition: text-decoration-color .4s, color .4s;
        transition-timing-function: var(--default-timing-function);

        text-decoration: underline;
        text-decoration-color: var(--t);
        text-decoration-thickness: 2px;
        text-underline-offset: 7px;

        span {
          //background: linear-gradient(transparent calc(100% - 2px), var(--light-color) calc(100% - 2px), var(--light-color) 100%, transparent calc(100% - 1px), transparent) no-repeat 100%/100% 0;
          //transition: background-size .4s var(--default-timing-function);
        }
      }

      &:hover {
        .subcase__name {
          text-decoration-color: var(--border-color);

          //span {
          //  background-size: 100% 100%;
          //}
        }
      }
    }

    &__text-block {
      grid-column: 1/3;

      .text__upper-medium-title {
        margin-top: var(--top100);
      }
    }

    &__picture-block {
      grid-column: 3/5;
      height: calc(var(--grid-column2) * 748 / 915);
      margin-top: -15px;
    }

    &__top-link {
      grid-column: 1/3;
    }

    &__top-description {
      grid-column: 1/2;
    }

    &__top-services {
      grid-column: 3/4;
    }

    &__top-year {
      grid-column: 4/5;
      text-align: right;
    }

    &__sub-heading {
      grid-column: span 2;
      width: calc(var(--grid-column1) * 1.5 + var(--grid-gap));
    }

    &__text-half {
      grid-column: span 1;
    }

    &__text {
      grid-column: span 2;
    }

    &__list-wrapper {
      grid-column: 3/5;
    }

    &__sub-heading,
    &__text,
    &__text-half,
    &__list-wrapper {
      ::selection,
      &::selection {
        background-color: var(--text-color);
        color: var(--background-color);
      }
    }
  }
}

@include respond('large') {
  .subcase {
    --subcase-bottom-padding: 200px;

    &__units {
      --compesation-padding: 15px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .subcase {
    --top030: 30px;
    --top030-to-010: 30px;
    --top050: 50px;
    --top070: 70px;
    --top080: 80px;
    --top100: 100px;
    --top130: 130px;
    --top120: 120px;
    --top200: 200px;
    --avatar-size: 170px;
    --avatar-margin: 40px;
    --see-also-bottom: 30px;

    &__list-list {
      ul {
        li {
          padding-left: 30px;

          &:before {
            left: 12px;
            top: 11px;
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .subcase {
    --subcase-bottom-padding: 200px;

    &__units {
      --compesation-padding: 8px;
    }
  }
}

@include respond-up('xs-large') {
  .subcase {
    --column-title: 1/4;

    &__custom-site-info-list {
      position: relative;

      &:after {
        right: 34%;
      }

      &:before {
        left: 34%;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        height: calc(100% - (((60 / 840) * 100vw) * 2));
        width: 2px;
        background-color: rgba(255, 255, 255, 0.4);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__custom-list-item {
      &:not(:first-child) {
        padding-top: 50px;
      }

      &:not(:last-child) {
        padding-bottom: 50px;
      }
    }

    &__custom-analytic-item {
      row-gap: calc((84 / 1920) * 100vw);
    }

    &__custom-circle-text {
      max-width: 85%;
    }

    &__custom-circles-list {
      column-gap: calc((58 / 1920) * 100vw);
      padding: calc((218 / 1920) * 100vw) 0;
    }

    &__custom-circle {
      width: calc((364 / 1920) * 100vw);
      height: calc((364 / 1920) * 100vw);
    }
  }
}

@include respond('xs-large') {
  .subcase {
    --subcase-bottom-padding: calc((200 / 1440) * 100vw);

    --top030: calc((30 / 1440) * 100vw);
    --top030-to-010: calc((30 / 1440) * 100vw);
    --top050: calc((50 / 1440) * 100vw);
    --top070: calc((70 / 1440) * 100vw);
    --top080: calc((80 / 1440) * 100vw);
    --top100: calc((100 / 1440) * 100vw);
    --top130: calc((130 / 1440) * 100vw);
    --top120: calc((120 / 1440) * 100vw);
    --top200: calc((200 / 1440) * 100vw);
    --avatar-size: calc((170 / 1440) * 100vw);
    --avatar-margin: calc((40 / 1440) * 100vw);
    --see-also-bottom: calc((30 / 1440) * 100vw);

    &__custom-analytic-item {
      row-gap: calc((84 / 1440) * 100vw);
    }

    &__units {
      --compesation-padding: 8px;
    }

    &__list-list {
      ul {
        li {
          padding-left: calc((27 / 1440) * 100vw);

          &:before {
            left: calc((10 / 1440) * 100vw);
            top: calc((10 / 1440) * 100vw);
            width: calc((6 / 1440) * 100vw);
            height: calc((6 / 1440) * 100vw);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .subcase {
    &__custom-analytic-item {
      flex-direction: column;
    }

    &__custom-result-cards-list {
      position: relative;
      padding: calc((160 / 1920) * 100vw) calc((168 / 1920) * 100vw) calc((270 / 1920) * 100vw) calc((168 / 1920) * 100vw);
    }

    &__custom-result-card-content-wrap {
      padding: calc((30 / 1920) * 100vw);
      top: -5px;
    }

    &__custom-result-cards-item {
      border-radius: 10px;
      width: calc((574 / 1920) * 100vw);
      height: calc((383 / 1920) * 100vw);

      &:not(:first-child) {
        position: absolute;
      }

      &:first-child {
        .subcase {
          &__custom-result-card-icon {
            svg {
              width: calc((77 / 1920) * 100vw);
              height: auto;
            }
          }

          &__custom-result-card-content-wrap {
            &:before {
              top: calc((75 / 1920) * 100vw);
            }
          }
        }
      }

      &:nth-child(2) {
        left: 50%;
        transform: translateX(-50%);
        top: calc(((160 / 1920) * 100vw) + (55 / 1920) * 100vw);

        .subcase {
          &__custom-result-card-icon {
            svg {
              width: calc((441 / 1920) * 100vw);
              height: auto;
            }
          }
        }
      }

      &:last-child {
        right: calc((168 / 1920) * 100vw);
        top: calc(((160 / 1920) * 100vw) + (110 / 1920) * 100vw);

        .subcase {
          &__custom-result-card-icon {
            top: calc((57 / 1920) * 100vw);
            right: calc((66 / 1920) * 100vw);

            svg {
              width: calc((231 / 1920) * 100vw);
              height: auto;
            }
          }
        }
      }
    }

    &__custom-result-card-text {
      max-width: calc((410 / 1920) * 100vw);
    }

    &__custom-result-card-icon {
      &_mob {
        display: none;
      }
    }

    &__custom-circles-list {
      flex-direction: row;
      align-items: center;
      justify-content: center;

    }

    &__custom-circle-number {
      position: absolute;
      left: 50%;
    }

    &__custom-circle-number {
      top: calc((40 / 1920) * 100vw * -1);
      transform: translate3d(-50%, -100%, 0);
    }

    &__custom-circle-text {
      padding: 0 calc((27 / 1920) * 100vw);
      z-index: 20;
    }

    &__custom-circle {
      &:nth-child(2n) {
        .subcase {
          &__custom-circle-number {
            bottom: calc((40 / 1920) * 100vw * -1);
            transform: translate3d(-50%, 100%, 0);
          }
        }
      }

      &:first-child {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          background: #F4731E;
          top: 50%;
          left: 50%;
          transform: translate3d(0, -50%, 0);
          z-index: 10;
          // 364 - это ширина круга, * 3 потому что линия начинается и заканчивается в центре первого и последнего круга
          // 58 - это gap мемжу кругами, их 3
          width: calc(((364 / 1920) * 100vw * 3) + ((58 / 1920) * 100vw * 3));
        }
      }

      &:not(:first-child) {
        .subcase {
          &__custom-circle-text {
            position: relative;

            &:before,
            &:after {
              position: absolute;
              content: '';
              width: 11px;
              height: 2px;
              top: 50%;
              border-radius: 10px;
              background: #F4731E;
              left: 0;
            }

            &:before {
              transform: translate3d(-100%, -50%, 0) rotate(45deg);
              transform-origin: bottom right;
            }

            &:after {
              transform: translate3d(-100%, -50%, 0) rotate(-45deg);
              transform-origin: top right;
            }
          }
        }
      }
    }

    &__custom-analytic-list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: calc((165 / 1920) * 100vw) calc((170 / 1920) * 100vw);
    }

    &__custom-analytic-text {
      max-width: calc((407 / 1920) * 100vw);
    }

    &__custom-analytic-icon {
      width: calc((297 / 1920) * 100vw);
    }

    &__custom-site-info-list {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      padding: calc((60 / 840) * 100vw);
      column-gap: calc((74 / 840) * 100vw);
    }

    &__custom-site-info-text {
      margin-top: calc((20 / 840) * 100vw);
      width: calc((175 / 840) * 100vw);
    }
  }
}

@include respond('medium') {
  .subcase {
    --subcase-bottom-padding: 120px;

    &__custom-analytic-list {
      padding: calc((60 / 834) * 100vw);
    }

    &__custom-analytic-item {
      column-gap: calc((40 / 834) * 100vw);
    }

    &__custom-circle-text {
      max-width: 83%;
      border-radius: 50%;
    }

    &__custom-circle-number,
    &__custom-circle-text {
      font-size: 18px;
    }

    &__custom-circles-list {
      column-gap: calc((18 / 840) * 100vw);
      padding: calc((100 / 840) * 100vw) 0 calc((110 / 840) * 100vw);
    }

    &__custom-circle {
      width: calc((168 / 840) * 100vw);
      height: calc((168 / 840) * 100vw);

      &:first-child {
        .subcase {
          &__custom-circle-text {
            padding: 0;
          }
        }
      }
    }

    &__custom-result-card-icon_desc {
      display: block;
    }

    &__custom-result-cards-item {
      &:first-child {
        .subcase {
          &__custom-result-card-icon {
            top: calc((20 / 1920) * 100vw);
            left: calc((20 / 1920) * 100vw);
          }
        }
      }

      &:nth-child(2) {
        .subcase {
          &__custom-result-card-icon {
            top: calc((55 / 1920) * 100vw);
            left: calc((30 / 1920) * 100vw);
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .subcase {

    &__top20 {
      margin-top: 20px;
    }

    &__custom-site-info-list {
      display: flex;
      flex-direction: column;
      padding: calc((24 / 374) * 100vw);
      row-gap: calc((34 / 374) * 100vw);
    }

    &__custom-site-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-child) {
        padding-top: calc((33 / 374) * 100vw);
        border-top: 2px solid rgba(255, 255, 255, 0.4);
      }
    }

    &__custom-site-info-text {
      margin-left: calc((24 / 374) * 100vw);
      width: calc((170 / 374) * 100vw);
    }

    &__picture {
      grid-column: span 2;
    }

    &__services {
      align-items: flex-end;
    }

    &__text-block {
      order: 2;

      .text__medium-title {
        margin-top: var(--top100);
      }
    }

    &__picture-block {
      margin-top: var(--top100);
      order: 1;
    }

    &__text-half {
      grid-column: span 2;
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .subcase {
    --client-text-column: var(--grid-column3);

    --top030: 20px;
    --top030-to-010: 20px;
    --top050: 40px;
    --top070: 40px;
    --top080: 60px;
    --top100: 70px;
    --top130: 80px;
    --top120: 100px;
    --top200: 120px;
    --avatar-size: 130px;
    --avatar-margin: 30px;
    --see-also-bottom: 20px;

    --column-title: 1/5;

    &__custom-list-item {
      &:not(:first-child) {
        padding-top: 25px;
      }

      &:not(:last-child) {
        padding-bottom: 25px;
      }
    }

    &__custom-analytic-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__custom-analytic-item {
      flex-direction: column;
      max-width: calc((197 / 834) * 100vw);
      row-gap: calc((40 / 834) * 100vw);
    }

    &__custom-analytic-icon {
      width: calc((128 / 834) * 100vw);
      height: auto;
    }

    &__custom-cards-block {
      grid-row-gap: 70px;
    }

    &__custom-card {
      grid-column: span 2;
      padding: var(--grid-gap) 0 0 var(--grid-gap);

      &:last-child {
        grid-column: span 4;
      }
    }

    &__custom-card-text {
      margin-top: 50px;
    }

    &__units {
      --compesation-padding: 6px;
    }

    &__list-list {
      ul {
        li {
          padding-left: calc((27 / 1024) * 100vw);

          &:before {
            left: calc((10 / 1024) * 100vw);
            top: 10px;
          }
        }
      }
    }

    &__list-wrapper {
      grid-column: 1/5;
    }

    &__services {
      grid-column: span 4;
    }

    &__task {
      margin-top: var(--top120);
    }

    &__item {
      padding: var(--top030) 0 calc(3 * var(--top030));
    }

    &__text-block,
    &__picture-block {
      grid-column: span 4;
    }

    &__top-link {
      grid-column: 1/3;
    }

    &__top-services {
      grid-column: 3/5;
    }

    &__top-year {
      display: none;
    }

    &__span1 {
      grid-column: span 2;
    }

    &__span2,
    &__text,
    &__sub-heading {
      grid-column: span 4;
    }
  }
}

@include respond-up('s-medium') {
  .subcase {
    --column-wide-picture: 1/5;

    &__review-author {
      margin-left: var(--avatar-margin);
    }

    &__custom-list-icons {
      padding: calc((120 / 1920) * 100vw) calc((170 / 1920) * 100vw);
    }

    &__custom-list-icon {
      margin-right: calc((130 / 1920) * 100vw);

      svg {
        path,
        line {
          stroke-width: 2px;
        }

        g {
          path {
            stroke-width: 2px;
          }
        }
      }
    }

    &__custom-list-item {
      align-items: center;
    }
  }
}

@include respond('s-medium') {
  .subcase {
    --subcase-bottom-padding: 120px;

    &__custom-site-info-list {
      padding: calc((30 / 568) * 100vw) calc((32 / 568) * 100vw) calc((47 / 568) * 100vw);
      row-gap: 23px;
    }

    &__custom-site-info-item {
      &:not(:first-child) {
        padding-top: 23px;
      }
    }

    &__custom-site-info-text {
      width: calc((277 / 568) * 100vw);
    }

    &__custom-analytic-list {
      padding: calc((32 / 568) * 100vw);
    }

    &__custom-analytic-item {
      column-gap: calc((30 / 568) * 100vw);
    }

    &__custom-circle {
      width: calc(var(--grid-column2) - ((20 / 374) * 100vw));
      height: calc(var(--grid-column2) - ((20 / 374) * 100vw));
    }

    &__custom-circles-list {
      gap: var(--grid-gap);
    }

    &__custom-result-cards-list {
      padding: 60px 60px 24px;
    }

    &__custom-result-card-content-wrap {
      padding: 0 15px 34px;
    }
  }
}

@include respond-down('s-medium') {
  .subcase {

    &__custom-site-info-list {
      display: flex;
      flex-direction: column;
    }

    &__custom-site-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-child) {
        border-top: 2px solid rgba(255, 255, 255, 0.4);
      }
    }

    &__custom-site-info-text {
      font-size: 15px;
      line-height: 130%;
      color: #fff;
    }

    &__custom-result-card-icon {
      &_desc {
        display: none;
      }
    }

    &__custom-result-cards-list {
      display: flex;
      flex-direction: column;
    }

    &__custom-result-cards-item {
      border-radius: 5px;
      overflow: hidden;
      width: calc((203 / 374) * 100vw);
      height: 147px;

      &:first-child {
        .subcase {
          &__custom-result-card-icon {
            top: 12px;
            left: 12px;
          }

          &__custom-result-card-content-wrap {
            &:before {
              top: 34px;
            }
          }
        }
      }

      &:nth-child(2) {
        align-self: center;
        transform: translate3d(0, -18px, 0);

        .subcase {
          &__custom-result-card-icon {
            top: 16px;
            right: 0;
          }
        }
      }

      &:last-child {
        align-self: self-end;
        transform: translate3d(0, -36px, 0);

        .subcase {
          &__custom-result-card-icon {
            top: 16px;
            right: 17px;
          }
        }
      }
    }

    &__custom-result-card-text {
      max-width: 167px;
    }

    &__custom-list-icon {
      svg {
        width: 70px;
        height: 70px;
      }
    }

    &__custom-circles-list {
      padding: calc((20 / 374) * 100vw);
      flex-wrap: wrap;
    }

    &__custom-circle {
      flex-direction: column;
      padding: 5px;
    }

    &__custom-circle-text {
      margin-top: calc((8 / 374) * 100vw);
      background: var(--t);
    }

    &__custom-circle-number {
      color: #F4731E;
    }
  }
}

@include respond('small') {
  .subcase {
    --subcase-bottom-padding: 60px;

    --top030: 20px;
    --top030-to-010: 10px;
    --top050: 20px;
    --top070: 20px;
    --top080: 40px;
    --top100: 30px;
    --top130: 50px;
    --top120: 60px;
    --top200: 60px;
    --avatar-size: 92px;
    --avatar-margin: 20px;
    --see-also-bottom: 10px;

    &__list-list {
      ul {
        li {
          padding-left: 27px;

          &:before {
            left: 10px;
            top: 9px;
          }
        }
      }
    }

    &__custom-circles-list {
      gap: calc(100vw - 20px - (((20 / 374) * 100vw) * 2) - (((151 / 374) * 100vw) * 2));
    }
  }
}

@include respond-down('small') {
  .subcase {
    --client-text-column: var(--grid-column2);
    --column-title: 1/3;
    --column-wide-picture: 1/3;

    &__custom-site-info-list {
      padding: calc((24 / 374) * 100vw);
      row-gap: 33px;
    }

    &__custom-site-info-item {
      &:not(:first-child) {
        padding-top: 33px;
      }
    }

    &__custom-site-info-text {
      margin-left: calc((24 / 374) * 100vw);
      width: calc((170 / 374) * 100vw);
    }

    &__custom-result-cards-list {
      padding: 24px 24px 0;
    }

    &__custom-result-card-content-wrap {
      padding: 0 21px 34px 15px;
    }

    &__custom-analytic-icon {
      width: calc((83 / 374) * 100vw);
      height: auto;
    }

    &__custom-analytic-text {
      max-width: calc((167 / 374) * 100vw);
    }

    &__custom-analytic-list {
      display: flex;
      flex-direction: column;
      row-gap: calc((20 / 374) * 100vw);
      padding: calc((26 / 374) * 100vw) calc((50 / 374) * 100vw) calc((40 / 374) * 100vw) calc((24 / 374) * 100vw);
    }

    &__custom-analytic-item {
      flex-direction: row;
      align-items: center;
      column-gap: calc((30 / 374) * 100vw);
    }

    &__custom-site-info-percent-number {
      font-size: 48px;
      line-height: 110%;
    }

    &__custom-site-info-percent {
      font-size: 24px;
      line-height: 26px;
    }

    &__custom-site-info-percent-number,
    &__custom-site-info-percent {
      font-family: var(--h-font);
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.02em;
    }

    &__custom-site-info-text {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 130%;
      letter-spacing: -0.03em;
    }

    &__custom-circle-number {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: -0.03em;
    }

    &__custom-circle-text {
      background: var(--t);
    }

    &__custom-circle-number {
      line-height: 140%;
    }

    &__custom-circle {
      width: calc((151 / 374) * 100vw);
      height: calc((151 / 374) * 100vw);
    }

    &__custom-list-icons {
      padding: calc((35 / 374) * 100vw) calc((24 / 374) * 100vw);
    }

    &__custom-list-icon {
      margin-right: calc((20 / 374) * 100vw);
      width: 70px;
      height: 70px;

      svg {
        width: 100%;
        height: 100%;

        path,
        line {
          stroke-width: calc((2 / 374) * 100vw);
        }

        g {
          path {
            stroke-width: calc((2 / 374) * 100vw);
          }
        }
      }
    }

    &__custom-list-text {
      width: calc((216 / 374) * 100vw);
    }

    &__custom-list-item {
      align-items: flex-start;

      &:not(:first-child) {
        padding-top: 30px;
      }

      &:not(:last-child) {
        padding-bottom: 30px;
      }
    }

    &__custom-cards-block {
      grid-row-gap: 40px;
    }

    &__custom-card {
      grid-column: span 2;
      padding: 15px 0 0 20px;
    }

    &__custom-card-text {
      margin-top: 40px;
    }

    &__units {
      margin-bottom: 2px;
    }

    &__list-wrapper {
      grid-column: 1/3;
    }

    &__item {
      padding: calc(var(--top030) / 2) 0 calc(2 * var(--top030));
    }

    &__task {
      margin-top: var(--top130);
    }

    &__top-link,
    &__top-services {
      grid-column: 1/3;
    }

    &__top-year {
      display: none;
    }

    &__name,
    &__services,
    &__text-block,
    &__picture-block,
    &__text,
    &__sub-heading {
      grid-column: span 2;
    }

    &__review-wrapper {
      flex-direction: column;
      row-gap: calc((10 / 374) * 100vw);
      align-items: flex-start;
    }

    &__review-author {
      margin-left: 0;
      width: 100%;
    }
  }
}

@include respond-down('x-small') {
  .subcase {
    --subcase-bottom-padding: calc((60 / 374) * 100vw);

    --top030: calc((20 / 374) * 100vw);
    --top030-to-010: calc((10 / 374) * 100vw);
    --top050: calc((20 / 374) * 100vw);
    --top070: calc((20 / 374) * 100vw);
    --top100: calc((30 / 374) * 100vw);
    --top130: calc((50 / 374) * 100vw);
    --top120: calc((60 / 374) * 100vw);
    --top200: calc((60 / 374) * 100vw);
    --avatar-size: calc((70 / 374) * 100vw);
    --avatar-margin: calc((20 / 374) * 100vw);
    --see-also-bottom: calc((20 / 374) * 100vw);

    &__list-list {
      ul {
        li {
          padding-left: calc((27 / 374) * 100vw);

          &:before {
            left: calc((10 / 374) * 100vw);
            top: calc((9 / 374) * 100vw);
          }
        }
      }
    }

    &__custom-analytic-icon {
      svg {
        path,
        line {
          stroke-width: calc((2 / 374) * 100vw);
        }
      }
    }

    &__custom-result-card-icon {
      svg {
        line,
        path,
        circle {
          stroke-width: calc((2 / 374) * 100vw);
        }
      }
    }

    &__custom-circles-list {
      gap: calc(100vw - ((20 / 374) * 100vw) - (((20 / 374) * 100vw) * 2) - (((151 / 374) * 100vw) * 2));
    }
  }
}