/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/
//Этот блок переменных не используется
$spacer-smaller: calc((10 / 375) * 100vw);
$spacer-small: 10;
$spacer-medium: 20;
$spacer-xs-large: calc((30 / 1440) * 100vw);
$spacer-large: 30;
$spacer-x-large: calc((30 / 1920) * 100vw);

$grid: (
  x-small: (
    from: 0,
    to: 374,
    spacer: calc((10 / 374) * 100vw)
  ),
  small: (
    from: 375,
    to: 568,
    spacer: 10px
  ),
  s-medium: (
    from: 569,
    to: 840,
    spacer: 20px
  ),
  medium: (
    from: 841,
    to: 1024,
    spacer: 20px
  ),
  xs-large: (
    from: 1025,
    to: 1440,
    spacer: calc((30 / 1440) * 100vw)
  ),
  s-large: (
    from: 1441,
    to: 1600,
    spacer: 30px
  ),
  large: (
    from: 1601,
    to: 1920,
    spacer: 30px
  ),
  x-large: (
    from: 1921,
    spacer: calc((30 / 1920) * 100vw)
  )
);

:root {
  --large-indent: calc(#{$spacer-large} * 1px);
  --medium-indent: calc(#{$spacer-medium} * 1px);
  --small-indent: calc(#{$spacer-small} * 1px);
  --large-min-width: 1025;

  --default-bezier: cubic-bezier(.25, .1, .25, 1);
  --easy-out: cubic-bezier(0, 0, .58, 1);
  --default-transition-time: .4s;

  --max-row-width: 100vw;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Inter", sans-serif;
  --h-font: "RClass Pragmatica", sans-serif;

  --t: transparent;

  --alert-color: #b50000;
  --default-transition: all var(--default-timing-function) .4s;
  --default-timing-function: cubic-bezier(.25, .1, .25, 1);
  --default-timing-function-invert: cubic-bezier(.75, 0, .75, .9);
  --custom-ease-in-ease-out: cubic-bezier(1, .07, .2, .98);
  --border-timing-function: cubic-bezier(.075, .82, .165, 1);

  //принцип использования цветов см в _layout.scss: у нас там темы меняются
  --primary-color: #000000;                           // черный
  --primary-color-40: rgba(0, 0, 0, .4);              // черный прозрачный 40
  --light-color: #FFFFFF;                             // белый
  --light-color-40: rgba(255, 255, 255, .4);          // белый прозрачный 40

  --blue-color: #37AEE2;
  --purple-color: #7F4DA0;
  --green-color: #25D366;
}
