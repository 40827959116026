.article-page {

}

@include respond-up('x-large') {
  .article-page {
    padding-bottom: calc((200 / 1920) * 100vw);

    &__main {
      margin-top: calc((100 / 1920) * 100vw);
    }

    &__read-also {
      margin-top: calc((200 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .article-page {
    padding-bottom: 200px;

    &__main {
      margin-top: 100px;
    }

    &__read-also {
      margin-top: 200px;
    }
  }
}

@include respond('xs-large') {
  .article-page {
    padding-bottom: calc((200 / 1440) * 100vw);

    &__main {
      margin-top: calc((100 / 1440) * 100vw);
    }

    &__read-also {
      margin-top: calc((200 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .article-page {

  }
}

@include respond('medium') {
  .article-page {

  }
}

@include respond-down('medium') {
  .article-page {
    padding-bottom: 120px;

    &__main {
      margin-top: 70px;
    }

    &__read-also {
      margin-top: 120px;
    }
  }
}

@include respond('small') {
  .article-page {
    padding-bottom: 60px;

    &__main {
      margin-top: 50px;
    }

    &__read-also {
      margin-top: 60px;
    }
  }
}

@include respond('x-small') {
  .article-page {
    padding-bottom: calc((60 / 374) * 100vw);

    &__main {
      margin-top: calc((50 / 374) * 100vw);
    }

    &__read-also {
      margin-top: calc((60 / 374) * 100vw);
    }
  }
}