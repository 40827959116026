.services-page {

}

@include respond-up('x-large') {
  .services-page {
    padding: 0 0 calc((200 / 1920) * 100vw);
  }
}

@include respond-between('s-large', 'large') {
  .services-page {
    padding: 0 0 200px;
  }
}

@include respond('xs-large') {
  .services-page {
    padding: 0 0 calc((200 / 1440) * 100vw);
  }
}

@include respond-between('s-medium', 'medium') {
  .services-page {
    padding: 0 0 120px;
  }
}

@include respond('medium') {
  .services-page {

  }
}

@include respond-down('medium') {
  .services-page {

  }
}

@include respond('small') {
  .services-page {
    padding: 36px 0 60px;
  }
}

@include respond('x-small') {
  .services-page {
    padding: 0 0 calc((60 / 374) * 100vw);
  }
}