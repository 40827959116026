.text-repetition-logo {
  position: relative;
  width: 100%;

  &__item {
    width: 100%;
    transition: background-color .4s var(--default-timing-function);
    background-color: var(--background-color);

    svg {
      path {
        stroke: var(--border-color);
        transition: stroke .4s var(--default-timing-function);
      }
    }

    &:not(&_main) {
      position: absolute;
      top: 0;
      left: 0;
    }

    &_main {
      position: relative;
      z-index: 10;
    }

    svg {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1050;
    }
  }
}

@include respond-up('large') {
  .text-repetition-logo {

  }
}

@include respond-up('medium') {
  .text-repetition-logo {

  }
}

@include respond('medium') {
  .text-repetition-logo {

  }
}

@include respond-down('medium') {
  .text-repetition-logo {

  }
}

@include respond-down('small') {
  .text-repetition-logo {

  }
}