.contacts-recall-form {

}

@include respond-up('s-medium') {
  .contacts-recall-form {
    .recall-form__button {
      padding: 43px 60px;
    }
  }
}

@include respond-down('small') {
  .contacts-recall-form {
    .recall-form__button {
      padding: 23px 30px;
    }
  }
}