.index-page-case-card {
  display: block;

  &__picture-wrap {
    display: flex;
    width: 100%;
    overflow: hidden;
    transition: opacity .7s var(--default-timing-function);
  }

  &__picture,
  &__video {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__video {
    transition: transform .7s var(--default-timing-function);
    object-fit: cover;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .7s var(--default-timing-function);
  }

  &__icon {
    display: flex;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__subtitle {
    display: block;
    color: var(--translucency-color);
    transition: color .4s, opacity .7s .2s;
    transition-timing-function: var(--default-timing-function);
  }

  &__title {
    display: block;
    overflow: hidden;
  }

  &__title-inner {
    display: block;
    transition: transform .7s var(--default-timing-function);
  }

  &.need-animation {
    border-color: var(--t);

    .index-page-case-card {
      &__title-inner {
        transform: translate3d(0, 100%, 0);
      }

      &__picture-wrap,
      &__subtitle {
        opacity: 0;
      }

      &__video,
      &__image {
        transform: scale(1.05);
      }
    }
  }

  &__categories-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__bottom {
    display: grid;
  }

  &__image-wrap {
    position: relative;
  }

  &__case-title {
    position: absolute;

    &.text__upper-medium-title {
      color: #fff;
    }
  }
}

@include respond-up('x-large') {
  .index-page-case-card {
    &__bottom {
      margin-top: calc((20 / 1920) * 100vw);
      padding-bottom: calc((30 / 1920) * 100vw);
    }

    &__subtitle {
      margin-top: calc((10 / 1920) * 100vw);
    }

    &__icon {
      svg {
        width: calc((25 / 1920) * 100vw);
        height: calc((25 / 1920) * 100vw);
      }
    }

    &__case-link {
      right: calc((30 / 1920) * 100vw);
      bottom: calc((30 / 1920) * 100vw);
    }

    &__case-title {
      top: calc((30 / 1920) * 100vw);
      left: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .index-page-case-card {
    &__bottom {
      margin-top: 20px;
      padding-bottom: 30px;
    }

    &__subtitle {
      margin-top: 10px;
    }

    &__case-link {
      right: 30px;
      bottom: 30px;
    }

    &__case-title {
      top: 30px;
      left: 30px;
    }
  }
}

@include respond-down('large') {
  .index-page-case-card {
    &__icon {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .index-page-case-card {
    &:hover {
      .index-page-case-card {
        &__video,
        &__image {
          transform: scale(1.05);
        }
      }
    }

    &__bottom {
      &:hover {
        .index-page-case-card {
          &__subtitle {
            color: var(--text-color);
          }
        }
      }
    }

    &__picture-wrap {
      height: calc(var(--grid-column2) * (557 / 915));
    }

    &__case-link {
      position: absolute;
    }
  }
}

@include respond('xs-large') {
  .index-page-case-card {
    &__bottom {
      margin-top: calc((20 / 1440) * 100vw);
      padding-bottom: calc((30 / 1440) * 100vw);
    }

    &__subtitle {
      margin-top: calc((10 / 1440) * 100vw);
    }

    &__icon {
      svg {
        width: calc((25 / 1440) * 100vw);
        height: calc((25 / 1440) * 100vw);
      }
    }

    &__case-link {
      right: calc((30 / 1440) * 100vw);
      bottom: calc((30 / 1440) * 100vw);
    }

    &__case-title {
      top: calc((30 / 1440) * 100vw);
      left: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .index-page-case-card {
    &__case-title {
      top: 20px;
      left: 20px;
    }
  }
}

@include respond-up('s-medium') {
  .index-page-case-card {
    &__bottom {
      grid-template-areas: 'title link'
                           'categories categories';
    }

    &__title-wrap {
      grid-area: title;
    }

    &__categories-list {
      grid-area: categories;
    }

    &__outer-link-wrap {
      grid-area: link;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include respond-down('medium') {
  .index-page-case-card {
    &__bottom {
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 10px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column4) * (483 / 794));
    }

    &__case-link {
      display: none;
    }
  }
}

@include respond('small') {
  .index-page-case-card {
    &__bottom {
      margin-top: 10px;
      padding-bottom: 40px;
    }

    &__icon {
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
}

@include respond-down('small') {
  .index-page-case-card {
    &__picture-wrap {
      height: calc(var(--grid-column2) * (215 / 354));
    }

    &__outer-link-wrap {
      margin-top: 20px;
    }

    &__case-title {
      top: 10px;
      left: 10px;
    }
  }
}

@include respond('x-small') {
  .index-page-case-card {
    &__bottom {
      margin-top: calc((10 / 374) * 100vw);
      padding-bottom: calc((40 / 374) * 100vw);
    }

    &__subtitle {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__icon {
      svg {
        width: calc((17 / 374) * 100vw);
        height: calc((17 / 374) * 100vw);
      }
    }

    &__outer-link-wrap {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__case-title {
      top: calc((10 / 374) * 100vw);
      left: calc((10 / 374) * 100vw);
    }
  }
}