.contacts-map-block {
  &__map-wrap {
    filter: var(--filter-color);
    transition: filter .4s var(--default-timing-function);
  }

  &__socials-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__socials-list {
    grid-row-gap: 0;
    border-top: unset;
    width: 100%;
  }

  &__item {
    &:nth-child(-n+2) {
      .contacts-map-block__link {
        border-top: 2px solid var(--border-color);
      }
    }
  }

  &__link {
    width: 100%;
  }

  &__requisites-wrap {
    display: flex;
    flex-direction: column;
    width: max-content;
  }

  &__requisites-icon {
    display: flex;

    svg {
      path {
        stroke: var(--border-color);
        transition: stroke .4s var(--default-timing-function);
      }
    }
  }

  &__requisites-link {
    display: flex;
    flex-direction: column;
  }

  &__link-title {
    .animation-item__inner {
      display: block;
    }
  }
}

@include respond-up('x-large') {
  .contacts-map-block {
    &__map {
      height: calc((754 / 1920) * 100vw);
    }

    &__requisites-link-text {
      margin-top: calc((20 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .contacts-map-block {
    &__map {
      height: 754px;
    }

    &__requisites-link-text {
      margin-top: 20px;
    }
  }
}

@include respond-up('xs-large') {
  .contacts-map-block {
    &__title {
      grid-column: 1/2;
    }

    &__socials-wrap {
      grid-column: 1/3;
    }

    &__map-wrap {
      grid-column: 3/5;
    }

    &__link {
      transition: border-top-color .4s var(--default-timing-function);
    }

    &__socials-list {
      &:hover {
        .contacts-map-block {
          &__link {
            border-top-color: var(--translucency-color);
          }

          &__link-title {
            color: var(--translucency-color);
          }
        }

        .button_arrow-diagonal {
          &::before {
            border-bottom-color: var(--translucency-color);
          }
        }
      }
    }

    &__item-border {
      background-color: var(--border-color);
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transition: transform var(--default-timing-function);
      transition-duration: 0s;
      transform-origin: center right;
      will-change: transform;
      animation-fill-mode: forwards;
    }

    &__item {
      &._hovered,
      &._anim-in-playing {
        .contacts-map-block {
          &__item-border {
            transform: scaleX(1);
            transition-duration: .4s;
            transform-origin: left center;
          }

          &__link-title {
            color: var(--text-color);
          }
        }
      }

      &._anim-out-playing {
        .contacts-map-block__item-border {
          transform: scaleX(0);
          transition-duration: 1s;
          transition-delay: .4s;
        }
      }
    }
  }
}

@include respond('xs-large') {
  .contacts-map-block {
    &__map {
      height: calc((754 / 1440) * 100vw);
    }

    &__requisites-link-text {
      margin-top: calc((20 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .contacts-map-block {

  }
}

@include respond-down('medium') {
  .contacts-map-block {
    grid-row-gap: 0;

    &__title,
    &__socials-wrap,
    &__map-wrap {
      grid-column: 1/5;
    }

    &__map-wrap {
      grid-row: 1/2;
    }

    &__map {
      height: 640px;
    }

    &__title {
      grid-row: 2/3;
      margin-top: 120px;
    }

    &__socials-wrap {
      grid-row: 3/4;
      margin-top: 20px;
    }

    &__requisites-link-text {
      margin-top: 20px;
    }

    &__requisites-wrap {
      margin-top: 120px;
    }
  }
}

@include respond-up('s-medium') {
  .contacts-map-block {
    &__socials-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond-down('small') {
  .contacts-map-block {
    &__socials-list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:nth-child(2) {
        .contacts-map-block__link {
          border-top: unset;
        }
      }
    }

    &__title,
    &__socials-wrap,
    &__map-wrap {
      grid-column: 1/3;
    }

    &__title {
      margin-top: 60px;
    }

    &__socials-wrap {
      margin-top: 10px;
    }

    &__map {
      height: 400px;
    }

    &__requisites-wrap {
      margin-top: 60px;
    }

    &__requisites-link-text {
      margin-top: 10px;
    }
  }
}

@include respond('x-small') {
  .contacts-map-block {
    &__map {
      height: calc((400 / 374) * 100vw);
    }

    &__title {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__socials-wrap {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__requisites-link-text {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__requisites-wrap {
      margin-top: calc((60 / 374) * 100vw);
    }
  }
}