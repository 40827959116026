.recall-form {
  &__action {
    display: flex;
    justify-content: center;
  }

  &__action-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button-wrap {
    margin-top: var(--button-margin-top);
  }

  &__policy-wrap {
    margin-top: var(--policy-margin-top);
    display: flex;
    justify-content: center;
  }

  .policy {
    text-align: center;
  }

  &__button {
    width: 100%;
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__success-title,
  &__success-subtitle {
    text-align: center;
  }
}

@include respond-up('x-large') {
  .recall-form {
    --file-form-field-padding: calc((22 / 1920) * 100vw);
    --button-margin-top: calc((28 / 1920) * 100vw);
    --policy-margin-top: calc((38 / 1920) * 100vw);

    &__fields {
      grid-row-gap: calc((45 / 1920) * 100vw);
    }

    &__action {
      margin-top: calc((70 / 1920) * 100vw);
    }

    &__action-inner {
      max-width: calc((439 / 1920) * 100vw);
    }

    &__success-subtitle {
      margin-top: calc((50 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .recall-form {
    --file-form-field-padding: 22px;
    --button-margin-top: 28px;
    --policy-margin-top: 38px;

    &__fields {
      grid-row-gap: 45px;
    }

    &__action {
      margin-top: 70px;
    }

    &__action-inner {
      max-width: 439px;
    }

    &__success-subtitle {
      margin-top: 50px;
    }
  }
}

@include respond-up('xs-large') {
  .recall-form {
    &__field {
      &:nth-child(1) {
        grid-column: 1/3;
      }

      &:nth-child(2) {
        grid-column: 3/5;
      }

      &:nth-child(3) {
        grid-column: 1/5;
      }
    }
  }
}

@include respond('xs-large') {
  .recall-form {
    --file-form-field-padding: calc((22 / 1440) * 100vw);
    --button-margin-top: calc((28 / 1440) * 100vw);
    --policy-margin-top: calc((38 / 1440) * 100vw);

    &__fields {
      grid-row-gap: calc((45 / 1440) * 100vw);
    }

    &__action {
      margin-top: calc((70 / 1440) * 100vw);
    }

    &__action-inner {
      max-width: calc((439 / 1440) * 100vw);
    }

    &__success-subtitle {
      margin-top: calc((50 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    --file-form-field-padding: 20px;
    --button-margin-top: 20px;
    --policy-margin-top: 20px;
    width: var(--grid-column4);

    &__field {
      grid-column: 1/5;
    }

    &__action {
      margin-top: 60px;
    }

    &__success-subtitle {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .recall-form {
    --file-form-field-padding: 10px;
    --button-margin-top: 20px;
    --policy-margin-top: 10px;
    width: var(--grid-column2);

    &__fields {
      grid-row-gap: 25px;
    }

    &__field {
      grid-column: 1/3;
    }

    &__action {
      margin-top: 30px;
    }

    &__success-subtitle {
      margin-top: 20px;
    }
  }
}

@include respond('x-small') {
  .recall-form {
    --file-form-field-padding: calc((10 / 375) * 100vw);
    --button-margin-top: calc((20 / 375) * 100vw);
    --policy-margin-top: calc((10 / 375) * 100vw);

    &__fields {
      grid-row-gap: calc((25 / 374) * 100vw);
    }

    &__action {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__success-subtitle {
      margin-top: calc((20 / 374) * 100vw);
    }
  }
}