.policy {
  color: var(--translucency-color);

  &__link {
    white-space: nowrap;
    transition: color .4s var(--default-timing-function);

    &::before {
      border-bottom-color: var(--translucency-color);
    }
  }
}