.follower {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 1000100;

  &__button {
    width: fit-content;
    height: fit-content;
    top: 0;
    left: 0;
    pointer-events: none;
    transform: scale(0);
    transition: transform .4s;
    //mix-blend-mode: difference;

    &.button {
      position: absolute;
    }
  }
}

@include respond-down('medium') {
  .follower {
    display: none;
  }
}