.content-header {
  &__title-text {
    display: block;
    transition: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: var(--default-timing-function);
    transform: translate3d(0, 0, 0);

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        z-index: calc(15 - #{$i});
        transition-delay: calc(#{$i} * .2s);
      }
    }
  }

  &__description {
    transition: color .4s 0s, opacity .7s .7s;
    transition-timing-function: var(--default-timing-function);
  }

  &__link {
    transition: color .4s 0s, opacity .7s .7s;
    transition-timing-function: var(--default-timing-function);
  }

  &__date {
    overflow: hidden;
  }

  &__day {
    transition: transform .7s var(--default-timing-function) .7s;
  }

  &__month-year {
    transition: opacity .7s var(--default-timing-function) .7s;
  }
}

@include respond-up('x-large') {
  .content-header {
    padding: calc((130 / 1920) * 100vw) 0 calc((160 / 1920) * 100vw);

    &_index,
    &_blog {
      padding-bottom: calc((105 / 1920) * 100vw);
    }

    &_contacts {
      padding-bottom: calc((112 / 1920) * 100vw);
    }

    &_article-page {
      padding: calc((50 / 1920) * 100vw) 0;
    }
  }
}

@include respond-down('large') {
  .content-header {
    padding: 130px 0 160px;

    &_index,
    &_blog {
      padding-bottom: 105px;
    }

    &_contacts {
      padding-bottom: 112px;
    }

    &_article-page {
      padding: 50px 0;
    }
  }
}

@include respond-up('s-large') {
  .content-header {

  }
}

@include respond-up('xs-large') {
  .content-header {
    &_error-page {
      padding: 0;
      height: calc(100vh - var(--header-height));
      transform: translateY(calc(0px - var(--header-height)));
    }

    &__play-button {
      display: flex;
      color: var(--translucency-color);
      align-items: center;
      font-size: 18px;
      opacity: 1;
      transition: opacity var(--default-timing-function) 0.8s;

      &._hidden {
        opacity: 0;
      }
    }

    &__play-button-arrow {
      width: 30px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      border: 1px solid var(--border-color);
      margin: 0 9px;

      svg {
        line, path {
          stroke: var(--border-color);
        }
      }
    }

    &__game-wrap {
      grid-column: 1/5;
      height: 100%;
      position: relative;
    }
  }
}

@include respond('xs-large') {
  .content-header {
    padding: calc((130 / 1440) * 100vw) 0 calc((160 / 1440) * 100vw);

    &_error-page {
      padding: 0;
    }

    &_index,
    &_blog {
      padding-bottom: calc((105 / 1440) * 100vw);
    }

    &_contacts {
      padding-bottom: calc((112 / 1440) * 100vw);
    }

    &_article-page {
      padding: calc((50 / 1440) * 100vw) 0;
    }
  }
}

@include respond('medium') {
  .content-header {

  }
}

@include respond-down('medium') {
  .content-header {
    padding: 100px 0;

    &__play-button,
    &__game-wrap {
      display: none;
    }

    &_case {
      padding-bottom: 20px;
    }

    &_contacts {
      padding-bottom: 54px;
    }

    &_article-page {
      padding: 45px 0 40px;
    }
  }
}

@include respond-up('s-medium') {
  .content-header {

  }
}

@include respond('s-medium') {
  .content-header {

  }
}

@include respond('small') {
  .content-header {
    padding: 36px 0 60px;

    &_case {
      padding-bottom: 10px;
    }

    &_contacts {
      padding-bottom: 42px;
    }

    &_blog {
      padding-bottom: 40px;
    }

    &_article-page {
      padding: 16px 0 30px;
    }
  }
}

@include respond('x-small') {
  .content-header {
    padding: calc((36 / 375) * 100vw) 0 calc((60 / 375) * 100vw);

    &_case {
      padding-bottom: calc((10 / 375) * 100vw);
    }

    &_contacts {
      padding-bottom: calc((42 / 374) * 100vw);
    }

    &_blog {
      padding-bottom: calc((40 / 374) * 100vw);
    }

    &_article-page {
      padding: calc((16 / 374) * 100vw) 0 calc((30 / 374) * 100vw);
    }
  }
}
