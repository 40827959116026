%text-item {
  transition: color .4s var(--default-timing-function);
  color: var(--text-color);
  font-family: var(--font);
  font-style: normal;
  font-weight: 600;
  font-size: var(--item-size);
  line-height: 140%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

@include respond-up('x-large') {
  %text-item {
    --item-size: calc((18 / 1920) * 100vw);
  }
}

@include respond('large') {
  %text-item {
    --item-size: 18px;
  }
}

@include respond('s-large') {
  %text-item {
    --item-size: 16px;
  }
}

@include respond('xs-large') {
  %text-item {
    --item-size: calc((16 / 1440) * 100vw);
  }
}

@include respond-up('medium') {
  %text-item {

  }
}

@include respond('medium') {
  %text-item {
    --item-size: 18px;
  }
}

@include respond-down('s-medium') {
  %text-item {
    --item-size: 16px;
  }
}

@include respond-down('medium') {
  %text-item {

  }
}

@include respond('x-small') {
  %text-item {
    --item-size: calc((16 / 374) * 100vw);
  }
}