.business-minded {
  align-items: center;

  .who-we-are-item__icon-inner {
    position: relative;
    overflow: hidden;
  }

  svg {
    height: auto !important;

    #side-top {
      stroke-dasharray: 135;
      animation: business-minded-side-line 2.1s var(--default-timing-function-invert) reverse infinite;
    }

    #side-right {
      stroke-dasharray: 135;
      animation: business-minded-side-line 2.1s var(--default-timing-function-invert) reverse infinite;
    }

    #middle {
      stroke-dasharray: 185;
      animation: business-minded-middle-line 2.1s var(--default-timing-function) infinite;
    }
  }
}

@keyframes business-minded-side-line {
  from {
    stroke-dashoffset: 135;
  }
  33% {
    stroke-dashoffset: 270;
  }
  66% {
    stroke-dashoffset: 135;
  }
  to {
    stroke-dashoffset: 135;
  }
}

@keyframes business-minded-middle-line {
  from {
    stroke-dashoffset: 555;
  }
  33% {
    stroke-dashoffset: 370;
  }
  66% {
    stroke-dashoffset: 370;
  }
  to {
    stroke-dashoffset: 185;
  }
}