%text-main {
  transition: color .4s var(--default-timing-function);
  color: var(--text-color);
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.03em;
  font-family: var(--font);
  line-height: 140%;
  font-size: var(--main-size);
}

@include respond-up('x-large') {
  %text-main {
    --main-size: calc((20 / 1920) * 100vw);
  }
}

@include respond('large') {
  %text-main {
    --main-size: 20px;
  }
}

@include respond('s-large') {
  %text-main {
    --main-size: 18px;
  }
}

@include respond('xs-large') {
  %text-main {
    --main-size: calc((18 / 1440) * 100vw);
  }
}

@include respond('medium') {
  %text-main {
    --main-size: 20px;
  }
}

@include respond('s-medium') {
  %text-main {
    --main-size: 18px;
  }
}

@include respond('small') {
  %text-main {
    --main-size: 18px;
  }
}

@include respond('x-small') {
  %text-main {
    --main-size: calc((18 / 375) * 100vw);
  }
}