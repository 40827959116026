.who-we-are-item {
  display: grid;
  grid-column-gap: var(--grid-gap);
  border-top: 2px solid var(--border-color);
  border-left: 2px solid var(--border-color);
  transition: border-color .4s var(--default-timing-function);

  &__icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;

      line,
      path,
      circle {
        stroke: var(--border-color);
        transition: stroke .4s var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .who-we-are-item {
    padding-top: calc((20 / 1920) * 100vw);
    padding-left: calc((30 / 1920) * 100vw);

    &__description {
      margin-top: calc((90 / 1920) * 100vw);
    }

    &__icon {
      width: calc((268 / 1920) * 100vw);
      height: calc((153 / 1920) * 100vw);
      margin-top: calc((65 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .who-we-are-item {
    padding-top: 20px;
    padding-left: 30px;

    &__description {
      margin-top: 90px;
    }

    &__icon {
      width: 268px;
      height: 153px;
      margin-top: 65px;
    }
  }
}

@include respond('xs-large') {
  .who-we-are-item {
    padding-top: calc((20 / 1440) * 100vw);
    padding-left: calc((30 / 1440) * 100vw);

    &__description {
      margin-top: calc((90 / 1440) * 100vw);
    }

    &__icon {
      width: calc((268 / 1440) * 100vw);
      height: calc((153 / 1440) * 100vw);
      margin-top: calc((65 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .who-we-are-item {
    &__description {
      margin-top: 60px;
    }

    &__icon {
      width: 220px;
      height: 126px;
      margin-top: 53px;
    }
  }
}

@include respond-down('medium') {
  .who-we-are-item {
    padding-top: 15px;
    padding-left: 20px;
  }
}

@include respond-up('s-medium') {
  .who-we-are-item {
    grid-template-columns: repeat(2, 1fr);

    &__title {
      grid-column: 1/2;
    }

    &__description {
      grid-column: 2/3;
    }

    &__icon {
      grid-column: 2/3;
    }
  }
}

@include respond('small') {
  .who-we-are-item {
    &__description {
      margin-top: 40px;
    }

    &__icon {
      width: 170px;
      height: 97px;
      margin-top: 40px;
    }
  }
}

@include respond('x-small') {
  .who-we-are-item {
    padding-top: calc((15 / 374) * 100vw);
    padding-left: calc((20 / 374) * 100vw);

    &__description {
      margin-top: calc((40 / 374) * 100vw);
    }

    &__icon {
      width: calc((170 / 374) * 100vw);
      height: calc((97 / 374) * 100vw);
      margin-top: calc((40 / 374) * 100vw);
    }
  }
}