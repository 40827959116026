.arrow {
  display: flex;

  &:before {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: var(--border-color);
      transition: stroke .4s var(--default-timing-function);
    }
  }

  &_left {
    svg {
      transform: rotate(180deg);
    }
  }
}

@include respond-up('x-large') {
  .arrow {
    padding: calc((16 / 1920) * 100vw);
    margin: calc((16 / 1920) * 100vw * -1);

    &__icon {
      width: calc((38 / 1920) * 100vw);
      height: calc((38 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .arrow {
    padding: 16px;
    margin: -16px;

    &__icon {
      width: 38px;
      height: 38px;
    }
  }
}

@include respond('xs-large') {
  .arrow {
    padding: calc((16 / 1440) * 100vw);
    margin: calc((16 / 1440) * 100vw * -1);

    &__icon {
      width: calc((38 / 1440) * 100vw);
      height: calc((38 / 1440) * 100vw);
    }
  }
}

@include respond('small') {
  .arrow {
    &__icon {
      width: 34px;
      height: 34px;
    }
  }
}

@include respond('x-small') {
  .arrow {
    padding: calc((16 / 374) * 100vw);
    margin: calc((16 / 374) * 100vw * -1);

    &__icon {
      width: calc((34 / 374) * 100vw);
      height: calc((34 / 374) * 100vw);
    }
  }
}