.breadcrumbs {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: block;
  }

  &__item_delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }
}

@include respond-up("large") {
  .breadcrumbs {
    margin-top: 50px;

    &__list {
      margin: -4px;
    }

    &__item {
      padding: 4px;
    }

    &__link {
      transition: color .4s;
      padding: 4px;
      margin: -4px;

      &:hover {
      }
    }
  }
}

@include respond("s-medium") {
  .breadcrumbs {
    margin-top: 33px;
  }
}

@include respond-down("s-medium") {
  .breadcrumbs {
    &__list {
      margin: -3px;
    }

    &__item {
      padding: 3px;

      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__link {
      padding: 3px;
      margin: -3px;
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
  }
}
