a.button, button {
  cursor: pointer;
}

.button, button {
  margin: 0;
  text-align: center;
  background-color: var(--t);
  display: inline-flex;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;

  &__text {
    display: flex;
    will-change: transform;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__icon {
    display: flex;
    height: max-content;

    svg {
      width: var(--icon-width);
      height: var(--icon-height);
    }
  }

  &_primary {
    border: 2px solid var(--border-color);
    transition: border-color .4s;
    transition-timing-function: var(--default-timing-function);
    border-radius: 100px;

    .button__text {
      @extend %text-item;
    }
  }

  &_line-through {
    .button__text {
      @extend %text-item;
    }
  }

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &_bordered-item {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      border-bottom: 2px solid var(--border-color);
    }
  }

  &_arrow-diagonal {
    align-items: center;
    justify-content: space-between;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      border-bottom: 2px solid var(--border-color);
    }

    .button__icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);

      svg path {
        stroke: var(--border-color);
      }
    }
  }

  &_arrow,
  &_arrow-down,
  &_message-box {
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    &::before {
      border-bottom: 2px dashed var(--translucency-color);
      transition: border-bottom-color .4s var(--default-timing-function);
      z-index: 1;
    }

    &::after {
      z-index: 2;
      border-bottom: 2px solid var(--border-color);
      opacity: 0;
      transition: opacity .4s var(--default-timing-function);
    }

    .button__icon {
      height: auto;
    }
  }

  &_message-box {
    svg {
      path {
        transition: stroke .4s, fill .4s;
        transition-timing-function: var(--default-timing-function);
        stroke: var(--text-color);
        fill: var(--text-color);
      }
    }
  }

  &_arrow-down {
    .button__icon {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &_bordered {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      transition: border-bottom-color var(--default-timing-function) .4s;
      border-bottom: 2px solid var(--border-color);
    }
  }

  &_hovered {
    .button__text {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        transition: border-bottom-color var(--default-timing-function) .4s;
        border-bottom: 2px solid var(--border-color);
      }
    }
  }

  &_case {
    background-color: var(--background-color);
    padding: 14px 30px;
    border-radius: 100px;
    transition: background .4s;
    transition-timing-function: var(--default-timing-function);

    .button {
      &__text {
        color: var(--text-color);
      }
    }
  }

  &__case-card-category {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--translucency-color);
    transition: color var(--default-transition-time) var(--default-timing-function);
  }

  &__case-card-category,
  &__case-link-text {
    font-family: var(--font);
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.03em;
    text-align: left;

    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__case-link {
    align-items: center;
    column-gap: 7px;
  }

  &__case-link-text {
    color: var(--text-color);
    transition: color var(--default-transition-time) var(--default-timing-function);
    position: relative;
  }

  &__case-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--text-color);
        transition: stroke var(--default-transition-time) var(--default-timing-function);
      }
    }
  }
}

@include respond-up('x-large') {
  .button, button {
    &_arrow-diagonal {
      --icon-width: calc((25 / 1920) * 100vw);
      --icon-height: calc((25 / 1920) * 100vw);
      padding: calc((17 / 1920) * 100vw) 0 calc((19 / 1920) * 100vw);
    }

    &_arrow,
    &_arrow-down,
    &_message-box {
      padding-bottom: calc((15 / 1920) * 100vw);

      .button__icon {
        margin-left: calc((15 / 1920) * 100vw);
      }
    }

    &_primary {
      padding: calc((20 / 1920) * 100vw) calc((60 / 1920) * 100vw);
    }

    &__case-card-category,
    &__case-link-text {
      font-size: calc((20 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .button, button {
    &_arrow-diagonal {
      --icon-width: 25px;
      --icon-height: 25px;
      padding: 17px 0 19px;
    }

    &_arrow,
    &_arrow-down,
    &_message-box {
      padding-bottom: 15px;

      .button__icon {
        margin-left: 15px;
      }
    }

    &_primary {
      padding: 20px 60px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .button, button {
    &__case-card-category,
    &__case-link-text {
      font-size: 20px;
    }
  }
}

@include respond-up('s-large') {
  .button, button {
    &_message-box {
      .button__icon {
        padding: calc((17 / 1920) * 100vw) 0 calc((11 / 1920) * 100vw);
      }
    }
  }
}

@include respond-up('xs-large') {
  .button, button {
    &_arrow-diagonal,
    &_bordered-item {
      &:hover {
        .button__icon {
          opacity: 1;
        }
      }

      &::before {
        transition: border-bottom-color var(--default-timing-function) .4s;
      }

      .button__icon {
        opacity: 0;
        transition: opacity var(--default-timing-function) .4s;
      }
    }

    &_arrow,
    &_arrow-down,
    &_message-box {
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    &_arrow,
    &_arrow-down {
      --icon-width: calc((38 / 1920) * 100vw);
      --icon-height: calc((38 / 1920) * 100vw);

      .button__icon {
        padding: calc((18 / 1920) * 100vw) 0 calc((10 / 1920) * 100vw);
      }
    }

    &_message-box {
      --icon-width: calc((38 / 1920) * 100vw);
      --icon-height: calc((38 / 1920) * 100vw);
    }

    &_bordered {
      &::before {
        transform: scaleX(1);
        transition: border-bottom-color .4s, transform .4s;
        transition-timing-function: var(--default-timing-function);
        transform-origin: center left;
      }

      &:hover {
        &::before {
          transform: scaleX(0);
          transform-origin: center right;
        }
      }
    }

    &_hovered {
      &:hover {
        .button__text {
          &::after {
            transform: scale(1);
            transform-origin: center left;
          }
        }
      }

      .button__text {
        &::after {
          transform: scaleX(0);
          transition: transform var(--default-transition-time) var(--default-bezier);
          transform-origin: center right;
        }
      }
    }

    &_primary {
      transition: border-color .4s, background-color .4s;

      &:hover {
        background-color: var(--opposite-background-color);

        .button__text {
          color: var(--opposite-text-color);
        }
      }
    }

    &_line-through {
      padding: 0 14px 0 15px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-bottom: 2px solid var(--border-color);
        transform: translate3d(0, -50%, 0) scaleX(0);
        transition: transform .4s var(--default-timing-function);
        transform-origin: center right;
      }

      &:hover {
        &::before {
          transform: translate3d(0, -50%, 0) scaleX(1);
          transform-origin: left center;
        }
      }
    }

    &_case {
      &:hover {
        background-color: var(--text-color);

        .button {
          &__text {
            color: var(--background-color);
          }
        }
      }
    }

    &__case-card-category {
      &:hover {
        color: var(--text-color);
      }
    }
  }
}

@include respond('xs-large') {
  .button, button {
    &_arrow-diagonal {
      --icon-width: calc((25 / 1440) * 100vw);
      --icon-height: calc((25 / 1440) * 100vw);
      padding: calc((17 / 1440) * 100vw) 0 calc((19 / 1440) * 100vw);
    }

    &_arrow,
    &_arrow-down,
    &_message-box {
      padding-bottom: calc((15 / 1440) * 100vw);

      .button__icon {
        margin-left: calc((15 / 1440) * 100vw);
      }
    }

    &_message-box {
      .button__icon {
        padding: calc((12 / 1440) * 100vw) 0 calc((10 / 1440) * 100vw);
      }
    }

    &_primary {
      padding: calc((20 / 1440) * 100vw) calc((60 / 1440) * 100vw);
    }

    &__case-card-category,
    &__case-link-text {
      font-size: calc((20 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .button, button {
    &_arrow,
    &_arrow-down,
    &_message-box {
      padding-bottom: 10px;

      .button__icon {
        margin-left: 10px;
        padding: calc((11 / 834) * 100vw) 0 calc((11 / 834) * 100vw);
      }
    }

    &_arrow,
    &_arrow-down,
    &_message-box {
      --icon-width: calc((26 / 834) * 100vw);
      --icon-height: calc((26 / 834) * 100vw);
    }

    &__case-card-category,
    &__case-link-text {
      font-size: 18px;
    }
  }
}

@include respond('small') {
  .button, button {
    &_arrow-diagonal {
      --icon-width: 17px;
      --icon-height: 17px;
      padding: 11px 0 11px;
    }

    &_primary {
      padding: 14px 30px;
    }
  }
}

@include respond-down('small') {
  .button, button {
    &_arrow,
    &_arrow-down,
    &_message-box {
      --icon-width: 20px;
      --icon-height: 20px;

      .button__icon {
        margin-left: 8px;
        padding: 4px 0 5px;
      }
    }
  }
}

@include respond('x-small') {
  .button, button {
    &_arrow-diagonal {
      --icon-width: calc((17 / 375) * 100vw);
      --icon-height: calc((17 / 375) * 100vw);
      padding: calc((11 / 375) * 100vw) 0 calc((11 / 375) * 100vw);
    }

    &_arrow,
    &_arrow-down,
    &_message-box {
      padding-bottom: calc((10 / 375) * 100vw);

      .button__icon {
        margin-left: calc((8 / 375) * 100vw);
      }
    }

    &_primary {
      padding: calc((14 / 374) * 100vw) calc((30 / 374) * 100vw);
    }

    &__case-card-category,
    &__case-link-text {
      font-size: calc((18 / 374) * 100vw);
    }
  }
}