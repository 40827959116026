.index-page {
  &__heading {
    display: flex;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--border-color);
      transition: background-color var(--default-timing-function) .4s;
    }
  }
}

@include respond-up('x-large') {
  .index-page {
    padding-bottom: calc((200 / 1920) * 100vw);

    &__heading {
      padding-bottom: calc((30 / 1920) * 100vw);
    }

    &__about {
      margin-top: calc((200 / 1920) * 100vw);
    }

    &__services-list {
      margin-top: calc((130 / 1920) * 100vw);
    }

    &__cases {
      margin-top: calc((200 / 1920) * 100vw);
    }

    &__info-block {
      margin-top: calc((200 / 1920) * 100vw);
    }

    &__map-block {
      margin-top: calc((28 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .index-page {
    padding-bottom: 200px;

    &__heading {
      padding-bottom: 30px;
    }

    &__about {
      margin-top: 200px;
    }

    &__services-list {
      margin-top: 130px;
    }

    &__cases {
      margin-top: 200px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .index-page {
    padding-bottom: 200px;

    &__info-block {
      margin-top: 200px;
    }

    &__map-block {
      margin-top: 28px;
    }
  }
}

@include respond('xs-large') {
  .index-page {
    padding-bottom: calc((200 / 1440) * 100vw);

    &__about {
      margin-top: calc((200 / 1440) * 100vw);
    }

    &__services-list {
      margin-top: calc((130 / 1440) * 100vw);
    }

    &__heading {
      padding-bottom: calc((30 / 1440) * 100vw);
    }

    &__cases {
      margin-top: calc((200 / 1440) * 100vw);
    }

    &__info-block {
      margin-top: calc((200 / 1440) * 100vw);
    }

    &__map-block {
      margin-top: calc((28 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium','medium') {
  .index-page {
    &__info-block {
      margin-top: 120px;
    }

    &__map-block {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {

  }
}

@include respond-down('medium') {
  .index-page {
    padding-bottom: 120px;

    &__about {
      margin-top: 120px;
    }

    &__services-list {
      margin-top: 80px;
    }

    &__heading {
      padding-bottom: 20px;
    }

    &__cases {
      margin-top: 100px;
    }
  }
}

@include respond('small') {
  .index-page {
    padding-bottom: 60px;

    &__heading {
      padding-bottom: 10px;
    }

    &__about {
      margin-top: 60px;
    }

    &__services-list {
      margin-top: 40px;
    }

    &__cases {
      margin-top: 60px;
    }
  }
}

@include respond-down('small') {
  .index-page {
    padding-bottom: 20px;

    &__info-block {
      margin-top: 60px;
    }

    &__map-block {
      margin-top: 30px;
    }
  }
}

@include respond('x-small') {
  .index-page {
    padding-bottom: calc((60 / 375) * 100vw);

    &__heading {
      padding-bottom: calc((10 / 375) * 100vw);
    }

    &__about {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__services-list {
      margin-top: calc((40 / 374) * 100vw);
    }

    &__cases {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__info-block {
      margin-top: calc((60 / 374) * 100vw);
    }

    &__map-block {
      margin-top: calc((30 / 374) * 100vw);
    }
  }
}