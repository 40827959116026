.text {
  &__custom-result-card-text,
  &__main,
  &__big-title,
  &__medium-title,
  &__upper-medium-title,
  &__item,
  &__main-mini{
    ::selection,
    &::selection{
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__custom-circle-text {
    @extend %custom-circle-text;
  }

  &__main {
    @extend %text-main;
  }

  &__main-mini {
    transition: color .4s var(--default-timing-function);
    color: #fff;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.03em;
    font-family: var(--font);
    line-height: 140%;
    font-size: 18px;
  }

  &__custom-result-card-text,
  &__big-title,
  &__medium-title,
  &__upper-medium-title {
    transition: color var(--default-timing-function) .4s;
    color: var(--text-color);
  }

  &__big-title {
    font-family: var(--h-font);
    font-size: var(--big-title-size);
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 110%;
    text-transform: uppercase;

    &_light {
      color: var(--light-color);
    }

    &_dark {
      color: var(--primary-color);
    }
  }

  &_grey {
    color: var(--light-color-40);
  }

  &_switch {
    color: var(--translucency-color);
  }

  &__custom-result-card-text,
  &__medium-title {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.03em;
    font-size: var(--medium-title-size);
  }

  &__upper-medium-title {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-size: var(--medium-title-size);
  }

  &__item {
    @extend %text-item;
  }

  &__underline {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--border-color);
      transition: background var(--default-timing-function) .4s;
    }
  }

  &__translucency {
    color: var(--translucency-color);
  }
}

@include respond-up('x-large') {
  .text {
    &__custom-result-card-text,
    &__medium-title,
    &__upper-medium-title {
      font-size: calc((40 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .text {
    &__custom-result-card-text,
    &__medium-title,
    &__upper-medium-title {
      font-size: 40px;
    }
  }
}

@include respond-up('xs-large') {
  .text {
    &__big-title {
      --big-title-size: calc((120 / 1920) * 100vw);

      &_mini {
        --big-title-size: calc((60 / 1920) * 100vw);
      }
    }
  }
}

@include respond('xs-large') {
  .text {
    &__custom-result-card-text,
    &__medium-title,
    &__upper-medium-title {
      --medium-title-size: calc((40 / 1440) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .text {
    &__big-title {
      --big-title-size: calc((80 / 834) * 100vw);

      &_mini {
        --big-title-size: calc((45 / 834) * 100vw);
      }
    }

    &__custom-result-card-text {
      --medium-title-size: 20px;
    }

    &__medium-title,
    &__upper-medium-title {
      --medium-title-size: 30px;
    }
  }
}

@include respond('small') {
  .text {
    &__medium-title,
    &__upper-medium-title {
      --medium-title-size: 23px;
    }
  }
}

@include respond-down('small') {
  .text {
    &__big-title {
      --big-title-size: calc((36 / 374) * 100vw);

      &_mini {
        --big-title-size: calc((26 / 374) * 100vw);
      }
    }
  }
}

@include respond('x-small') {
  .text {
    &__custom-result-card-text {
      --medium-title-size: calc((20 / 374) * 100vw);
    }

    &__medium-title,
    &__upper-medium-title {
      --medium-title-size: calc((23 / 374) * 100vw);
    }
  }
}