.pagination-endless {
  &__nav {
    display: flex;
    justify-content: center;
  }

  &__button {
    &_wide {
      &.button {
        width: 100%;
      }
    }
  }
}

@include respond-up('x-large') {
  .pagination-endless {
    &__button {
      margin-top: calc((80 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .pagination-endless {
    &__button {
      margin-top: 80px;
    }
  }
}

@include respond('xs-large') {
  .pagination-endless {
    &__button {
      margin-top: calc((80 / 1440) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .pagination-endless {

  }
}

@include respond-between('s-medium', 'medium') {
  .pagination-endless {
    &__button {
      margin-top: 60px;
    }
  }
}

@include respond-down('medium') {
  .pagination-endless {
  }
}

@include respond('small') {
  .pagination-endless {
    &__button {
      margin-top: 40px;
    }
  }
}

@include respond-down('x-small') {
  .pagination-endless {
    &__button {
      margin-top: calc((40 / 374) * 100vw);
    }
  }
}