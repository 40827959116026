.case-top-picture {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('xs-large') {
  .case-top-picture {
    height: calc((876 / 1920) * 100vw);
  }
}

@include respond-down('medium') {
  .case-top-picture {
    height: calc((374 / 834) * 100vw);
  }
}

@include respond-down('small') {
  .case-top-picture {
    height: calc((281 / 375) * 100vw);
  }
}