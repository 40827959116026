.header {
  transition: transform .4s, opacity .4s 1.5s;
  transition-timing-function: var(--default-timing-function);
  will-change: transform;
  pointer-events: none;
  opacity: 1;

  &__logo,
  &__nav-menu,
  &__phone,
  &__lang,
  &__hamburger {
    pointer-events: auto;
  }

  &__nav-menu,
  &__info {
    transition: opacity .4s var(--default-timing-function);
  }

  &__container {
    background-color: var(--background-color);
    transition: background-color .4s var(--default-timing-function);
    will-change: transform;
  }

  &__container-inner {
    position: relative;
    z-index: 1500;
  }

  &__inner {
    height: var(--header-height);
    position: relative;
  }

  &__logo,
  &__nav,
  &__info,
  &__phone {
    display: flex;
    align-items: center;
  }

  &__logo {
    grid-column: 1/2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--logo-svg-width);
    transition: width .5s 1.2s, height .5s 1.2s;
    transition-timing-function: var(--default-timing-function);
    will-change: width, height;
  }

  &__info {
    justify-content: flex-end;
    margin-right: var(--info-margin-right);
  }

  &__phone,
  &__lang {
    height: 100%;
  }

  &__hamburger.hamburger,
  &__drop-menu {
    display: none;
  }

  &._sticky {
    transform: translateY(-100%);

    //.header__container {
    //  background-color: var(--background-color);
    //}
  }

  &._sticky._scroll-up {
    transform: translateY(0);
  }
}

@include respond-up('x-large') {
  .header {
    --info-margin-right: calc((20 / 1920) * 100vw * -1);

    &__phone {
      padding: 0 calc((20 / 1920) * 100vw);
    }

    &__lang {
      .lang__link {
        padding: 0 calc((20 / 1920) * 100vw);
      }
    }
  }
}

@include respond-down('large') {
  .header {
    --info-margin-right: -20px;

    &__phone {
      padding: 0 20px;
    }

    &__lang {
      .lang__link {
        padding: 0 20px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .header {
    &__nav-menu {
      grid-column: 2/4;
    }
  }
}

@include respond('xs-large') {
  .header {
    --info-margin-right: calc((20 / 1440) * 100vw * -1);

    &__phone {
      padding: 0 calc((20 / 1440) * 100vw);
    }

    &__lang {
      .lang__link {
        padding: 0 calc((20 / 1440) * 100vw);
      }
    }
  }
}

@include respond-down('medium') {
  .header {
    --info-margin-right: calc(var(--grid-spacer) * -1);

    &__phone,
    &__nav-menu.header-nav-menu,
    &__lang.lang {
      display: none;
    }

    &__hamburger.hamburger {
      display: flex;
    }
  }
}

@include respond-up('s-medium') {
  .header {
    &__info {
      grid-column: 4/5;
    }
  }
}

@include respond-down('small') {
  .header {
    &__info {
      grid-column: 2/3;
    }
  }
}