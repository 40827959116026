.agency-team-block {
  &__add-person-text-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__person {
    display: flex;
    flex-direction: column;
  }

  &__add-person-wrapper {
    justify-content: center;
  }

  &__add-person {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 2px solid var(--text-color);
    transition: transform, border-color;
    transition-duration: .3s;
    transition-timing-function: var(--default-timing-function);
    border-radius: 50%;

    span {
      display: flex;
      text-align: center;
    }
  }

  &__info {
    grid-column: span 2;
    display: grid;
  }

  &__info-subtitle-wrap {
    display: flex;
  }

  &__info-title-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__position {
    color: var(--translucency-color);
  }
}

@include respond-up('x-large') {
  .agency-team-block {
    &__team {
      padding-top: calc(30 / 1920 * 100vw);
      grid-row-gap: calc(80 / 1920 * 100vw);
    }
  }
}

@include respond('large') {
  .agency-team-block {
    &__team {
      padding-top: 30px;
      grid-row-gap: 80px;
    }
  }
}

@include respond('s-large') {
  .agency-team-block {
    &__team {
      padding-top: 30px;
      grid-row-gap: 80px;
    }
  }
}

@include respond('xs-large') {
  .agency-team-block {
    &__team {
      padding-top: calc(30 / 1440 * 100vw);
      grid-row-gap: calc(80 / 1440 * 100vw);
    }

    &__position {
      padding-top: 5px;
    }
  }
}

@include respond-up('xs-large') {
  .agency-team-block {
    &__description {
      grid-column: 2/5;
    }

    &__person {
      grid-column: span 1;
    }

    &__video,
    &__add-person-wrapper {
      height: calc(var(--grid-column1) / 884 * 1006);
    }

    &__add-person-wrap {
      transition: width, height;
      transition-duration: 0.3s;
      transition-timing-function: var(--default-bezier);
    }

    &__add-person-wrapper {
      align-content: center;
      align-items: center;
      justify-content: center;

      &:hover {
        .agency-team-block {
          &__add-person-wrap {
            width: calc(.93 * var(--grid-column));
            height: calc(.93 * var(--grid-column));
          }
        }
      }
    }

    &__add-person-wrap {
      width: 100%;
      height: var(--grid-column1);
    }

    &__add-person {
      //height: var(--grid-column1);
      height: 100%;
    }

    &__info-subtitle-wrap {
      width: var(--grid-column);
    }
  }
}

@include respond('medium') {
  .agency-team-block {
    &__person {
      grid-column: span 2;
    }

    &__video,
    &__add-person-wrapper {
      height: calc(var(--grid-column2) / 884 * 1006);
    }

    &__team {
      padding-top: 20px;
      grid-row-gap: 40px;
    }

    &__description {
      grid-column: 1/5;
    }

    &__add-person-wrap,
    &__add-person {
      height: var(--grid-column2);
    }
  }
}

@include respond-down('medium') {
  .agency-team-block {
    &__text {
      display: none;
    }

    &__person {
      &_empty {
        display: none;
      }
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .agency-team-block {
    &__info {
      grid-column: span 4;
    }

    &__info-subtitle-wrap {
      padding-top: 128px;
      width: var(--grid-column2);
    }
  }
}

@include respond-up('s-medium') {
  .agency-team-block {
    &__description {
      text-indent: calc(var(--grid-column1) + var(--grid-gap));
    }

    &__person-name {
      padding-top: 20px;
    }

    &__info {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--grid-gap)
    }

    &__info-subtitle-wrap {
      align-items: flex-end;
    }

    &__info-title-wrap {
      align-items: flex-start;
    }
  }
}

@include respond('s-medium') {
  .agency-team-block {
    &__person {
      grid-column: span 2;
    }

    &__video,
    &__add-person-wrapper {
      height: calc(var(--grid-column2) / 884 * 1006);
    }

    &__team {
      padding-top: 20px;
      grid-row-gap: 40px;
    }

    &__description {
      grid-column: 1/5;
    }

    &__add-person {
      height: var(--grid-column2);
    }
  }
}

@include respond('small') {
  .agency-team-block {
  }
}

@include respond-down('small') {
  .agency-team-block {
    &__team {
      padding-top: 20px;
      grid-row-gap: 20px;
    }

    &__person {
      grid-column: span 1;
    }

    &__video,
    &__add-person-wrapper {
      height: calc(var(--grid-column1) / 884 * 1006);
    }

    &__add-person {
      height: var(--grid-column1);
    }

    &__person-name {
      padding-top: 10px;
    }

    &__description {
      grid-column: 1/3;
    }

    &__info {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
    }

    &__info-title-wrap {
      grid-row: 1/2;
    }

    &__info-subtitle-wrap {
      grid-row: 2/3;
    }
  }
}

@include respond-down('x-small') {
  .agency-team-block {
    &__team {
      padding-top: calc((20 / 375) * 100vw);
      grid-row-gap: calc((20 / 375) * 100vw);
    }


    &__person-name {
      padding-top: calc((10 / 375) * 100vw);
    }

    &__info {
      grid-row-gap: calc((30 / 374) * 100vw);
    }
  }
}
