.reviews-slider {
  &__slider {
    &.swiper {
      //overflow: visible;
    }
  }

  &__item-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__logo-container {
    flex: 0 0 auto;
    display: flex;
  }

  &__logo-wrapper {
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--default-timing-function);
    display: flex;
  }

  &__name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform .4s, opacity .4s;
    transition-timing-function: var(--default-timing-function);
  }

  &__description-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: var(--grid-column2);
  }

  &__description {
    white-space: nowrap;
    overflow: hidden;
    width: var(--grid-column2);

    span {
      transition: transform .8s var(--default-timing-function);
      transform-origin: 0 50%;
      will-change: transform;
    }
  }

  &__origin,
  &__description {
    transition: transform .4s, opacity .4s;
    transition-timing-function: var(--default-timing-function);
  }

  &__name-wrap,
  &__profession-wrap {
    overflow: hidden;
  }

  &__name {
    transition: color .4s, transform .8s;
    transition-timing-function: var(--default-timing-function);
    transform-origin: 0 50%;
    will-change: transform;
  }

  &__profession {
    color: var(--translucency-color);
    transition: color .4s, transform .8s;
    transition-timing-function: var(--default-timing-function);
    transform-origin: 0 50%;
    will-change: transform;
  }

  &__picture {
    display: flex;
    border-radius: 100%;
    overflow: hidden;

    ::selection,
    &::selection {
      background-color: var(--text-color);
      color: var(--background-color);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__origin-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    &.swiper-wrapper {
      width: var(--grid-column2);
    }
  }

  &__item {
    &.swiper-slide {
      width: var(--grid-column2);

      .reviews-slider {
        &__description span,
        &__name,
        &__profession {
          transform: translate3d(0, 100%, 0) rotate(8deg);
        }

        &__description {
          @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
              span {
                transition-delay: calc(#{$i} * .05s);
              }
            }
          }
        }

        &__logo-wrapper {
          opacity: 0;
          transform: translate3d(0, 20px, 0);
        }
      }
    }

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
      .reviews-slider {
        &__description {
          span {
            transform: translate3d(0, 0, 0);
          }

          @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
              span {
                transition-delay: calc(.3s + (#{$i} * .05s));
              }
            }
          }
        }

        &__name {
          transform: translate3d(0, 0, 0);
          transition-delay: 0s, .4s;
        }

        &__profession {
          transform: translate3d(0, 0, 0);
          transition-delay: 0s, .5s;
        }

        &__logo-wrapper {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: .3s;
        }
      }
    }
  }

  &__nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__progress {
    background-color: var(--translucency-color);
    height: 2px;
    width: 100%;
    position: relative;
  }

  &__progress-fill {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--border-color);
    width: 0;
    height: 100%;
    animation: border-in 5s linear;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
  }
}

@include respond-up('x-large') {
  .reviews-slider {
    &__main {
      margin-top: calc((130 / 1920) * 100vw);
    }

    &__name-wrapper {
      margin-left: calc((40 / 1920) * 100vw);
    }

    &__content {
      margin-top: calc((60 / 1920) * 100vw);
    }

    &__origin-wrap {
      margin-top: calc((72 / 1920) * 100vw);
    }

    &__nav {
      margin-top: calc((11 / 1920) * 100vw);
    }

    &__progress {
      margin-left: calc((30 / 1920) * 100vw);
    }
  }
}

@include respond-between('s-large', 'large') {
  .reviews-slider {
    &__main {
      margin-top: 130px;
    }

    &__content {
      margin-top: 60px;
    }

    &__origin-wrap {
      margin-top: 72px;
    }

    &__nav {
      margin-top: 11px;
    }

    &__progress {
      margin-left: 30px;
    }
  }
}

@include respond-up('xs-large') {
  .reviews-slider {
    &__name-wrapper {
      margin-left: 40px;
    }

    &__picture {
      width: calc((170 / 1920) * 100vw);
      height: calc((170 / 1920) * 100vw);

      &_second {
        margin-left: calc((21 / 1920) * 100vw * -1);
      }
    }

    &__title {
      width: var(--grid-column);
    }

    &__main {
      grid-template-columns: repeat(2, 1fr);
    }

    &__content {
      //height: calc((388 / 1920) * 100vw);
    }
  }
}

@include respond('xs-large') {
  .reviews-slider {
    &__name-wrapper {
      margin-left: calc((40 / 1440) * 100vw);
    }

    &__main {
      margin-top: calc((130 / 1440) * 100vw);
    }

    &__content {
      margin-top: calc((60 / 1440) * 100vw);
    }

    &__origin-wrap {
      margin-top: calc((72 / 1440) * 100vw);
    }

    &__nav {
      margin-top: calc((11 / 1440) * 100vw);
    }

    &__progress {
      margin-left: calc((30 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .reviews-slider {
    &__description-wrap,
    &__description {
      width: var(--grid-column4);
    }

    &__name-wrapper {
      margin-left: 30px;
    }

    &__picture {
      width: calc((130 / 834) * 100vw);
      height: calc((130 / 834) * 100vw);

      &_second {
        margin-left: calc((16 / 834) * 100vw * -1);
      }
    }

    &__title {
      width: var(--grid-column2);
    }

    &__main {
      margin-top: 70px;
    }

    &__content {
      margin-top: 40px;
      //height: calc((247 / 834) * 100vw);
    }

    &__origin-wrap {
      margin-top: 42px;
    }

    &__slider-wrap {
      margin-top: 70px;
    }

    &__list.swiper-wrapper,
    &__item.swiper-slide {
      width: var(--grid-column4);
    }

    &__progress {
      margin-left: 30px;
    }
  }
}

@include respond-down('medium') {
  .reviews-slider {
    &__main {
      grid-template-columns: 1fr;
      grid-row-gap: 0;
    }
  }
}

@include respond-up('s-medium') {
  .reviews-slider {

  }
}

@include respond-down('small') {
  .reviews-slider {
    &__name-wrapper {
      margin-top: 10px;
    }

    &__main {
      margin-top: 30px;
    }

    &__picture {
      width: calc((92 / 374) * 100vw);
      height: calc((92 / 374) * 100vw);

      &_second {
        margin-left: calc((12 / 834) * 100vw * -1);
      }
    }

    &__logo-container {
      flex-direction: column;
    }

    &__content {
      margin-top: 30px;
      //height: calc((328 / 374) * 100vw);
    }

    &__slider-wrap {
      margin-top: 30px;
    }

    &__origin-wrap {
      margin-top: 30px;
    }

    &__progress {
      margin-left: 20px;
    }
  }
}

@include respond-down('x-small') {
  .reviews-slider {
    &__main {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__name-wrapper {
      margin-top: calc((10 / 374) * 100vw);
    }

    &__content {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__slider-wrap {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__origin-wrap {
      margin-top: calc((30 / 374) * 100vw);
    }

    &__progress {
      margin-left: calc((20 / 1920) * 100vw);
    }
  }
}

@keyframes border-in {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}