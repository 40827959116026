.service-items {
  transition: border-color .4s var(--default-timing-function);

  &__content-wrap {
    height: 0;
    transition: var(--default-transition);
    overflow: hidden;
  }

  &__content {
    p,
    ul {
      @extend %text-main;
    }

    p strong {
      @extend %text-item;
    }
  }

  &__info {
    grid-column: 1/3;
  }

  &__item {
    border-bottom: 2px solid var(--border-color);
    transition: border-color .4s, opacity .7s;
    transition-timing-function: var(--default-timing-function);

    &:first-child {
      border-top: 2px solid var(--border-color);
    }

    &._opened {
      .service-items__link {
        &::before {
          transform: translate3d(0, -50%, 0) rotate(-180deg);
        }

        &:after {
          transform: translate3d(0, -50%, 0);
        }
      }
    }

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        .service-items__link {
          transition-delay: calc(#{$i} * .05s);
        }
      }
    }
  }

  &__link {
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    cursor: default;
    transition: opacity .7s var(--default-timing-function);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 2px;
      transform: translate3d(0, -50%, 0);
      background-color: var(--border-color);
      transition: transform .4s, opacity .4s;
      transition-timing-function: var(--default-timing-function);
      opacity: 0;
      pointer-events: none;
    }

    &::after {
      transform: translate3d(0, -50%, 0) rotate(90deg);
    }

    &_iconable {
      cursor: pointer;

      &::before,
      &::after {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

@include respond-up('x-large') {
  .service-items {
    &__content {
      padding: calc((80 / 1920) * 100vw) 0 calc((100 / 1920) * 100vw);
    }

    &__link {
      padding: calc((17 / 1920) * 100vw) 0 calc((19 / 1920) * 100vw);

      &::before,
      &::after {
        width: calc((26 / 1920) * 100vw);
      }
    }
  }
}

@include respond-between('s-large', 'large') {
  .service-items {
    &__content {
      padding: 80px 0 100px;
    }
  }
}

@include respond-down('large') {
  .service-items {
    &__link {
      padding: 17px 0 19px;

      &::before,
      &::after {
        width: 26px;
      }
    }
  }
}

@include respond-up('xs-large') {
  .service-items {
    &__item-title {
      grid-column: 1/3;
    }

    &__item-price {
      grid-column: 3/4;
    }

    &__description {
      grid-column: 3/5;
    }

    &__item-price-mobile {
      display: none;
    }

    &:hover {
      border-top-color: var(--translucency-color);

      .service-items {
        &__item-title,
        &__item-price {
          color: var(--translucency-color);
        }

        &__item {
          border-bottom-color: var(--translucency-color);
          &:first-child {
            border-top-color: var(--translucency-color);
          }
        }

        &__link {
          &_iconable {
            &::before,
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }

    &__item {
      position: relative;

      &._hovered,
      &._anim-in-playing {
        .service-items__item-border {
          transform: scaleX(1);
          transition-duration: .4s;
          transform-origin: left center;
        }
      }

      &._anim-out-playing {
        .service-items__item-border {
          transform: scaleX(0);
          transition-duration: 1s;
          transition-delay: .4s;
        }
      }

      &:hover {
        //border-bottom-color: var(--border-color) !important;

        .service-items {
          &__item-title,
          &__item-price {
            color: var(--text-color);
          }

          &__link {
            &_iconable {
              &::before,
              &::after {
                opacity: 1;
              }
            }
          }
        }
      }

      &._opened {
        .service-items {
          &__link {
            &::before,
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    &__item-border {
      background-color: var(--border-color);
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -2px;
      left: 0;
      transform: scaleX(0);
      transition: transform var(--default-timing-function);
      transition-duration: 0s;
      transform-origin: center right;
      will-change: transform;
      animation-fill-mode: forwards;
    }

    &__button-title {
      width: var(--grid-column);
    }
  }
}

@include respond('xs-large') {
  .service-items {
    &__content {
      padding: calc((80 / 1440) * 100vw) 0 calc((100 / 1440) * 100vw);
    }

    &__link {
      padding: calc((17 / 1440) * 100vw) 0 calc((19 / 1440) * 100vw);

      &::before,
      &::after {
        width: calc((26 / 1440) * 100vw);
      }
    }
  }
}

@include respond-up('medium') {
  .service-items {

  }
}

@include respond('medium') {
  .service-items {

  }
}

@include respond-down('medium') {
  .service-items {
    &__info {
      grid-row: 3/4;
      margin-top: 60px;
    }

    &__description {
      grid-column: 1/5;
      grid-row: 2/3;
      margin-top: 30px;
    }

    &__content {
      padding: 30px 0 60px;
      grid-row-gap: 0;
    }

    &__item-title {
      grid-column: 1/5;
    }

    &__item-price {
      display: none;
    }

    &__item-price-mobile {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    &__button-title {
      width: var(--grid-column2);
    }
  }
}

@include respond-down('small') {
  .service-items {
    &__info {
      margin-top: 40px;
    }

    &__description {
      grid-column: 1/3;
      margin-top: 20px;
    }

    &__content {
      padding: 20px 0 40px;
    }

    &__link {
      grid-template-columns: repeat(2, 1fr);
      padding: 10px 0 12px;

      &::before,
      &::after {
        width: 18px;
      }
    }
  }
}

@include respond('x-small') {
  .service-items {
    &__content {
      padding: calc((20 / 374) * 100vw) 0 calc((40 / 374) * 100vw);
    }

    &__link {
      padding: calc((10 / 374) * 100vw) 0 calc((12 / 374) * 100vw);

      &::before,
      &::after {
        width: calc((18 / 375) * 100vw);
      }
    }

    &__item-title {
      grid-column: 1/3;
    }

    &__description {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__info {
      margin-top: calc((40 / 374) * 100vw);
    }
  }
}