.footer-socials {
  display: flex;
  height: max-content;

  &__list {
    display: flex;
    align-items: center;
    margin: 0 -4px;

    &:hover {
      svg {
        path {
          fill: var(--opposite-translucency-color);
        }
      }
    }
  }

  &__link {
    padding: 0 4px;
    display: flex;
    align-items: center;

    svg {
      path {
        fill: var(--opposite-text-color);
        transition: fill .4s var(--default-timing-function);
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--opposite-text-color);
        }
      }
    }
  }
}

@include respond-up('xs-large') {
  .footer-socials {

  }
}

@include respond-up('medium') {
  .footer-socials {

  }
}

@include respond('medium') {
  .footer-socials {

  }
}

@include respond-up('s-medium') {
  .footer-socials {
    justify-content: flex-end;
  }
}

@include respond-down('medium') {
  .footer-socials {

  }
}

@include respond-down('small') {
  .footer-socials {

  }
}