.contacts-form {
  &.request-form {
    position: static;
  }

  &__success-wrapper {
    position: relative;
    //margin-top: calc(0px - var(--header-height));
  }

  &__success {
    z-index: 500;
    background-color: var(--background-color);

    &.request-form__success {
      position: fixed;
      top: calc(0px - var(--header-height));
      background: var(--background-color);
      z-index: 1550;
      height: calc(100vh + var(--header-height));

      &.success {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__fields,
  &__action {
    display: flex;
    flex-wrap: wrap;
  }

  &__action {
    justify-content: space-between;
  }

  &__file-field-wrap {
    flex: 0 0 100%;
  }

  .form-file-field {
    padding: var(--file-form-field-padding) 0;
  }

  &__button-wrap {
    margin-top: var(--button-margin-top);
  }

  &__policy-wrap {
    margin-top: var(--policy-margin-top);
  }

  &__data {
    margin-top: var(--data-top);
  }
}

@include respond-up('x-large') {
  .contacts-form {
    --file-form-field-padding: calc((22 / 1920) * 100vw);
    --button-margin-top: calc((28 / 1920) * 100vw);
    --policy-margin-top: calc((38 / 1920) * 100vw);
    --data-top: calc((80 / 1920) * 100vw);
  }
}

@include respond-down('large') {
  .contacts-form {
    --file-form-field-padding: 22px;
    --button-margin-top: 28px;
    --policy-margin-top: 38px;
  }
}

@include respond-up('xs-large') {
  .contacts-form {
    --data-top: 80px;

    &__data {
      width: var(--grid-column3);
      margin-left: calc(var(--grid-column1) + var(--grid-gap));
    }

    &__button-wrap,
    &__policy-wrap {
      flex: 0 0 var(--grid-column);
    }
  }
}

@include respond('xs-large') {
  .contacts-form {
    --file-form-field-padding: calc((22 / 1440) * 100vw);
    --button-margin-top: calc((28 / 1440) * 100vw);
    --policy-margin-top: calc((38 / 1440) * 100vw);
    --data-top: calc((80 / 1440) * 100vw);
  }
}

@include respond-up('medium') {
  .contacts-form {

  }
}

@include respond('medium') {
  .contacts-form {

  }
}

@include respond-down('medium') {
  .contacts-form {
    --file-form-field-padding: 20px;
    --button-margin-top: 20px;
    --policy-margin-top: 20px;
    --data-top: 80px;
    width: var(--grid-column4);

    &__button-wrap,
    &__policy-wrap {
      flex: 0 0 var(--grid-column2);
    }
  }
}

@include respond-up('s-medium') {
  .contacts-form {
    .form-field {
      flex: 0 0 50%;

      &:nth-child(2) {
        border-left: unset;
      }

      &:last-child {
        flex: 0 0 100%;
        border-top: unset;
      }
    }
  }
}

@include respond-down('small') {
  .contacts-form {
    --file-form-field-padding: 10px;
    --button-margin-top: 20px;
    --policy-margin-top: 10px;
    --data-top: 40px;
    width: var(--grid-column2);

    .form-field {
      flex: 0 0 100%;

      &:not(:first-child) {
        border-top: unset;
      }
    }

    &__button-wrap,
    &__policy-wrap {
      flex: 0 0 100%;
    }
  }
}

@include respond('x-small') {
  .contacts-form {
    --file-form-field-padding: calc((10 / 375) * 100vw);
    --button-margin-top: calc((20 / 375) * 100vw);
    --policy-margin-top: calc((10 / 375) * 100vw);
    --data-top: calc((40 / 375) * 100vw);
  }
}