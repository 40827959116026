/* Generated by ParaType (http://www.paratype.com)*/
/* Font Pragmatica: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
    font-family: 'RClass Pragmatica';
    src: url('rclass-pragmatica.eot');
    src: local('RClass Pragmatica'), local('rclass-pragmatica'),
        url('rclass-pragmatica.eot?#iefix') format('embedded-opentype'),
        url('rclass-pragmatica.woff2') format('woff2'),
        url('rclass-pragmatica.woff') format('woff'),
        url('rclass-pragmatica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
