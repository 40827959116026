.index-page-about {
  &__description {
    position: relative;

    .edit-block {
      //что делает эта строчка, она зачем была нужна
      //min-height: 40px;
      max-width: var(--grid-column2);
    }

    .edit-block__link {
      margin-top: 0;
    }
  }

  &__title {
    font-family: var(--h-font);
    text-transform: uppercase;
  }
}

@include respond-up('x-large') {
  .index-page-about {
    &__button-wrap {
      margin-top: calc((50 / 1920) * 100vw);
    }
  }
}

@include respond-down('large') {
  .index-page-about {
  }
}

@include respond-up('xs-large') {
  .index-page-about {
    margin-top: 40px;

    &__main {
      margin-top: 127px;
      margin-left: calc(var(--grid-column2) + var(--grid-gap));
    }

    &__title {
      .edit-block {
        max-width: calc(var(--grid-column2) - var(--grid-column) / 2);
      }
    }

    &__description {
      .edit-block__link {
        bottom: unset;
        top: -48px;
      }
    }

    &__button-wrap {
      margin-top: 50px;
    }
  }
}

@include respond('xs-large') {
  .index-page-about {
    &__button-wrap {
      margin-top: calc((50 / 1440) * 100vw);
    }
  }
}

@include respond-between('s-medium', 'medium') {
  .index-page-about {
    margin-top: 40px;

    &__main {
      margin-top: 100px;
    }

    &__description {
      .edit-block {
        max-width: var(--grid-column4);
      }

      .edit-block__link {
        bottom: unset;
        top: -48px;
      }
    }

    &__button-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .index-page-about {
  }
}

@include respond('small') {
  .index-page-about {
    margin-top: 20px;

    &__title {
      .edit-block {
        max-width: calc(var(--grid-column2) - var(--grid-column) / 2);
      }
    }

    &__button-wrap {
      margin-top: 20px;
    }

    &__main {
      margin-top: 50px;
    }

    &__button-wrap {
      margin-top: 20px;
    }

    &__description {
      .edit-block__link {
        bottom: unset;
        top: -48px;
      }
    }
  }
}

@include respond('x-small') {
  .index-page-about {

    &__title {
      .edit-block {
        max-width: calc(var(--grid-column2) - var(--grid-column) / 2);
      }
    }

    &__button-wrap {
      margin-top: calc((20 / 374) * 100vw);
    }

    &__main {
      margin-top: 50px;
    }

    &__button-wrap {
      margin-top: 20px;
    }

    &__description {
      .edit-block__link {
        bottom: unset;
        top: -52px;
      }
    }
  }
}