.animation-block {
  &__text-opacity {
    transition: opacity var(--default-timing-function) 0.3s;
  }

  &__delay-03 {
    transition-delay: 0.3s;
  }

  &__delay-04 {
    transition-delay: 0.4s;
  }

  &__delay-05 {
    transition-delay: 0.5s;
  }

  &__delay-06 {
    transition-delay: 0.6s;
  }

  &__delay-07 {
    transition-delay: 0.7s;
  }

  &__delay-08 {
    transition-delay: 0.8s;
  }

  &__custom-blocks-wrap {
    overflow: hidden;
  }

  &__button-opacity {
    transition: opacity var(--default-timing-function) 0.3s;
    transition-delay: 1s;
  }

  &__overflow {
    overflow: hidden;
  }

  &__transform {
    transition: transform .8s;
    transition-timing-function: var(--default-timing-function);
  }

  &__wrap {
    .animation-block {
      &__opacity-scale-image {
        transition-property: opacity, transform;
        transition-duration: 0.6s;
        transition-timing-function: var(--default-timing-function);
      }

      &__text-opacity-side {
        transition-property: opacity, transform;
        transition-duration: 1s;
        transition-timing-function: var(--default-timing-function);

        &.need-animation {
          opacity: 0;
        }

        &_left-to-right {
          &.need-animation {
            transform: translateX(-100%);
          }
        }

        &_right-to-left {
          &.need-animation {
            transform: translateX(100%);
          }
        }

        &_left-to-right-right-mob {
          &.need-animation {
            transform: translateX(-100%);
          }
        }

        &_right-to-left-left-mob {
          &.need-animation {
            transform: translateX(100%);
          }
        }
      }
    }

    &.need-animation {
      .animation-block {
        &__text-opacity {
          opacity: 0;
        }

        &__text-inner {
          span {
            transform: translateY(100%);
          }
        }

        &__opacity-scale-image {
          opacity: 0;
          transform: scale(1.1);
        }

        &__button-opacity {
          opacity: 0;
        }

        &__transform {
          transform: translateY(100%);
        }
      }
    }
  }

  &__text-inner {
    display: block;
    white-space: nowrap;
    overflow-y: hidden;
    //overflow-y: clip;
    width: 100%;

    span {
      transition: transform .8s;
      transition-timing-function: var(--default-timing-function);
    }

    @for $i from 2 through 20 {
      &:nth-child(#{$i}) {
        span {
          transition-delay: calc((#{$i} * 0.1s));
        }
      }
    }
  }
}

@include respond-down('medium') {
  .animation-block {
    &__wrap {
      .animation-block {
        &__text-opacity-side {
          &_left-to-right-right-mob {
            &.need-animation {
              transform: translateX(100%);
            }
          }

          &_right-to-left-left-mob {
            &.need-animation {
              transform: translateX(-100%);
            }
          }
        }
      }
    }
  }
}