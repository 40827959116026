%custom-circle-text {
  transition: color .4s var(--default-timing-function);
  color: #fff;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.03em;
  font-family: var(--font);
  line-height: 140%;
  font-size: var(--main-size);
}

@include respond-up('x-large') {
  %custom-circle-text {
    --main-size: calc((20 / 1920) * 100vw);
  }
}

@include respond('large') {
  %custom-circle-text {
    --main-size: 20px;
  }
}

@include respond('s-large') {
  %custom-circle-text {
    --main-size: 15px;
  }
}

@include respond('xs-large') {
  %custom-circle-text {
    --main-size: calc((15 / 1440) * 100vw);
  }
}

@include respond('medium') {
  %custom-circle-text {
    --main-size: 15px;
  }
}

@include respond('s-medium') {
  %custom-circle-text {
    --main-size: 15px;
  }
}

@include respond('small') {
  %custom-circle-text {
    --main-size: 15px;
  }
}

@include respond('x-small') {
  %custom-circle-text {
    --main-size: calc((15 / 375) * 100vw);
  }
}